import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities, deleteEntity } from './functionality.reducer';


export const FunctionalityDeleteDialog = (itemId, showModal) => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();
  const { appId } = useParams<'appId'>();

  const [loadModal, setLoadModal] = useState(false);
  


  const functionalityEntity = useAppSelector(state => state.functionality.entities);
  const updateSuccess = useAppSelector(state => state.functionality.updateSuccess);

  const handleClose = () => {
    itemId.showModal(loadModal)
  };

  const getAllEntities = () => {
    dispatch(
      getEntities({
        query: `appId.equals=${appId}`,
      })
    );
  };

  useEffect(() => {
    if (updateSuccess ) {
      itemId.showModal(loadModal)
      getAllEntities()

    }
  }, [updateSuccess]);


  const confirmDelete = () => {
      dispatch(deleteEntity(itemId.itemId));
        };
  

  return (
    <Modal isOpen
    // toggle={handleClose}
    >
      <ModalHeader 
      // toggle={handleClose} 
      data-cy="functionalityDeleteDialogHeading">
        <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>
      </ModalHeader>
      <ModalBody id="conductorMonolithApp.functionality.delete.question">
        <Translate contentKey="conductorMonolithApp.functionality.delete.question" interpolate={{ id: itemId.itemId}}>
          Are you sure you want to delete this Functionality?
        </Translate>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" 
        onClick={handleClose}
        >
          <FontAwesomeIcon icon="ban" />
          &nbsp;
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
        <Button id="jhi-confirm-delete-functionality" data-cy="entityConfirmDeleteButton" color="danger" onClick={confirmDelete}>
          <FontAwesomeIcon icon="trash" />
          &nbsp;
          <Translate contentKey="entity.action.delete">Delete</Translate>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default FunctionalityDeleteDialog;