import { IApp } from 'app/shared/model/app.model';
import { ICodeBlueprint } from 'app/shared/model/code-blueprint.model';

export interface IAppCodeBlueprint {
  id?: number;
  variableValues?: string | null;
  userId?: number | null;
  app?: IApp | null;
  codeBlueprint?: ICodeBlueprint | null;
}

export const defaultValue: Readonly<IAppCodeBlueprint> = {};
