import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './core-screen.reducer';

export const CoreScreenDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const coreScreenEntity = useAppSelector(state => state.coreScreen.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="coreScreenDetailsHeading">
          <Translate contentKey="conductorMonolithApp.coreScreen.detail.title">CoreScreen</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{coreScreenEntity.id}</dd>
          <dt>
            <span id="title">
              <Translate contentKey="conductorMonolithApp.coreScreen.title">Title</Translate>
            </span>
          </dt>
          <dd>{coreScreenEntity.title}</dd>
          <dt>
            <span id="path">
              <Translate contentKey="conductorMonolithApp.coreScreen.path">Path</Translate>
            </span>
          </dt>
          <dd>{coreScreenEntity.path}</dd>
          <dt>
            <span id="metadata">
              <Translate contentKey="conductorMonolithApp.coreScreen.metadata">Metadata</Translate>
            </span>
          </dt>
          <dd>{coreScreenEntity.metadata}</dd>
          <dt>
            <span id="template">
              <Translate contentKey="conductorMonolithApp.coreScreen.template">Template</Translate>
            </span>
          </dt>
          <dd>{coreScreenEntity.template}</dd>
          <dt>
            <span id="accessRoles">
              <Translate contentKey="conductorMonolithApp.coreScreen.accessRoles">Access Roles</Translate>
            </span>
          </dt>
          <dd>{coreScreenEntity.accessRoles}</dd>
          <dt>
            <Translate contentKey="conductorMonolithApp.coreScreen.coreFunctionality">Core Functionality</Translate>
          </dt>
          <dd>{coreScreenEntity.coreFunctionality ? coreScreenEntity.coreFunctionality.name : ''}</dd>
        </dl>
        <Button tag={Link} to="/core-screen" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/core-screen/${coreScreenEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default CoreScreenDetail;
