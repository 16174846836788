import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardText,
  CardTitle,
  Col,
  Label,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import { Translate, getSortState, JhiPagination } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities } from './product.reducer';
import { reset } from 'app/entities/app/app.reducer';
import './product.scss';
import { EditIcon } from 'app/shared/assets/svg-icon/icon';
import { publishProduct } from '../customreducers/ustomReducer';
import ProductDeleteDialog from './product-delete-dialog';
import { toast } from 'react-toastify';
import { AppStatus } from 'app/shared/model/enumerations/app-status.model';
import SearchModule from 'app/shared/component/searchModule/SearchModule';

export const Product = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  var [deleteId, setDeleteId] = useState({})
  // const [productName, setProductName] = useState("")
  const [showModal, setShowModal] = useState(false)
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id', DESC), location.search)
  );

  const productList = useAppSelector(state => state.product.entities);
  const loading = useAppSelector(state => state.product.loading);
  const totalItems = useAppSelector(state => state.product.totalItems);
  const updateSuccess = useAppSelector(state => state.product.updateSuccess);
  const [isPublished, setIsPublished] = useState(undefined);
    const [query,setQuery] = useState("")
  const getAllEntities = (emptyQuery?:any) => {
    const queryValue = emptyQuery != undefined ? emptyQuery : query
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `id,desc`,
        query: queryValue?`name.contains=${queryValue}`:'status.notEquals='+AppStatus.Deleted
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
  };

  useEffect(() => {
    sortEntities();
    dispatch(reset());
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  useEffect(() => {
    if (updateSuccess && isPublished) {
      toast.success("Product published successfully!!");
      setIsPublished(undefined);
    }
    sortEntities();
  }, [updateSuccess]);

  const handleDelete = (product) => {
    setDeleteId(product)
    // setProductName(productName)
    setShowModal(true)
  }

  const publish = (productId) => {
    dispatch(publishProduct(productId));
    setIsPublished(true);
  }

  const viewModal = (modal) => {
    setShowModal(modal)
  }

  localStorage.removeItem('selectedTab');
  
  return (
    <>
      <div className="inner-content-wrap">
        <h2 id="product-heading" data-cy="ProductHeading" className="row g-0 page-title">
          <Col md="5">
            <img src="content/images/product-icon.png" />
            <Translate contentKey="conductorMonolithApp.product.home.title">Products</Translate>
          </Col>
          <Col md="7">
            {/* <div className="d-flex justify-content-end">
            <Button className="me-2" color="refresh" onClick={handleSyncList} disabled={loading}>
              <FontAwesomeIcon icon="sync" spin={loading} />{' '}
              <Translate contentKey="conductorMonolithApp.product.home.refreshListLabel">Refresh List</Translate>
            </Button>
          </div> */}
          </Col>
        </h2>
        <div>
          <Row>
            <Col>
              <SearchModule 
                setQuery={setQuery}
                fetchFunction={getAllEntities}
                placeHolder='Product Name'
              />
            </Col>
          </Row>
          <Row className="cardLayout">
                      <Col md={'3'} className="mb-4">
              <Card className="newCard">
                <Link to="/product/new" className="btn btn-create-new jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
                  <CardBody>
                    <EditIcon />
                    <br />
                    <Label contentKey="conductorMonolithApp.product.home.createLabel">Add Product</Label>
                  </CardBody>
                </Link>
              </Card>
            </Col>
            {productList && productList.length > 0 ? (
              <>
                {productList.map((product, i) => (
                  <Col md={'3'} key={`entity-${i}`} data-cy="entityTable" className="mb-4">
                    <Card>
                      <CardBody onClick={() => navigate(`/product/${product.id}`)}>
                        <CardText className="ProductImg">
                          <span>{product.name}</span>
                        </CardText>
                        <CardTitle>{product.name}</CardTitle>
                      </CardBody>
                      <div className="floating-buttons">
                        <Button
                          className="del-button"
                          id={`deleteproduct-${product.id}`}
                          onClick={async () => {
                            handleDelete(product);
                          }}
                        >
                          <FontAwesomeIcon icon="trash" />{' '}
                        </Button>
                        <UncontrolledTooltip placement="top" target={`deleteproduct-${product.id}`}>
                          Delete
                        </UncontrolledTooltip>
                      </div>
                      <CardFooter>
                        <Link
                          className="btn btn-manage"
                          type="button"
                          data-cy="entityEditButton"
                          to={`/product/${product.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        >
                          <FontAwesomeIcon icon="pencil-alt" /> Manage
                        </Link>
                        <Button onClick={() => publish(product && product.id)} color="publish" className="btn">
                       Setup
                        </Button>
                      </CardFooter>
                    </Card>
                  </Col>
                ))}
              </>
            ) : (
              !loading && (
                <div className="alert alert-warning">
                  <Translate contentKey="conductorMonolithApp.product.home.notFound">No Products found</Translate>
                </div>
              )
            )}
          </Row>
        </div>
        {totalItems ? (
          <div className={productList && productList.length > 0 ? '' : 'd-none'}>

            <div className="justify-content-center d-flex mt-5">
              <JhiPagination
                activePage={paginationState.activePage}
                onSelect={handlePagination}
                maxButtons={5}
                itemsPerPage={paginationState.itemsPerPage}
                totalItems={totalItems}
              />
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      {showModal && <ProductDeleteDialog itemId={deleteId} showModal={viewModal} />}
    </>
  );
};

export default Product;
