import { AUTHORITIES } from 'app/config/constants';

import PrivateRoute from 'app/shared/auth/private-route';

import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import PageNotFound from 'app/shared/error/page-not-found';

const CustomRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route path="*" element={<PageNotFound />} />
  </ErrorBoundaryRoutes>
);

export default CustomRoutes;
