import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './core-functionality-dependency.reducer';

export const CoreFunctionalityDependencyDetail = () => {
  const dispatch = useAppDispatch();

  const { productId } = useParams<'productId'>();
  const { appId } = useParams<'appId'>();
  const { coreFunctionalityDependencyId } = useParams<'coreFunctionalityDependencyId'>();

  useEffect(() => {
    dispatch(getEntity(coreFunctionalityDependencyId));
  }, []);

  const coreFunctionalityDependencyEntity = useAppSelector(state => state.coreFunctionalityDependency.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="coreFunctionalityDependencyDetailsHeading">
          <Translate contentKey="conductorMonolithApp.coreFunctionalityDependency.detail.title">CoreFunctionalityDependency</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{coreFunctionalityDependencyEntity.id}</dd>
          <dt>
            <span id="version">
              <Translate contentKey="conductorMonolithApp.coreFunctionalityDependency.version">Version</Translate>
            </span>
          </dt>
          <dd>{coreFunctionalityDependencyEntity.version}</dd>
          <dt>
            <span id="lastSyncTimestamp">
              <Translate contentKey="conductorMonolithApp.coreFunctionalityDependency.lastSyncTimestamp">Last Sync Timestamp</Translate>
            </span>
          </dt>
          <dd>
            {coreFunctionalityDependencyEntity.lastSyncTimestamp ? (
              <TextFormat value={coreFunctionalityDependencyEntity.lastSyncTimestamp} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="conductorMonolithApp.coreFunctionalityDependency.dependency">Dependency</Translate>
          </dt>
          <dd>{coreFunctionalityDependencyEntity.dependency ? coreFunctionalityDependencyEntity.dependency.id : ''}</dd>
          <dt>
            <Translate contentKey="conductorMonolithApp.coreFunctionalityDependency.coreFunctionality">Core Functionality</Translate>
          </dt>
          <dd>{coreFunctionalityDependencyEntity.coreFunctionality ? coreFunctionalityDependencyEntity.coreFunctionality.name : ''}</dd>
        </dl>
        <Button tag={Link} to={`/product/${productId}/app/${appId}`} replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/product/${productId}/app/${appId}/core-functionality-dependency/${coreFunctionalityDependencyEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default CoreFunctionalityDependencyDetail;
