import dayjs from 'dayjs';
import { IDependency } from 'app/shared/model/dependency.model';
import { ICoreFunctionality } from 'app/shared/model/core-functionality.model';

export interface ICoreFunctionalityDependency {
  id?: number;
  version?: string | null;
  lastSyncTimestamp?: string | null;
  dependency?: IDependency | null;
  coreFunctionality?: ICoreFunctionality | null;
}

export const defaultValue: Readonly<ICoreFunctionalityDependency> = {};
