import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText, UncontrolledTooltip, Label, FormGroup, Input, Container, Spinner, ButtonGroup } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm, getSortState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity, updateEntity, createEntity, reset, getEntities as getScreens } from './app-screen.reducer';
import { createEntity as createScreenComponent } from '../screen-component/screen-component.reducer';
import { updateEntity as updateScreenComponent } from '../screen-component/screen-component.reducer';
import { deleteEntity as deleteScreenComponent } from '../screen-component/screen-component.reducer';
import { getEntities as getAppEntities } from '../app-entity/app-entity.reducer';
import { getEntities as getEntityFields } from '../app-entity-field/app-entity-field.reducer';
import { getEntities as getRelationship } from '../entity-relationship/entity-relationship.reducer';
import { getEntities as getScreenComponents } from '../screen-component/screen-component.reducer';
import { FormActionType } from 'app/shared/model/enumerations/form-action-type.model';
import DynamicSelect from 'app/modules/dynamicSelectComponent/DynamicSelect';
import { ITEMS_PER_PAGE, DESC } from 'app/shared/util/pagination.constants';

import GrapeEditor from 'app/shared/component/grapeJS/GrapeEditor';

type Item = string;

const AppScreenUpdate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { productId } = useParams<'productId'>();
  const { appId } = useParams<'appId'>();
  const { appScreenId } = useParams<'appScreenId'>();
  const isNew = appScreenId === undefined;
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id', DESC), location.search)
  );

  const apps = useAppSelector(state => state.app.entities);
  const appEntitiesLoading = useAppSelector(state => state.appEntity.loading);
  const appScreenComponent = useAppSelector(state => state.screenComponent.entities);
  const appScreenComponentUpdate = useAppSelector(state => state.screenComponent.updateSuccess);
  const appScreenComponentLoading = useAppSelector(state => state.screenComponent.loading);
  const appEntities = useAppSelector(state => state.appEntity.entities);
  const appEntitiesTotalItems = useAppSelector(state => state.appEntity.totalItems);
  const appEntityFieldsLoading = useAppSelector(state => state.appEntityField.loading);
  const appEntityFieldEntities = useAppSelector(state => state.appEntityField.entities);
  const entityRelationshipLoading = useAppSelector(state => state.entityRelationship.loading);
  const entityRelationshipList = useAppSelector(state => state.entityRelationship.entities);
  const appScreenEntity = useAppSelector(state => state.appScreen.entity);
  const appScreenList = useAppSelector(state => state.appScreen.entities);
  const loading = useAppSelector(state => state.appScreen.loading);
  const loadingScreens = useAppSelector(state => state.appScreen.loadingScreens);
  const updating = useAppSelector(state => state.appScreen.updating);
  const updateSuccess = useAppSelector(state => state.appScreen.updateSuccess);
  const chatGptSearchData = useAppSelector(state => state.chatGptSearch);
  
  const [formBuilder, setFormBuilder] = useState([]);
  const [selectionBuilder, setSelectionBuilder] = useState([]);
  const [selectedEntityId, setSelectedEntityId] = useState(undefined);
  const [entitiesCopy, setEntitiesCopy] = useState([]);
  const [tempEntitiesCopy, setTempEntitiesCopy] = useState([]);
  const [entityLayout, setEntityLayout] = useState({ entity_id: '', layout: '' });
  const [selectEntityField, setSelectEntityField] = useState({ entity_id: '', checked: null, field: {} });
  const [selectRelatedEntityField, setSelectRelatedEntityField] = useState({ entity_id: '', related_entity: '', checked: null, field: {} });
  const [selectedRelatedEntityId, setSelectedRelatedEntityId] = useState({ entity_id: '', related_entity_id: '' });
  const [temp, setTemp] = useState(undefined);
  const [screenComponent, setScreenComponent] = useState(appScreenComponent);
  const [rSelected, setRSelected] = useState(null);
  const [subLayout, setSubLayout] = useState({ entity_id: '', sub_layout: '' });
  const [template, setTemplate] = useState('col');
  const [appScreenTitle, setAppScreenTitle] = useState('');
  const [appScreenPath, setAppScreenPath] = useState('');
  const [filterEntityData, setFilterEntityData] = useState({ entity_id: '', filterField: '' });
  const [tempFilterEntity, setTempFilterEntity] = useState(undefined);
  const [filterRelatedEntity, setFilterRelatedEntity] = useState({ entity_id: '', relatedEntity: '' });
  const [filterEntityText, setFilterEntityText] = useState({ entity_id: '', filterId: '', filterType: '' });
  const [tempAppScreen, setTempAppScreen] = useState(undefined);
  const [navigateScreenDetail, setNavigateScreenDetail] = useState({ entity_id: '', screenId: '', screenType: '' });
  const [formAction, setFormAction] = useState({ entity_id: '', action: '' });
  const [redirectScreen, setRedirectScreen] = useState({ entity_id: '', screenId: '' });
  const [formSuccessMessage, setFormSuccessMessage] = useState({ entity_id: '', message: '' });
  const [tempAppScreenList, setTempAppScreenList] = useState([]);
  const [tempAppScreenId, setTempAppScreenId] = useState(undefined);
  useEffect(() => {
    // checkboxes change handler based on selected related entity for related entity fields
    if (
      Object.keys(selectRelatedEntityField).length > 0 &&
      selectRelatedEntityField.related_entity != '' &&
      selectRelatedEntityField.entity_id != '' &&
      selectRelatedEntityField.checked != null &&
      Object.keys(selectRelatedEntityField.field).length > 0
    ) {
      // selection & form builder entity
      let entityToUpdate = selectionBuilder.filter(selection => selection.entity == selectRelatedEntityField?.entity_id)[0];
      let entityToUpdateForm = formBuilder.filter(form => form.entity_id == selectRelatedEntityField?.entity_id)[0];
      // form & selection entity field
      let formRelationshipUpdate = entityToUpdateForm.related_entities.filter(
        form => form.entity_id == selectRelatedEntityField?.related_entity
      )[0];

      let formRelationshipFieldUpdate = formRelationshipUpdate.entity_fields.filter(
        form => form.id == selectRelatedEntityField?.field['id']
      )[0];

      // check if field is to add or remove
      let relatedEntityToUpdate = entityToUpdate.related_entities.filter(
        related_entity => related_entity.entity_id == selectRelatedEntityField?.related_entity
      )[0];

      let fieldsToUpdate = relatedEntityToUpdate.entityfields || '';
      if (selectRelatedEntityField.checked) {
        //add field
        if (fieldsToUpdate == '') {
          fieldsToUpdate = selectRelatedEntityField.field['name'];
        } else {
          fieldsToUpdate = [fieldsToUpdate, selectRelatedEntityField.field['name']].join(',');
        }
        formRelationshipFieldUpdate = { ...formRelationshipFieldUpdate, checkedField: selectRelatedEntityField.checked };
      }
      if (!selectRelatedEntityField.checked) {
        if (fieldsToUpdate != '') {
          let fieldsArr = fieldsToUpdate.split(',');
          let fieldsArrIdx = fieldsArr.indexOf(selectRelatedEntityField.field['name']);
          if (fieldsArrIdx != -1) {
            fieldsArr.splice(fieldsArrIdx, 1);
          }
          fieldsToUpdate = fieldsArr.join(',');
        }
        formRelationshipFieldUpdate = { ...formRelationshipFieldUpdate, checkedField: selectRelatedEntityField.checked };
      }
      // update relationship field
      formRelationshipFieldUpdate = formRelationshipUpdate?.entity_fields.map(form => {
        return form.id == selectRelatedEntityField?.field['id'] ? formRelationshipFieldUpdate : form;
      });
      formRelationshipUpdate = { ...formRelationshipUpdate, entity_fields: formRelationshipFieldUpdate };

      formRelationshipUpdate = entityToUpdateForm?.related_entities.map(form => {
        return form.entity_id == selectRelatedEntityField?.related_entity ? formRelationshipUpdate : form;
      });
      entityToUpdateForm = { ...entityToUpdateForm, related_entities: formRelationshipUpdate };

      let tempFromBuilderUpdate = formBuilder.map(form => {
        return form.entity_id == selectRelatedEntityField?.entity_id ? entityToUpdateForm : form;
      });

      relatedEntityToUpdate = { ...relatedEntityToUpdate, entityfields: fieldsToUpdate };
      relatedEntityToUpdate = entityToUpdate.related_entities.map(entity => {
        return entity.entity_id == relatedEntityToUpdate.entity_id ? relatedEntityToUpdate : entity;
      });
      entityToUpdate = { ...entityToUpdate, related_entities: relatedEntityToUpdate };

      let tempSelectionBuilder = selectionBuilder.map(selection => {
        return selection.entity == entityToUpdate.entity ? entityToUpdate : selection;
      });
      setFormBuilder(tempFromBuilderUpdate);
      setSelectionBuilder(tempSelectionBuilder);

      setSelectRelatedEntityField({ entity_id: '', related_entity: '', checked: null, field: {} });
    }
  }, [selectRelatedEntityField, selectionBuilder]);

  useEffect(() => {
    if (
      Object.keys(selectedRelatedEntityId).length > 0 &&
      selectedRelatedEntityId.entity_id != '' &&
      selectedRelatedEntityId.related_entity_id != null
    ) {
      dispatch(getEntityFields({ query: 'appEntityId.equals=' + selectedRelatedEntityId.related_entity_id }));
      if (!appEntityFieldsLoading) {
        setTemp({
          id: selectedRelatedEntityId.entity_id,
          related_entity_id: selectedRelatedEntityId.related_entity_id,
          type: 'related_entity',
        });
      }
      let entityToUpdate = selectionBuilder.filter(selection => selection.entity == selectedRelatedEntityId.entity_id)[0];

      if (entityToUpdate.related_entities && Array.isArray(entityToUpdate.related_entities)) {
        if (entityToUpdate.related_entities.find(item => item.entity_id == selectedRelatedEntityId.related_entity_id) == undefined) {
          entityToUpdate.related_entities.push({ entity_id: selectedRelatedEntityId.related_entity_id });
        } else {
          entityToUpdate['related_entities'] = entityToUpdate.related_entities.map(entity => {
            return entity.entity_id == selectedRelatedEntityId.related_entity_id
              ? !isNew
                ? entity
                : { entity_id: selectedRelatedEntityId.related_entity_id }
              : entity;
          });
        }
      } else {
        entityToUpdate['related_entities'] = [{ entity_id: selectedRelatedEntityId.related_entity_id }];
      }

      setSelectionBuilder(
        selectionBuilder.map(selection => {
          return selection.entity == entityToUpdate.entity ? entityToUpdate : selection;
        })
      );
      setSelectedRelatedEntityId({ entity_id: '', related_entity_id: '' });
    }
  }, [selectedRelatedEntityId, selectionBuilder, formBuilder, appEntityFieldsLoading]);

  // filter  entity data with id and add related entity name
  useEffect(() => {
    if (Object.keys(filterEntityData).length > 0 && filterEntityData.entity_id != '' && filterEntityData.filterField != null) {
      dispatch(getRelationship({ query: 'appEntityFromId.equals=' + filterEntityData.entity_id }));
      if (filterEntityData.filterField === 'relatedentity') {
        setTempFilterEntity({ filter_entity_id: filterEntityData.entity_id, filter_field: filterEntityData.filterField });
      }

      let entityToUpdateForm = formBuilder.filter(form => form.entity_id == filterEntityData?.entity_id)[0];
      let entityToUpdateSelector = selectionBuilder.filter(select => select.entity == filterEntityData?.entity_id)[0];

      entityToUpdateSelector = { ...entityToUpdateSelector, filter: { [filterEntityData.filterField]: '' } };
      let tempSelectorBuilderEntity = selectionBuilder.map(select => {
        return select.entity == filterEntityData?.entity_id ? entityToUpdateSelector : select;
      });
      entityToUpdateForm = { ...entityToUpdateForm, filter: { fieldType: filterEntityData.filterField } };
      let tempFormBuilderEntity = formBuilder.map(form => {
        return form.entity_id == filterEntityData?.entity_id ? entityToUpdateForm : form;
      });
      setSelectionBuilder(tempSelectorBuilderEntity);
      setFormBuilder(tempFormBuilderEntity);
      setFilterEntityData({ entity_id: '', filterField: '' });
    }
  }, [filterEntityData, selectionBuilder, formBuilder]);

  // form action selection
  useEffect(() => {
    if (Object.keys(formAction).length > 0 && formAction.entity_id != '' && formAction.action != null) {
      let entityToUpdateForm = formBuilder.filter(form => form.entity_id == formAction?.entity_id)[0];
      let entityToUpdateSelector = selectionBuilder.filter(select => select.entity == formAction?.entity_id)[0];

      entityToUpdateSelector = { ...entityToUpdateSelector, form_action: formAction.action };

      entityToUpdateForm = { ...entityToUpdateForm, form_action: formAction.action };
      if (formAction.action == FormActionType.REDIRECT) {
        let tempAppScreenList = appScreenList.filter(app => app.id != appScreenId);

        entityToUpdateForm = { ...entityToUpdateForm, redirect_screens: tempAppScreenList };
        delete entityToUpdateSelector['formSuccess_message'];
      } else {
        delete entityToUpdateSelector['redirect_screen'];
      }
      let tempFormBuilderEntity = formBuilder.map(form => {
        return form.entity_id == formAction?.entity_id ? entityToUpdateForm : form;
      });
      let tempSelectorBuilderEntity = selectionBuilder.map(select => {
        return select.entity == formAction?.entity_id ? entityToUpdateSelector : select;
      });
      setSelectionBuilder(tempSelectorBuilderEntity);
      setFormBuilder(tempFormBuilderEntity);
      setFormAction({ entity_id: '', action: '' });
    }
  }, [formAction, selectionBuilder, formBuilder]);

  // set redirect screen Id in fomr builder and selector builder
  useEffect(() => {
    if (Object.keys(redirectScreen).length > 0 && redirectScreen.entity_id != '' && redirectScreen.screenId != null) {
      let entityToUpdateSelector = selectionBuilder.filter(select => select.entity == redirectScreen?.entity_id)[0];
      let entityToUpdateForm = formBuilder.filter(form => form.entity_id == redirectScreen?.entity_id)[0];

      entityToUpdateSelector = { ...entityToUpdateSelector, redirect_screen: redirectScreen.screenId };
      let tempSelectorBuilderEntity = selectionBuilder.map(select => {
        return select.entity == redirectScreen?.entity_id ? entityToUpdateSelector : select;
      });
      entityToUpdateForm = { ...entityToUpdateForm, redirect_screen: redirectScreen.screenId };

      let tempFormBuilderEntity = formBuilder.map(form => {
        return form.entity_id == redirectScreen?.entity_id ? entityToUpdateForm : form;
      });

      setSelectionBuilder(tempSelectorBuilderEntity);
      setFormBuilder(tempFormBuilderEntity);

      setRedirectScreen({ entity_id: '', screenId: '' });
    }
  }, [redirectScreen, selectionBuilder, formBuilder]);
  useEffect(() => {
    if (Object.keys(formSuccessMessage).length > 0 && formSuccessMessage.entity_id != '' && formSuccessMessage.message != null) {
      let entityToUpdateForm = formBuilder.filter(form => form.entity_id == formSuccessMessage?.entity_id)[0];
      let entityToUpdateSelector = selectionBuilder.filter(select => select.entity == formSuccessMessage?.entity_id)[0];

      entityToUpdateSelector = { ...entityToUpdateSelector, formSuccess_message: formSuccessMessage.message };
      let tempSelectorBuilderEntity = selectionBuilder.map(select => {
        return select.entity == formSuccessMessage?.entity_id ? entityToUpdateSelector : select;
      });
      entityToUpdateForm = { ...entityToUpdateForm, formSuccess_message: formSuccessMessage.message };

      let tempFormBuilderEntity = formBuilder.map(form => {
        return form.entity_id == formSuccessMessage?.entity_id ? entityToUpdateForm : form;
      });
      setSelectionBuilder(tempSelectorBuilderEntity);
      setFormBuilder(tempFormBuilderEntity);
      setFormSuccessMessage({ entity_id: '', message: '' });
    }
  }, [formSuccessMessage, selectionBuilder, formBuilder]);

  useEffect(() => {
    if (tempFilterEntity != undefined && !entityRelationshipLoading) {
      let filterentityToUpdateForm = formBuilder.filter(form => form.entity_id == tempFilterEntity?.filter_entity_id)[0];

      filterentityToUpdateForm = { ...filterentityToUpdateForm, filterRelatedEntity: entityRelationshipList };

      let tempFormBuilderFilterEntity = formBuilder.map(form => {
        return form.entity_id == tempFilterEntity?.filter_entity_id ? filterentityToUpdateForm : form;
      });

      setFormBuilder(tempFormBuilderFilterEntity);
      setTempFilterEntity(undefined);
    }
  }, [tempFilterEntity, formBuilder, entityRelationshipList, entityRelationshipLoading]);

  useEffect(() => {
    if (Object.keys(filterRelatedEntity).length > 0 && filterRelatedEntity.entity_id != '' && filterRelatedEntity.relatedEntity != null) {
      let entityToUpdateForm = formBuilder.filter(form => form.entity_id == filterRelatedEntity?.entity_id)[0];

      entityToUpdateForm = {
        ...entityToUpdateForm,
        filter: { ...entityToUpdateForm.filter, relatedEntityName: filterRelatedEntity.relatedEntity },
      };
      let tempFormBuilderEntity = formBuilder.map(form => {
        return form.entity_id == filterRelatedEntity?.entity_id ? entityToUpdateForm : form;
      });

      setFormBuilder(tempFormBuilderEntity);
      setFilterRelatedEntity({ entity_id: '', relatedEntity: '' });
    }
  }, [filterRelatedEntity, selectionBuilder, formBuilder]);

  useEffect(() => {
    if (
      Object.keys(filterEntityText).length > 0 &&
      filterEntityText.entity_id != '' &&
      filterEntityText.filterId != null &&
      filterEntityText.filterType != null
    ) {
      let entityToUpdate = selectionBuilder.filter(selection => selection.entity == filterEntityText?.entity_id)[0];
      let entityToUpdateForm = formBuilder.filter(form => form.entity_id == filterEntityText?.entity_id)[0];

      entityToUpdateForm = {
        ...entityToUpdateForm,
        filter: { ...entityToUpdateForm.filter, filterIds: filterEntityText.filterId },
      };
      let tempFormBuilderEntity = formBuilder.map(form => {
        return form.entity_id == filterEntityText?.entity_id ? entityToUpdateForm : form;
      });
      //update entityfield of selection builder
      if (filterEntityText.filterType === 'id') {
        entityToUpdate = { ...entityToUpdate, filter: { id: filterEntityText.filterId } };
      } else if (filterEntityText.filterType === 'relatedentity') {
        let entityId = entityToUpdateForm.filter.relatedEntityName;
        entityToUpdate = { ...entityToUpdate, filter: { [entityId]: filterEntityText.filterId } };
      }
      let tempSelectionBuilder = selectionBuilder.map(selection => {
        return selection.entity == filterEntityText.entity_id ? entityToUpdate : selection;
      });
      // set updated entityfields

      setSelectionBuilder(tempSelectionBuilder);
      setFormBuilder(tempFormBuilderEntity);
      setFilterEntityText({ entity_id: '', filterId: '', filterType: '' });
    }
  }, [filterEntityText, selectionBuilder, formBuilder]);

  useEffect(() => {
    // checkboxes change handler based on selected entity for entity fields
    if (
      Object.keys(selectEntityField).length > 0 &&
      selectEntityField.entity_id != '' &&
      selectEntityField.checked != null &&
      Object.keys(selectEntityField.field).length > 0
    ) {
      // selection & form builder entity get
      let entityToUpdate = selectionBuilder.filter(selection => selection.entity == selectEntityField?.entity_id)[0];
      let entityToUpdateForm = formBuilder.filter(form => form.entity_id == selectEntityField?.entity_id)[0];

      // check if field is to add or remove
      let formFieldUpdate = entityToUpdateForm?.entity_fields.filter(form => form.id == selectEntityField?.field['id'])[0];

      let fieldsToUpdate = entityToUpdate.entityfields || '';
      if (selectEntityField.checked) {
        //add field
        if (fieldsToUpdate == '') {
          fieldsToUpdate = selectEntityField.field['name'];
        } else {
          fieldsToUpdate = [fieldsToUpdate, selectEntityField.field['name']].join(',');
        }
        formFieldUpdate = { ...formFieldUpdate, checkedField: selectEntityField.checked };
      }
      if (!selectEntityField.checked) {
        if (fieldsToUpdate != '') {
          let fieldsArr = fieldsToUpdate.split(',');
          let fieldsArrIdx = fieldsArr.indexOf(selectEntityField.field['name']);
          if (fieldsArrIdx != -1) {
            fieldsArr.splice(fieldsArrIdx, 1);
          }
          fieldsToUpdate = fieldsArr.join(',');
        }
        formFieldUpdate = { ...formFieldUpdate, checkedField: selectEntityField.checked };
      }
      //update entityfield of form builder
      let tempFormBuilderField = entityToUpdateForm?.entity_fields.map(form => {
        return form.id == selectEntityField?.field['id'] ? formFieldUpdate : form;
      });

      entityToUpdateForm = { ...entityToUpdateForm, entity_fields: tempFormBuilderField };
      let tempFormBuilderEntity = formBuilder.map(form => {
        return form.entity_id == selectEntityField?.entity_id ? entityToUpdateForm : form;
      });
      //update entityfield of selection builder
      entityToUpdate = { ...entityToUpdate, entityfields: fieldsToUpdate };
      let tempSelectionBuilder = selectionBuilder.map(selection => {
        return selection.entity == entityToUpdate.entity ? entityToUpdate : selection;
      });
      // set updated entityfields
      setSelectionBuilder(tempSelectionBuilder);
      setFormBuilder(tempFormBuilderEntity);
      setSelectEntityField({ entity_id: '', checked: null, field: {} });
    }
  }, [selectEntityField, selectionBuilder]);

  // layout change handler based on selected entity
  useEffect(() => {
    if (Object.keys(entityLayout).length > 0 && entityLayout.entity_id != '' && entityLayout.layout != '') {
      //form Builder Layout change
      let subLayoutName = [{ name: 'sidebar' }, { name: 'card' }, { name: 'table' }];
      let entityLayoutToUpdateForm = selectionBuilder.filter(form => form.entity != entityLayout.entity_id);
      let filterSubLayout = [];

      for (let j = 0; j < entityLayoutToUpdateForm.length; j++) {
        let entityToUpdateLayout = formBuilder.filter(selection => selection.entity_id == entityLayoutToUpdateForm[j].entity)[0];

        if (entityToUpdateLayout?.subLayout != undefined) {
          if (template != '2-col') {
            filterSubLayout = subLayoutName?.filter(item => item.name != 'sidebar');
          } else {
            filterSubLayout = subLayoutName?.filter(item => item.name != entityLayoutToUpdateForm[j].layoutTemplate);
          }
        } else {
          filterSubLayout = subLayoutName;
        }
      }

      let entityToUpdateForm = formBuilder.filter(form => form.entity_id == entityLayout.entity_id)[0];

      if (entityLayout.layout == 'list') {
        if (entityLayoutToUpdateForm.length > 0) {
          entityToUpdateForm = { ...entityToUpdateForm, layout: entityLayout.layout, subLayout: filterSubLayout };
        } else {
          if (template != '2-col') {
            filterSubLayout = subLayoutName?.filter(item => item.name != 'sidebar');
            entityToUpdateForm = { ...entityToUpdateForm, layout: entityLayout.layout, subLayout: filterSubLayout };
          } else {
            entityToUpdateForm = { ...entityToUpdateForm, layout: entityLayout.layout, subLayout: subLayoutName };
          }
        }
      } else {
        entityToUpdateForm = { ...entityToUpdateForm, layout: entityLayout.layout };
      }

      //replacing selection
      let tempFormBuilder = formBuilder.map(form => {
        return form.entity_id == entityToUpdateForm.entity_id ? entityToUpdateForm : form;
      });
      setFormBuilder(tempFormBuilder);

      // selection builder layout add
      let entityToUpdate = selectionBuilder.filter(selection => selection.entity == entityLayout.entity_id)[0];
      if (entityLayout.layout == 'list') {
        delete entityToUpdate['form_action'];
        delete entityToUpdate['redirect_screen'];
        delete entityToUpdate['formSuccess_message'];
        entityToUpdate = { ...entityToUpdate, layout: entityLayout.layout };
      } else if (entityLayout.layout == 'details') {
        delete entityToUpdate['form_action'];
        delete entityToUpdate['redirect_screen'];
        delete entityToUpdate['formSuccess_message'];
        entityToUpdate = { ...entityToUpdate, layout: entityLayout.layout };
      } else if (entityLayout.layout == 'form') {
        delete entityToUpdate['form_action'];
        delete entityToUpdate['redirect_screen'];
        delete entityToUpdate['formSuccess_message'];
        entityToUpdate = { ...entityToUpdate, layout: entityLayout.layout };
      } else {
        delete entityToUpdate['layoutTemplate'];
        delete entityToUpdate['navigateScreenView'];

        entityToUpdate = { ...entityToUpdate, layout: entityLayout.layout };
      }
      //replacing selection
      let tempSelectionBuilder = selectionBuilder.map(selection => {
        return selection.entity == entityToUpdate.entity ? entityToUpdate : selection;
      });
      setSelectionBuilder(tempSelectionBuilder);

      setEntityLayout({ entity_id: '', layout: '' });
    }
  }, [entityLayout, selectionBuilder]);

  useEffect(() => {
    if (Object.keys(subLayout).length > 0 && subLayout.entity_id != '' && subLayout.sub_layout != '') {
      //form Builder Layout change

      let entityToUpdateForm = formBuilder.filter(form => form.entity_id == subLayout.entity_id)[0];

      let subLayoutCheck = entityToUpdateForm?.subLayout.map(form =>
        form.name === subLayout.sub_layout ? { ...form, checked: true } : { ...form, checked: false }
      );

      // if (entityLayout.layout == 'list') {
      //   entityToUpdateForm = { ...entityToUpdateForm, layout: entityLayout.layout, subLayout: subLayoutName };

      // }else{
      entityToUpdateForm = { ...entityToUpdateForm, subLayout: subLayoutCheck };

      // }

      // replacing selection
      let tempFormBuilder = formBuilder.map(form => {
        return form.entity_id == entityToUpdateForm.entity_id ? entityToUpdateForm : form;
      });
      setFormBuilder(tempFormBuilder);
      // selection builder layout add
      let entityToUpdate = selectionBuilder.filter(selection => selection.entity == subLayout.entity_id)[0];
      entityToUpdate = { ...entityToUpdate, layoutTemplate: subLayout.sub_layout };

      //replacing selection
      let tempSelectionBuilder = selectionBuilder.map(selection => {
        return selection.entity == entityToUpdate.entity ? entityToUpdate : selection;
      });
      setSelectionBuilder(tempSelectionBuilder);

      setSubLayout({ entity_id: '', sub_layout: '' });
    }
  }, [subLayout, selectionBuilder]);

  // ENTITY FIELD AND RELATIONSHIP ADD IN FORM BUILDER

  useEffect(() => {
    if (temp != undefined && !appEntityFieldsLoading && !entityRelationshipLoading && !loadingScreens ) {
      let tempFormBuilder = formBuilder.filter(entity => entity.entity_id == temp.id)[0];

      if (temp.type == 'entity') {
        tempFormBuilder['entity_fields'] = appEntityFieldEntities.map(entityField => {
          return {
            id: entityField.id,
            name: entityField.name,
            description: entityField.description,
            isRequired: entityField.isRequired,
            type: entityField.type,
          };
        });

        tempFormBuilder['related_entities'] = entityRelationshipList.map(relationship => {
          let entity = relationship.appEntityTo;
          return { entity_id: entity.id, name: entity.name };
        });
      }
      if (temp.type == 'related_entity') {
        let filtered_related_entity = tempFormBuilder.related_entities.filter(entity => entity.entity_id == temp.related_entity_id)[0];
        filtered_related_entity['entity_fields'] = appEntityFieldEntities.map(entityField => {
          return { id: entityField.id, name: entityField.name, description: entityField.description, isRequired: entityField.isRequired };
        });
        tempFormBuilder['selectedRelationShip'] = temp.related_entity_id
        tempFormBuilder['related_entities'] = tempFormBuilder.related_entities.map(entity => {
          return entity.entity_id == temp.related_entity_id ? filtered_related_entity : entity;
        });
      }
      if (tempAppScreen?.type == 'screenDetails') {
        let screens = appScreenList.filter(screen => screen.id != appScreenId);
        tempFormBuilder = { ...tempFormBuilder, viewScreensDetails: screens, editScreensDetails: screens };
      }
      let updatedFormbuilder = formBuilder.map(entity => {
        return entity.entity_id == temp.id ? tempFormBuilder : entity;
      });

      setFormBuilder(updatedFormbuilder);
      setTemp(undefined);
    }
  }, [
    temp,
    formBuilder,
    appEntityFieldEntities,
    tempAppScreen,
    loadingScreens,
    appEntityFieldsLoading,
    entityRelationshipList,
    entityRelationshipLoading,
  ]);

  // ADD SCREEN IN SELECTOR BUILDER

  useEffect(() => {
    if (Object.keys(navigateScreenDetail).length > 0 && navigateScreenDetail.entity_id != '' && navigateScreenDetail.screenId != '') {
      let entityToUpdate = selectionBuilder.filter(selection => selection.entity == navigateScreenDetail.entity_id)[0];
      let tempFormBuilders = formBuilder.filter(form => form.entity_id == navigateScreenDetail.entity_id)[0];
      let getViewNavigateScreen = tempFormBuilders?.viewScreensDetails.filter(item => item.id == navigateScreenDetail.screenId)[0];
      let getEditNavigateScreen = tempFormBuilders?.editScreensDetails.filter(item => item.id == navigateScreenDetail.screenId)[0];

      if (navigateScreenDetail.screenType == 'view') {
        entityToUpdate = { ...entityToUpdate, navigateScreenView: getViewNavigateScreen };
        tempFormBuilders = { ...tempFormBuilders, selectedViewScreen: navigateScreenDetail.screenId };
      } else if (navigateScreenDetail.screenType == 'edit') {
        entityToUpdate = { ...entityToUpdate, navigateScreenEdit: getEditNavigateScreen };
        tempFormBuilders = { ...tempFormBuilders, selectedEditScreen: navigateScreenDetail.screenId };
      }
      //replace form builder data
      let tempFormBuilder = formBuilder.map(form => {
        return form.entity_id == navigateScreenDetail.entity_id ? tempFormBuilders : form;
      });
      //replacing selection
      let tempSelectionBuilder = selectionBuilder.map(selection => {
        return selection.entity == navigateScreenDetail.entity_id ? entityToUpdate : selection;
      });
      setSelectionBuilder(tempSelectionBuilder);
      setFormBuilder(tempFormBuilder);
      setNavigateScreenDetail({ entity_id: '', screenId: '', screenType: '' });
    }
  }, [navigateScreenDetail, selectionBuilder]);

  // SET ALL SCREEN IN FORM BUILDER

  // useEffect(() => {
  //   if (!loadingScreens && tempAppScreen != undefined) {
  //     let tempScreenFormBuilder = formBuilder.filter(entity => entity.entity_id == tempAppScreen.id)[0];

  //     let screens = appScreenList.filter(screen => screen.id != appScreenId);
  //     tempScreenFormBuilder = { ...tempScreenFormBuilder, viewScreensDetails: screens, editScreensDetails: screens };

  //     let updatedScreenFormbuilder = formBuilder.map(entity => {
  //       return entity.entity_id == tempAppScreen.id ? tempScreenFormBuilder : entity;
  //     });

  //     setFormBuilder(updatedScreenFormbuilder);
  //     setTempAppScreen(undefined);
  //   }
  // }, [tempAppScreen, formBuilder, appScreenList, loadingScreens]);

  // SELECT ENTITY DISPTACH ENTITY FIELDS AND RELATIONSHIP

  useEffect(() => {
    if (selectedEntityId != undefined && entitiesCopy.length > 0) {
      dispatch(getEntityFields({ query: 'appEntityId.equals=' + selectedEntityId }));
      if (!appEntityFieldsLoading) {
        setTemp({ id: selectedEntityId, type: 'entity' });
      }

      dispatch(getRelationship({ query: 'appEntityFromId.equals=' + selectedEntityId }));
      let existingFormBuilder = formBuilder;
      let filteredEntitydata = entitiesCopy.filter(entity => entity.id == selectedEntityId)[0];
      existingFormBuilder.push({ entity_id: filteredEntitydata.id, name: filteredEntitydata.name });
      setFormBuilder(existingFormBuilder);
      dispatch(
        getScreens({
          page: 0,
          size: 100,
          sort: `${paginationState.sort},${paginationState.order}`,
          query: `appId.equals=${appId}`,
        })
      );
      if (!loadingScreens) {
        setTempAppScreen({ id: selectedEntityId, type: 'screenDetails' });
      }
      setEntitiesCopy(entitiesCopy.filter(entity => entity.id != selectedEntityId));
      let tempselectionBuilder = selectionBuilder;
      tempselectionBuilder.push({ entity: selectedEntityId, name: filteredEntitydata.name });
      setSelectionBuilder(tempselectionBuilder);

      setSelectedEntityId(undefined);
    }
  }, [entitiesCopy, selectedEntityId, appEntities, formBuilder, selectionBuilder]);

  const removeEntity = async (entity_id, screen_id) => {
    setSelectionBuilder(selectionBuilder.filter(selection => selection.entity != entity_id));
    setFormBuilder(formBuilder.filter(formObj => formObj.entity_id != entity_id));
    const entityCopy = [...entitiesCopy];
    const entityObj = tempEntitiesCopy.find(entity => entity.id === entity_id);
    if (screen_id != undefined) {
      await dispatch(deleteScreenComponent(screen_id));
      await dispatch(getScreenComponents({ query: 'appScreenId.equals=' + appScreenId }));
    }
    entityCopy.push(entityObj);

    entityCopy.sort((a, b) => a.id - b.id);
    setEntitiesCopy(entityCopy);
  };

  const removeRelatedEntity = async (entity_id, parentEntity_id, screen_id) => {
    const updatedSelectionBuilder = selectionBuilder.map(selection =>
      selection.entity === parentEntity_id
        ? {
            ...selection,
            related_entities: selection.related_entities.filter(
              related_entity => related_entity.entity_id !== entity_id && Object.keys(related_entity).length !== 0
            ),
          }
        : selection
    );

    setSelectionBuilder(updatedSelectionBuilder);

    const updatedFormBuilder = formBuilder.map(formEntity =>
      formEntity.entity_id === parentEntity_id
        ? {
            ...formEntity,
            related_entities: formEntity.related_entities.map(related_entity =>
              related_entity.entity_id === entity_id
                ? {
                    ...related_entity,
                    entity_fields: undefined,
                  }
                : related_entity
            ),
          }
        : formEntity
    );

    setFormBuilder(updatedFormBuilder);
    if (screen_id != undefined) {
      await dispatch(deleteScreenComponent(screen_id));
    }
  };
  const handleClose = () => {
    navigate(`/product/${productId}/app/${appId}` + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(appScreenId));
      dispatch(getScreenComponents({ query: 'appScreenId.equals=' + appScreenId }));
      dispatch(
        getScreens({
          page: 0,
          size: 100,
          sort: `${paginationState.sort},${paginationState.order}`,
          query: `appId.equals=${appId}`,
        })
      );
    }

    dispatch(
      getAppEntities({
        page: paginationState.activePage - 1,
        size: 25,
        sort: `${paginationState.sort},${paginationState.order}`,
        query: `appId.equals=${appId}`,
      })
    );
  }, []);

  // fetch screen component data
  useEffect(() => {
    const fetchScreenComponet = async () => {
      let existingFormBuilder = [];
      let existSelectionBuilder = [];
      let filterRelationshipSelection = [];
      let tempScreenCheck = appScreenList;

      let subLayoutName = [{ name: 'sidebar' }, { name: 'card' }, { name: 'table' }];
      let filterSubLayout = [];
      if (!isNew && screenComponent.length > 0) {
        let filterAppScreenEntity = await screenComponent.filter(entity => entity?.appScreen?.id == appScreenId);

        const { payload } = await dispatch(
          getScreens({
            page: 0,
            size: 100,
            sort: `${paginationState.sort},${paginationState.order}`,
            query: `appId.equals=${appId}`,
          })
        );

        let getAllScreens = payload['data'];

        if (filterAppScreenEntity.length > 0 && template != 'tabs') {
          if (!loadingScreens) {
            setTempAppScreen({ id: selectedEntityId, type: 'screenDetails' });
          }
          let filterPresentScreen = getAllScreens.filter(screen => screen.id != appScreenId);

          let filterScreenComponent = filterAppScreenEntity.filter(entity => entity?.screenComponent == null);
          const promise = filterScreenComponent.map(async (parentfilterScreenComponennt: any) => {
            const { payload } = await dispatch(
              getEntityFields({ query: 'appEntityId.equals=' + parentfilterScreenComponennt?.appEntity?.id })
            );

            let appEntityFields = payload['data'];

            const relationshipEntity = await dispatch(
              getRelationship({ query: 'appEntityFromId.equals=' + parentfilterScreenComponennt?.appEntity?.id })
            );

            let fetchFilterRelatedEntity = relationshipEntity?.payload['data'];

            let selectedRelationshipEntity = screenComponent.filter(
              entity => entity?.screenComponent?.id == parentfilterScreenComponennt?.id
            );

            let filterRelationshipForm = [];
            let selectedRelationShipEntityId = '';

            const relationShipPromise = Promise.all(
              fetchFilterRelatedEntity.map(async (parentFilterRelationShipEntity: any) => {
                let tempRelationshipObj = {};
                await Promise.all(
                  selectedRelationshipEntity.map(async (parentselectedRelation: any) => {
                    if (
                      parentselectedRelation?.screenComponent != null &&
                      parentselectedRelation?.appEntity?.id == parentFilterRelationShipEntity?.appEntityTo?.id
                    ) {
                      let relationshipFieldNameSaved =
                        parentselectedRelation?.entityFields != null ? parentselectedRelation?.entityFields?.split(',') : [];

                      let relationshipEntityField = parentFilterRelationShipEntity?.appEntityTo?.appEntityFields.map(fields =>
                        relationshipFieldNameSaved.includes(fields.name) ? { ...fields, checkedField: true } : { ...fields }
                      );

                      tempRelationshipObj = {
                        ...tempRelationshipObj,
                        entity_fields: relationshipEntityField,
                        screen_id: parentselectedRelation?.id,
                      };
                    }

                    selectedRelationShipEntityId = parentselectedRelation?.appEntity?.id;
                  })
                );
                tempRelationshipObj = {
                  ...tempRelationshipObj,
                  entity_id: parentFilterRelationShipEntity?.appEntityTo?.id,
                  name: parentFilterRelationShipEntity?.appEntityTo?.name,
                };

                let existingRelationShipIndex = filterRelationshipForm.findIndex(
                  item => item?.entity_id === tempRelationshipObj['entity_id']
                );

                if (existingRelationShipIndex !== -1) {
                  // Merge the existing object with the new one
                  filterRelationshipForm[existingRelationShipIndex] = {
                    ...filterRelationshipForm[existingRelationShipIndex],
                    ...tempRelationshipObj,
                  };
                } else {
                  // Add the new object to filterRelationshipForm
                  filterRelationshipForm.push(tempRelationshipObj);
                }
              })
            );

            await relationShipPromise;

            let entityFieldNameSaved =
              parentfilterScreenComponennt.entityFields != null ? parentfilterScreenComponennt.entityFields?.split(',') : [];

            let entityField = appEntityFields.map(fields =>
              entityFieldNameSaved.includes(fields.name) ? { ...fields, checkedField: true } : { ...fields }
            );

            if (template != '2-col') {
              filterSubLayout = subLayoutName?.filter(item => item.name != 'sidebar');
              filterSubLayout = filterSubLayout.map(item =>
                item.name === parentfilterScreenComponennt?.layoutTemplate ? { ...item, checked: true } : { ...item, checked: false }
              );
            } else {
              filterSubLayout = subLayoutName.map(item =>
                item.name === parentfilterScreenComponennt?.layoutTemplate ? { ...item, checked: true } : { ...item, checked: false }
              );
            }
            let tempFilterObject = {};
            let filterObject = JSON.parse(parentfilterScreenComponennt.filter);
            if (filterObject) {
              let filterObjectKey = Object.keys(filterObject)[0];
              if (filterObjectKey == 'id') {
                tempFilterObject = { fieldType: filterObjectKey, filterIds: filterObject[filterObjectKey] };
              } else {
                tempFilterObject = {
                  fieldType: 'relatedentity',
                  relatedEntityName: filterObjectKey,
                  filterIds: filterObject[filterObjectKey],
                };
              }
            }

            existingFormBuilder.push({
              entity_id: parentfilterScreenComponennt?.appEntity?.id,
              name: parentfilterScreenComponennt?.appEntity?.name,
              entity_fields: entityField,
              related_entities: filterRelationshipForm,
              selectedRelationShip: selectedRelationShipEntityId,
              layout: parentfilterScreenComponennt?.layout,
              screen_id: parentfilterScreenComponennt?.id,
              subLayout: filterSubLayout,
              editScreensDetails: filterPresentScreen,
              viewScreensDetails: filterPresentScreen,
              selectedViewScreen: parentfilterScreenComponennt?.appScreenviewDetail?.id,
              selectedEditScreen: parentfilterScreenComponennt?.appScreenviewEdit?.id,
              filter: tempFilterObject,
              filterRelatedEntity: fetchFilterRelatedEntity,
              redirect_screens: filterPresentScreen,
              form_action: parentfilterScreenComponennt?.formAction,
              redirect_screen: parentfilterScreenComponennt?.appScreenformSuccessRedirectScreen?.id,
              formSuccess_message: parentfilterScreenComponennt?.formSuccessMessage,
            });

            filterRelationshipSelection = screenComponent
              .filter(entity => entity?.screenComponent?.id == parentfilterScreenComponennt.id)
              .map(relationship => {
                return {
                  entity_id: relationship.appEntity.id,
                  name: relationship.appEntity.name,
                  entityfields: relationship.entityFields,
                  screen_id: relationship.id,
                };
              });
            existSelectionBuilder.push({
              entity: parentfilterScreenComponennt?.appEntity?.id,
              name: parentfilterScreenComponennt?.appEntity?.name,
              entityfields: parentfilterScreenComponennt?.entityFields,
              related_entities: filterRelationshipSelection,
              layout: parentfilterScreenComponennt?.layout,
              screen_id: parentfilterScreenComponennt?.id,
              layoutTemplate: parentfilterScreenComponennt?.layoutTemplate,
              navigateScreenView: parentfilterScreenComponennt?.appScreenviewDetail,
              navigateScreenEdit: parentfilterScreenComponennt?.appScreenviewEdit,
              filter: filterObject,
              form_action: parentfilterScreenComponennt?.formAction,
              redirect_screen: parentfilterScreenComponennt?.appScreenformSuccessRedirectScreen?.id,
              formSuccess_message: parentfilterScreenComponennt?.formSuccessMessage,
            });
          });
          await Promise.all(promise);
        }
        if (filterAppScreenEntity.length > 0 && template == 'tabs') {
          const appScreenPromise = filterAppScreenEntity.map(async (appScreenTabs: any) => {
            if (tempScreenCheck.length > 0) {
              tempScreenCheck = tempScreenCheck.map(screen =>
                screen.id === appScreenTabs?.appScreenviewDetail?.id ? { ...screen, checkScreen: true } : { ...screen }
              );

              existSelectionBuilder.push({
                navigateScreenView: appScreenTabs?.appScreenviewDetail,
                screen_id: appScreenTabs?.id,
              });
            }
          });
          Promise.all(appScreenPromise);
        }
      }
      tempScreenCheck = tempScreenCheck.filter(screen => screen.template != 'tabs');
      setTempAppScreenList(tempScreenCheck);
      setSelectionBuilder(existSelectionBuilder);

      setFormBuilder(existingFormBuilder);
    };
    if (!isNew) {
      fetchScreenComponet();
    }
  }, [screenComponent]);

  useEffect(() => {
    if (updateSuccess) {
      if (!isNew && appScreenComponentUpdate) {
        handleClose();
      } else {
        handleClose();
      }
    }
  }, [updateSuccess, appScreenComponentUpdate]);
  const removeScreenComponent = async screenId => {
    await dispatch(deleteScreenComponent(screenId));
    await dispatch(getScreenComponents({ query: 'appScreenId.equals=' + appScreenId }));
  };

  useEffect(() => {
    if (tempAppScreenId != undefined && template == 'tabs' && tempAppScreenList.length > 0) {
      let tempselectionBuilder = selectionBuilder;

      let findArrIdx = tempselectionBuilder
        .map(function (x) {
          return x?.navigateScreenView?.id;
        })
        .indexOf(tempAppScreenId);
      let tempScreenCheck = tempAppScreenList.filter(screen => screen.id === tempAppScreenId)[0];

      if (findArrIdx != -1) {
        let checkScreenId = tempselectionBuilder[findArrIdx];

        if (checkScreenId?.screen_id) {
          removeScreenComponent(checkScreenId?.screen_id);
        }
        tempselectionBuilder.splice(findArrIdx, 1);
        tempScreenCheck = { ...tempScreenCheck, checkScreen: false };
      } else {
        let tempAppScreenObj = tempAppScreenList.filter(screen => screen.id == tempAppScreenId)[0];
        // if (tempselectionBuilderObj == undefined) {
        tempselectionBuilder.push({ navigateScreenView: tempAppScreenObj });
        tempScreenCheck = { ...tempScreenCheck, checkScreen: true };
      }
      let tempScreenCheckArr = tempAppScreenList.map(screen => {
        return screen.id === tempAppScreenId ? tempScreenCheck : screen;
      });

      setTempAppScreenList(tempScreenCheckArr);
      setSelectionBuilder(tempselectionBuilder);
      setTempAppScreenId(undefined);
    }
  }, [tempAppScreenId, tempAppScreenList]);
  useEffect(() => {
    if (template == 'tabs' && appScreenList.length > 0 && isNew) {
      let tempScreenCheck = appScreenList;
      tempScreenCheck = tempScreenCheck.filter(screen => screen.template != 'tabs');
      setTempAppScreenList(tempScreenCheck);
    }
  }, [appScreenList]);

  const handleTemplateLayout = async event => {
    setTemplate(event.target.value);
    if (event.target.value == 'tabs') {
      await dispatch(
        getScreens({ page: 0, size: 100, sort: `${paginationState.sort},${paginationState.order}`, query: `appId.equals=${appId}` })
      );
    }
  };

  // create screen components
  const createScreenComponents = async screen_id => {
    const promises = selectionBuilder.map(async (parentScreenComponentData: any) => {
      let screenComponentEntity = {
        layout: parentScreenComponentData.layout || 'list',
        appScreen: { id: screen_id.toString() },
        entityFields: parentScreenComponentData.entityfields,
        appEntity: parentScreenComponentData.entity ? { id: parentScreenComponentData.entity } : null,
        layoutTemplate: parentScreenComponentData.layoutTemplate || '',
        appScreenviewDetail: parentScreenComponentData.navigateScreenView || null,
        appScreenviewEdit: parentScreenComponentData.navigateScreenEdit || null,
        filter: JSON.stringify(parentScreenComponentData.filter),
        formAction: parentScreenComponentData.form_action || null,
        appScreenformSuccessRedirectScreen: parentScreenComponentData.redirect_screen
          ? { id: parentScreenComponentData.redirect_screen }
          : null,
        formSuccessMessage: parentScreenComponentData.formSuccess_message || null,
      };
      var entityCreateResult: any = await dispatch(createScreenComponent(screenComponentEntity));
      var screenComponentId = entityCreateResult.payload.data.id;
      if (parentScreenComponentData.related_entities && parentScreenComponentData.related_entities.length > 0 && screenComponentId) {
        const relatedEntityPromises = parentScreenComponentData.related_entities.map(async (relatedEntityData: any) => {
          let screenRelatedComponentEntity: any = {
            layout: relatedEntityData.layout || '',
            appScreen: { id: screen_id.toString() },
            entityFields: relatedEntityData.entityfields,
            appEntity: { id: relatedEntityData.entity_id },
            screenComponent: { id: screenComponentId },
          };
          await dispatch(createScreenComponent(screenRelatedComponentEntity));
        });
        await Promise.all(relatedEntityPromises);
      }
    });
    await Promise.all(promises);
  };
  // update screen components
  const updateScreenComponents = async entity => {
    let appScreen_id = entity.id.toString();
    const promises = selectionBuilder.map(async (parentScreenComponentData: any) => {
      let screenComponentEntity = {
        layout: parentScreenComponentData.layout || 'list',
        appScreen: { id: appScreen_id },
        entityFields: parentScreenComponentData.entityfields,
        appEntity: parentScreenComponentData.entity ? { id: parentScreenComponentData.entity } : null,
        id: parentScreenComponentData.screen_id,
        layoutTemplate: parentScreenComponentData.layoutTemplate || '',
        appScreenviewDetail: parentScreenComponentData.navigateScreenView || null,
        appScreenviewEdit: parentScreenComponentData.navigateScreenEdit || null,
        filter: JSON.stringify(parentScreenComponentData.filter),
        formAction: parentScreenComponentData.form_action || null,
        appScreenformSuccessRedirectScreen: parentScreenComponentData.redirect_screen
          ? { id: parentScreenComponentData.redirect_screen }
          : null,
        formSuccessMessage: parentScreenComponentData.formSuccess_message || null,
      };

      var entityUpdateResult: any = parentScreenComponentData.screen_id
        ? await dispatch(updateScreenComponent(screenComponentEntity))
        : await dispatch(createScreenComponent(screenComponentEntity));

      var screenComponentId = entityUpdateResult.payload.data.id;
      if (parentScreenComponentData.related_entities && parentScreenComponentData.related_entities.length > 0 && screenComponentId) {
        const relatedEntityPromises = parentScreenComponentData.related_entities.map(async (relatedEntityData: any) => {
          let screenRelatedComponentEntity: any = {
            layout: relatedEntityData.layout || '',
            appScreen: { id: appScreen_id },
            entityFields: relatedEntityData.entityfields,
            appEntity: { id: relatedEntityData.entity_id },
            screenComponent: { id: screenComponentId },
            id: parseInt(relatedEntityData.screen_id),
          };
          relatedEntityData.screen_id
            ? await dispatch(updateScreenComponent(screenRelatedComponentEntity))
            : await dispatch(createScreenComponent(screenRelatedComponentEntity));
        });
        await Promise.all(relatedEntityPromises);
      }
    });
    await Promise.all(promises);
  };

  const saveEntity = async values => {
    // debugger
    let entity = {
      ...appScreenEntity,
      ...values,
      app: { id: appId },
      accessRoles: rSelected,
      template: template,
      title: appScreenTitle,
      path: appScreenPath,
      // appentity: updatedSelectedFields.find(item => item.id.toString() === values.appEntity.toString()),
      // appentity: updatedSelectedFields,
    };

    if (isNew) {
      const { payload } = await dispatch(createEntity(entity));
      let screen_id = payload['data'].id;

      if (screen_id) {
        await createScreenComponents(screen_id);
      }
    } else {
      let tempScreenComponentsFilter = appScreenEntity?.screenComponents?.filter(
        screen =>
          selectionBuilder.map(function (entity) {
            return entity?.screen_id
          }).includes(screen?.id)
      );
      console.log("🚀 ~ file: app-screen-update.tsx:1138 ~ saveEntity ~ tempScreenComponentsFilter:", tempScreenComponentsFilter)
      entity = {...entity, screenComponents: tempScreenComponentsFilter}
      console.log("🚀 ~ file: app-screen-update.tsx:1140 ~ saveEntity ~ entity:", entity)
      dispatch(updateEntity(entity));
      if (entity) {
        updateScreenComponents(entity);
      }
    }
  };

  const defaultValues = () =>
    isNew
      ? chatGptSearchData?.entity?.identifier
        ? { title: chatGptSearchData.entity.identifier }
        : {}
      : {
          ...appScreenEntity,
          app: appScreenEntity?.app?.id,
          // functionality: appScreenEntity?.functionality?.id,
        };

  useEffect(() => {
    if (appEntities.length > 0 && template != 'tabs') {
      let newElems = [];
      appEntities.forEach(item => {
        let find = tempEntitiesCopy.find(existingElem => existingElem.value === item.id);

        if (!find) {
          newElems.push({ ...item, value: item.id, label: item.name });
        }
      });

      setEntitiesCopy(entitiesCopy => [...entitiesCopy, ...newElems]);
      setTempEntitiesCopy(tempEntitiesCopy => [...tempEntitiesCopy, ...newElems]);
    }
  }, [appEntities]);
  useEffect(() => {
    if (!isNew) {
      const loadData = async () => {
        if (tempEntitiesCopy.length >= appEntitiesTotalItems) {
          let entityCopyTemp = [...entitiesCopy];
          let filterAppScreenEntity = screenComponent.filter(entity => entity?.appScreen?.id == appScreenId);
          let filterScreenComponent = filterAppScreenEntity.filter(entity => entity?.screenComponent == null);

          const removeAppEntities = await filterScreenComponent.map(async (parentScreen: any) => {
            entityCopyTemp = entityCopyTemp.filter(item => item.id != parentScreen.appEntity.id);
            setEntitiesCopy(entityCopyTemp);
          });
          await Promise.all(removeAppEntities);

          return;
        }
        const apiData = await dispatch(
          getAppEntities({
            page: paginationState.activePage,
            size: 25,
            sort: `${paginationState.sort},${paginationState.order}`,
            query: !!appId ? `appId.equals=${appId}` : '',
          })
        );
        let getAppEntitiesData = apiData?.payload['data'];
        if (getAppEntitiesData.length > 0) {
          setPaginationState(prevPageState => ({ ...prevPageState, activePage: paginationState.activePage + 1 }));
        }
      };
      loadData();
    }
  }, [paginationState.activePage, tempEntitiesCopy]);
  useEffect(() => {
        setTemplate(appScreenEntity?.template);
    setRSelected(appScreenEntity?.accessRoles);
    if (!isNew && appScreenEntity && appScreenEntity.title) {
      setAppScreenTitle(appScreenEntity.title);
    }
    if (!isNew && appScreenEntity && appScreenEntity.path) {
      setAppScreenPath(appScreenEntity.path);
    }
  }, [appScreenEntity]);
  useEffect(() => {
    setScreenComponent(appScreenComponent);
  }, [appScreenComponent]);

  const handleAppScreenTitleChange = e => {
    setAppScreenTitle(e.target.value);
  };

  const handleAppScreenPathChange = e => {
    setAppScreenPath(e.target.value);
  };
  const handleAppEntityFromChange = e => {
    var entity = appEntities.find(it => it.id.toString() === e.value.toString());
    setSelectedEntityId(e.value);
    // setSelectedEntityFrom(entity);
    // setEntityFieldsFrom(entity && entity.appEntityFields && entity.appEntityFields != null && entity.appEntityFields);
    // setIsEntityFieldFromDisabled(false);
  };
 
  return (
    <div className="inner-content-wrap">
      <Row className="justify-content-center">
        <Col md="12">
          <h2 className="row  page-title" id="conductorMonolithApp.appScreen.home.createOrEditLabel" data-cy="AppScreenCreateUpdateHeading">
            <Label>{isNew ? `Add` : `Manage`} App Screen</Label>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="12">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity} className="row">
              <ValidatedField
                className="col-md-6"
                label={translate('conductorMonolithApp.appScreen.title')}
                id="app-screen-title"
                name="appScreenTitle"
                data-cy="appScreenTitle"
                type="text"
                value={appScreenTitle}
                onChange={handleAppScreenTitleChange}
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                className="col-md-6"
                label={translate('conductorMonolithApp.appScreen.path')}
                id="app-screen-path"
                name="appScreenPath"
                data-cy="appScreenPath"
                type="text"
                value={appScreenPath}
                onChange={handleAppScreenPathChange}
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              {/* convert temaplate field to dropdown with values "Single column (col)" and "Two Column (2-col)"
              if selected is template 2 column then sidebar show */}
              {/* if sidebar is selected for one entity then it will not be selected for second entity */}
              <ValidatedField
                className="col-md-6"
                label={translate('conductorMonolithApp.appScreen.template')}
                id="app-screen-template"
                name="templates"
                data-cy="template"
                value={template}
                type="select"
                onChange={handleTemplateLayout}
              >
                <option value="col">Single Column</option>
                <option value="2-col">Double Column</option>
                <option value="tabs">Tabs</option>
              </ValidatedField>
              <Col md="6" className="mb-3">
                <Label>{translate('conductorMonolithApp.appScreen.accessRoles')}</Label>
                <ButtonGroup style={{ width: '100%' }}>
                  <Button color="primary" outline onClick={() => setRSelected('ADMIN')} active={rSelected === 'ADMIN'}>
                    Admin
                  </Button>
                  <Button color="primary" outline onClick={() => setRSelected('USER')} active={rSelected === 'USER'}>
                    User
                  </Button>
                  <Button color="primary" outline onClick={() => setRSelected('PUBLIC')} active={rSelected === 'PUBLIC'}>
                    Public
                  </Button>
                </ButtonGroup>
              </Col>
              <Col className="col-md-12 mb-6">
                <Row>
                  <Col className="col-md-6 mb-3" style={{ padding: '0px' }}>
                    {formBuilder.length > 0 ? (
                      formBuilder.map(entity => {
                        return (
                          // <Row>
                          // <Col className="col-md-6 mb-3" style={{ paddingRight: 0 }}>
                          <Container
                            className="bg-light border"
                            fluid="md"
                            style={{
                              paddingTop: '20px',
                              paddingBottom: '20px',
                              margin: '20px 0px',
                            }}
                          >
                            <h3 className="entityTitle">
                              {' '}
                              Entity Name : {entity.name}{' '}
                              <Button
                                className="del-entityScreen"
                                id={`deleteapp-${entity.entity_id}`}
                                onClick={e => {
                                  removeEntity(entity.entity_id, entity.screen_id);
                                }}
                              >
                                <FontAwesomeIcon icon={faXmark} />{' '}
                              </Button>
                            </h3>
                            <Row>
                              <Col>
                                <ValidatedField
                                  name={`layout-${entity.name}`}
                                  type="select"
                                  label="Layout :"
                                  value={entity.layout}
                                  required
                                  onChange={e => {
                                    setEntityLayout({
                                      layout: e.target.value,
                                      entity_id: entity.entity_id,
                                    });
                                  }}
                                >
                                  <option value="">Select One</option>
                                  <option value="list">List</option>
                                  <option value="details">Details</option>
                                  <option value="form">Form</option>
                                </ValidatedField>
                              </Col>
                              <Col>
                                {/* checkboxes with values "sidebar", "card", "table"*/}
                                {entity.layout === 'list' && entity?.subLayout?.length > 0 ? (
                                  <>
                                    <Label>Sub Layout : </Label>
                                    <FormGroup>
                                      {entity.subLayout.map(sub => {
                                        return (
                                          <>
                                            <Input
                                              name={`sub-layout-${entity.name}`}
                                              type="radio"
                                              className="mx-1"
                                              checked={sub.checked}
                                              required
                                              onChange={e => {
                                                setSubLayout({
                                                  entity_id: entity.entity_id,
                                                  sub_layout: sub.name,
                                                });
                                              }}
                                            />
                                            <Label check className="me-2">
                                              {sub.name}
                                            </Label>
                                          </>
                                        );
                                      })}
                                    </FormGroup>
                                  </>
                                ) : (
                                  ''
                                )}
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Label>Select Fields : </Label>
                                {entity && entity.entity_fields ? (
                                  entity.entity_fields.map(field => {
                                    return (
                                      <>
                                        <Input
                                          type="checkbox"
                                          style={{ marginLeft: '5px', marginRight: '5px' }}
                                          checked={field.checkedField}
                                          onChange={e =>
                                            setSelectEntityField({
                                              entity_id: entity.entity_id,
                                              checked: e.target.checked,
                                              field: field,
                                            })
                                          }
                                        />
                                        <Label check style={{ marginRight: '10px' }}>
                                          {field.name}
                                        </Label>
                                      </>
                                    );
                                  })
                                ) : (
                                  <Spinner size="sm" color="primary">
                                    Loading...
                                  </Spinner>
                                )}
                              </Col>
                            </Row>
                            <Row>
                              <Label>Filter on: </Label>
                              <Col>
                                <ValidatedField
                                  name={`filter-id-${entity.name}`}
                                  type="select"
                                  value={entity?.filter?.fieldType}
                                  onChange={e => {
                                    setFilterEntityData({ entity_id: entity.entity_id, filterField: e.target.value });
                                  }}
                                >
                                  <option value="">Select One</option>
                                  <option value="id">Id</option>
                                  <option value="relatedentity">Related Entity</option>
                                </ValidatedField>
                              </Col>
                              <Col>
                                {entity.filter && entity.filter.fieldType === 'id' && (
                                  <ValidatedField
                                    name="id"
                                    type="text"
                                    value={entity?.filter?.filterIds}
                                    onChange={e =>
                                      setFilterEntityText({
                                        entity_id: entity.entity_id,
                                        filterId: e.target.value,
                                        filterType: entity.filter.fieldType,
                                      })
                                    }
                                  ></ValidatedField>
                                )}
                                {entity.filter &&
                                  entity.filter.fieldType === 'relatedentity' &&
                                  (entity && entity.filterRelatedEntity ? (
                                    <ValidatedField
                                      name={`filter-relatedentity-${entity.name}`}
                                      type="select"
                                      value={entity?.filter?.relatedEntityName}
                                      onChange={e => {
                                        setFilterRelatedEntity({ entity_id: entity.entity_id, relatedEntity: e.target.value });
                                      }}
                                    >
                                      <option value="">Select One</option>
                                      {entity.filterRelatedEntity &&
                                        entity.filterRelatedEntity.length > 0 &&
                                        entity.filterRelatedEntity.map(item => (
                                          <option value={item?.appEntityTo?.name}>{item?.appEntityTo?.name}</option>
                                        ))}
                                    </ValidatedField>
                                  ) : (
                                    <Spinner size="sm" color="primary">
                                      Loading...
                                    </Spinner>
                                  ))}
                              </Col>
                              <Col>
                                {entity.filter && entity.filter.relatedEntityName && (
                                  <ValidatedField
                                    name="id"
                                    type="text"
                                    value={entity.filter.filterIds}
                                    onChange={e =>
                                      setFilterEntityText({
                                        entity_id: entity.entity_id,
                                        filterId: e.target.value,
                                        filterType: entity.filter.fieldType,
                                      })
                                    }
                                  ></ValidatedField>
                                )}
                              </Col>
                            </Row>
                            {entity.layout && entity.layout != 'form' && (
                              <Row>
                                <Col>
                                  <Label>Navigate View Screen: </Label>
                                  {entity && entity.viewScreensDetails ? (
                                    <ValidatedField
                                      name={`navigate-${entity.name}`}
                                      type="select"
                                      value={entity.selectedViewScreen}
                                      onChange={e => {
                                        setNavigateScreenDetail({
                                          entity_id: entity.entity_id,
                                          screenId: e.target.value,
                                          screenType: 'view',
                                        });
                                      }}
                                    >
                                      <option value="">Select One</option>
                                      {entity &&
                                        entity.viewScreensDetails &&
                                        entity.viewScreensDetails.length > 0 &&
                                        entity.viewScreensDetails.map(screen => {
                                          return <option value={screen.id}>{screen.title}</option>;
                                        })}
                                    </ValidatedField>
                                  ) : (
                                    <Spinner size="sm" color="primary">
                                      Loading...
                                    </Spinner>
                                  )}
                                </Col>
                                <Col>
                                  <Label> Navigate Edit Screen : </Label>
                                  {entity && entity.editScreensDetails ? (
                                    <ValidatedField
                                      name={`navigate-${entity.name}`}
                                      type="select"
                                      value={entity.selectedEditScreen}
                                      onChange={e => {
                                        setNavigateScreenDetail({
                                          entity_id: entity.entity_id,
                                          screenId: e.target.value,
                                          screenType: 'edit',
                                        });
                                      }}
                                    >
                                      <option value="">Select One</option>
                                      {entity &&
                                        entity.editScreensDetails &&
                                        entity.editScreensDetails.length > 0 &&
                                        entity.editScreensDetails.map(screen => {
                                          return <option value={screen.id}>{screen.title}</option>;
                                        })}
                                    </ValidatedField>
                                  ) : (
                                    <Spinner size="sm" color="primary">
                                      Loading...
                                    </Spinner>
                                  )}
                                </Col>
                              </Row>
                            )}{' '}
                            {entity.layout && entity.layout == 'form' && (
                              <Row>
                                <Col>
                                  <Label>Form Action: </Label>
                                  {
                                    <ValidatedField
                                      name={`form-action-${entity.name}`}
                                      type="select"
                                      value={entity.form_action}
                                      onChange={e => {
                                        setFormAction({
                                          entity_id: entity.entity_id,
                                          action: e.target.value,
                                        });
                                      }}
                                    >
                                      <option value="">Select One</option>
                                      <option value={FormActionType.REDIRECT}>Redirect</option>
                                      <option value={FormActionType.MESSAGE}>Show Message</option>
                                    </ValidatedField>
                                  }
                                </Col>

                                <Col>
                                  {entity && entity.form_action == FormActionType.REDIRECT && (
                                    <>
                                      <Label>Screen: </Label>
                                      {entity && entity.redirect_screens ? (
                                        <ValidatedField
                                          name={`redirect-screen-${entity.name}`}
                                          type="select"
                                          value={entity.redirect_screen}
                                          onChange={e => {
                                            setRedirectScreen({ entity_id: entity.entity_id, screenId: e.target.value });
                                          }}
                                        >
                                          <option value="">Select One</option>
                                          {entity &&
                                            entity.redirect_screens &&
                                            entity.redirect_screens.length > 0 &&
                                            entity.redirect_screens.map(screen => {
                                              return <option value={screen.id}>{screen.title}</option>;
                                            })}
                                        </ValidatedField>
                                      ) : (
                                        <Spinner size="sm" color="primary">
                                          Loading...
                                        </Spinner>
                                      )}
                                    </>
                                  )}
                                  {entity && entity.form_action == FormActionType.MESSAGE && (
                                    <>
                                      <Label>Message: </Label>

                                      <ValidatedField
                                        name="form-action-message"
                                        type="text"
                                        value={entity.formSuccess_message}
                                        onChange={e => {
                                          setFormSuccessMessage({ entity_id: entity.entity_id, message: e.target.value });
                                        }}
                                      ></ValidatedField>
                                    </>
                                  )}
                                </Col>
                              </Row>
                            )}
                            <Row>
                              {entity.related_entities &&
                                entity.related_entities.length > 0 &&
                                entity.related_entities.map(related_entity => {
                                  return (
                                    <>
                                      {related_entity.entity_fields && related_entity.entity_fields.length > 0 ? (
                                        <Container
                                          className="bg-light border hide"
                                          fluid="md"
                                          style={{
                                            paddingTop: '20px',
                                            paddingBottom: '20px',
                                          }}
                                        >
                                          <h5 className="entityTitle">
                                            {' '}
                                            Related Entity Name : {related_entity.name}{' '}
                                            <Button
                                              className="del-entityScreen"
                                              id={`deleteapp-${related_entity.entity_id}`}
                                              onClick={e => {
                                                removeRelatedEntity(related_entity.entity_id, entity.entity_id, related_entity.screen_id);
                                              }}
                                            >
                                              <FontAwesomeIcon icon={faXmark} />{' '}
                                            </Button>
                                          </h5>
                                          <Row>
                                            <Col>
                                              <Label>Select Fields : </Label>
                                              {related_entity.entity_fields &&
                                                related_entity.entity_fields.map(field => {
                                                  return (
                                                    <>
                                                      <Input
                                                        type="checkbox"
                                                        style={{ marginLeft: '5px', marginRight: '5px' }}
                                                        checked={field.checkedField}
                                                        onChange={e =>
                                                          setSelectRelatedEntityField({
                                                            entity_id: entity.entity_id,
                                                            related_entity: related_entity.entity_id,
                                                            checked: e.target.checked,
                                                            field: field,
                                                          })
                                                        }
                                                      />
                                                      <Label check style={{ marginRight: '10px' }}>
                                                        {field.name}
                                                      </Label>
                                                    </>
                                                  );
                                                })}
                                            </Col>
                                          </Row>
                                        </Container>
                                      ) : (
                                        ''
                                      )}
                                    </>
                                  );
                                })}
                              <Col>
                                {entity.related_entities && entity.related_entities.length > 0 && (
                                  <ValidatedField
                                    className="col-md-6"
                                    name=""
                                    type="select"
                                    value={entity?.selectedRelationShip}
                                    label="Add Related Entity"
                                    onChange={e =>
                                      setSelectedRelatedEntityId({ entity_id: entity.entity_id, related_entity_id: e.target.value })
                                    }
                                  >
                                    <option value="default">Select related Entity</option>
                                    {entity.related_entities.map(realtedEntity => {
                                      return <option value={realtedEntity.entity_id}>{realtedEntity.name}</option>;
                                    })}
                                  </ValidatedField>
                                )}
                                {entity.related_entities == undefined && (
                                  <Spinner size="sm m-1" color="primary">
                                    Loading...
                                  </Spinner>
                                )}
                              </Col>
                            </Row>
                          </Container>
                          // </Col>
                          // </Row>
                        );
                      })
                    ) : !isNew && template != 'tabs' ? (
                      screenComponent.length > 0 ? (
                        <Spinner color="primary" ml={'2'}>
                          Loading...
                        </Spinner>
                      ) : (
                        ''
                      )
                    ) : (
                      ''
                    )}

                    {template != 'tabs' ? (
                      <Container>
                        <div id="entity-relationship-appEntityFrom" className="col-md-12 mb-6">
                          Add Entity
                          <DynamicSelect
                            changeFxn={handleAppEntityFromChange}
                            callbackFXN={getAppEntities}
                            paginateState={paginationState}
                            enitityData={entitiesCopy}
                            callbackFxnProps={{
                              page: paginationState.activePage - 1,
                              size: 25,
                              sort: `${paginationState.sort},${paginationState.order}`,
                              query: !!appId ? `appId.equals=${appId}` : '',
                            }}
                            setPageState={setPaginationState}
                            entity={'appEntity'}
                            name="appentity"
                            label="Add Entity"
                            isNew={isNew}
                          />
                        </div>
                        {/* {!appEntitiesLoading ? (
                  <ValidatedField
                    className="col-md-12"
                    name="appentity"
                    type="select"
                    label="Add Entity"
                    onChange={e => setSelectedEntityId(e.target.value)}
                  >
                    <option value="default">Select one</option>
                    {entitiesCopy.map(entity => {
                      return (
                        <option value={entity.id} key={entity.id}>
                          {entity.name}
                        </option>
                      );
                    })}
                  </ValidatedField>
                ) : isNew ? (
                  <Spinner color="primary">Loading...</Spinner>
                ) : (
                    ''
                )} */}
                      </Container>
                    ) : (
                      <Container>
                        <Col md="6">
                          <Label>App Screens : </Label>
                          {tempAppScreenList && tempAppScreenList.length > 0 ? (
                            tempAppScreenList.map(screen => {
                              return (
                                <>
                                  <Input
                                    type="checkbox"
                                    style={{ marginLeft: '5px', marginRight: '5px' }}
                                    checked={screen.checkScreen}
                                    onChange={e => {
                                      setTempAppScreenId(screen.id);
                                    }}
                                  />
                                  <Label check style={{ marginRight: '10px' }}>
                                    {screen.title}
                                  </Label>
                                </>
                              );
                            })
                          ) : (
                            <Spinner size="sm" color="primary">
                              Loading...
                            </Spinner>
                          )}
                        </Col>
                      </Container>
                    )}
                  </Col>
                  {formBuilder.length > 0 ? (
                    <Col className="col-md-6 mb-3" style={{ margin: '20px 0px' }}>
                      <GrapeEditor selectionBuilder={selectionBuilder} />
                    </Col>
                  ) : (
                    ''
                  )}
                </Row>
              </Col>
              <Col md={'12'}>
                <Button
                  tag={Link}
                  id="cancel-save"
                  data-cy="entityCreateCancelButton"
                  to={`/product/${productId}/app/${appId}`}
                  replace
                  color="back"
                >
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="save" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </Col>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default AppScreenUpdate;
