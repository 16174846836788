import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText, Label } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IProduct } from 'app/shared/model/product.model';
import { getEntities as getProducts } from 'app/entities/product/product.reducer';
import { IApp } from 'app/shared/model/app.model';
import { ApplicationType } from 'app/shared/model/enumerations/application-type.model';
import { AppStatus } from 'app/shared/model/enumerations/app-status.model';
import { getEntity, updateEntity, createEntity, reset } from './app.reducer';

export const AppUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { appId } = useParams<'appId'>();
  const id = appId;
  const { productId } = useParams<'productId'>();
  console.log("productId", productId)
  const isNew = id === undefined;

  const products = useAppSelector(state => state.product.entities);
  const appEntity = useAppSelector(state => state.app.entity);
  const loading = useAppSelector(state => state.app.loading);
  const updating = useAppSelector(state => state.app.updating);
  const updateSuccess = useAppSelector(state => state.app.updateSuccess);
  const applicationTypeValues = Object.keys(ApplicationType);
  const chatGptSearchData = useAppSelector(state => state.chatGptSearch);
  let appStatus = Object.keys(AppStatus);
  // appStatus.splice(appStatus.indexOf("DeletedD"), 1);


  const handleClose = () => {
    navigate(`/product/${productId}` + location.search);
  };
  // localStorage.removeItem('selectedTab');


  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getProducts({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    console.log("values",  values);

    const entity = {
      ...appEntity,
      ...values,
      product: {id: productId}
      // ,products.find(it => it.id === values.product || it.id === productId),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      console.log(entity);
      dispatch(updateEntity(entity));

    }
  };

  // const defaultValues = () =>
  //   isNew
  //     ? {}
  //     : {
  //       applicationType: 'MONOLITH',
  //       ...appEntity,
  //       product: appEntity?.product?.id,
  //     };

  const defaultValues = () =>{
    if(isNew){
      const emptyObj = {}; // Empty object
      if(chatGptSearchData && chatGptSearchData.entity && chatGptSearchData.entity.identifier){
        emptyObj['name'] =chatGptSearchData.entity.identifier;
      }
      return emptyObj;
    }else{
      const newEntityObj = { ...appEntity };
      if(chatGptSearchData && chatGptSearchData.entity && chatGptSearchData.entity.newValue){
        newEntityObj.name = chatGptSearchData.entity.newValue;
      }
      console.log(newEntityObj)
      return {
              applicationType: 'Monolith',
               ...newEntityObj,
              product: appEntity?.product?.id,
           };
    }
  };

  const handleBack = () => {
    navigate(`/product/${productId}`)
  };

  return (
    <div className="inner-content-wrap">
      <Row className="justify-content-center">
        <Col md="12">
          <h2 id="conductorMonolithApp.app.home.createOrEditLabel" data-cy="AppCreateUpdateHeading">
            {/* <Translate contentKey="conductorMonolithApp.app.home.createOrEditLabel">Create or edit a App</Translate> */}
            <Label>{isNew ? `Add` : ` Manage`} App</Label>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="12">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity} className="row">
              {!isNew ? (
                // <ValidatedField
                // className="col-md-6"
                //   name="id"
                //   required
                //   readOnly
                //   id="app-id"
                //   label={translate('global.field.id')}
                //   validate={{ required: true }}
                // />
                ""
              )
                : null}
              <ValidatedField
                className="col-md-6"
                label={translate('conductorMonolithApp.app.name')}
                id="app-name"
                name="name"
                data-cy="name"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') }
                }}
              />
              <ValidatedField
                className="col-md-6"
                label={translate('conductorMonolithApp.app.baseName')}
                id="app-baseName"
                name="baseName"
                data-cy="baseName"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                className="col-md-6"
                label={translate('conductorMonolithApp.app.applicationType')}
                id="app-applicationType"
                name="applicationType"
                data-cy="applicationType"
                type="select"
              >
                {applicationTypeValues.map(applicationType => (
                  <option value={ApplicationType[applicationType]} key={ApplicationType[applicationType]}>
                    {translate('conductorMonolithApp.ApplicationType.' + ApplicationType[applicationType])}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                className="col-md-6"
                label={translate('conductorMonolithApp.app.gitRepoPath')}
                id="app-gitRepoPath"
                name="gitRepoPath"
                data-cy="gitRepoPath"
                type="text"
              />
              {isNew ? (<ValidatedField
                    className="col-md-6"
                    label={translate('conductorMonolithApp.app.status')}
                    id="app-status"
                    name="status"
                    data-cy="status"
                    type="select"
                  // value={authenticationType}
                  // onChange={handleAuthTypeChange}
                  >
                    {appStatus.map(status => (
                      <option value={AppStatus[status]} key={AppStatus[status]}>
                        {AppStatus[status]}
                        {/* {translate('conductorMonolithApp.AppStatus.' + status)} */}
                      </option>
                    ))}
                  </ValidatedField>
                  ) : (
                    <ValidatedField
                      className="col-md-6"
                      label={translate('conductorMonolithApp.app.status')}
                      id="app-status"
                      name="status"
                      data-cy="status"
                      type="select"
                    >
                      {appStatus.map(status => (
                        <option value={AppStatus[status]} key={AppStatus[status]}>
                          {AppStatus[status]}
                          {/* {translate('conductorMonolithApp.AppStatus.' + status)} */}
                        </option>
                      ))}
                    </ValidatedField>
                  )}
              <ValidatedField
                className="col-md-6"
                label={translate('conductorMonolithApp.app.packageName')}
                id="app-packageName"
                name="packageName"
                data-cy="packageName"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') }
                }}
              />
              <Col md={12}>
                <Button className='m-1'
                  // tag={Link}
                  id="cancel-save" data-cy="entityCreateCancelButton"
                  // to="/app"
                  onClick={handleBack}
                  replace color="back">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span>
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button className='m-1' color="save" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </Col>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default AppUpdate;
