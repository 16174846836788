import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import CoreAccessConfig from './core-access-config';
import CoreAccessConfigDetail from './core-access-config-detail';
import CoreAccessConfigUpdate from './core-access-config-update';
import CoreAccessConfigDeleteDialog from './core-access-config-delete-dialog';

const CoreAccessConfigRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<CoreAccessConfig />} />
    <Route path="new" element={<CoreAccessConfigUpdate />} />
    <Route path=":id">
      <Route index element={<CoreAccessConfigDetail />} />
      <Route path="edit" element={<CoreAccessConfigUpdate />} />
      <Route path="delete" element={<CoreAccessConfigDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default CoreAccessConfigRoutes;
