import React, {useEffect} from 'react';
import { Translate, translate, ValidatedField } from 'react-jhipster';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert, Row, Col, Form } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ConductorLogo } from 'app/shared/assets/svg-icon/logo';

export interface ILoginModalProps {
  showModal: boolean;
  loginError: boolean;
  handleLogin: (username: string, password: string, rememberMe: boolean) => void;
  handleClose: () => void;
}

const LoginModal = (props: ILoginModalProps) => {
  const login = ({ username, password, rememberMe }) => {
    props.handleLogin(username, password, rememberMe);
  };

  const {
    handleSubmit,
    register,
    formState: { errors, touchedFields },
  } = useForm({ mode: 'onTouched' });

  const { loginError, handleClose } = props;

  const handleLoginSubmit = e => {
    handleSubmit(login)(e);
  };
  if(window){
    if(document.getElementById('app-header')) document.getElementById('app-header').hidden = true
    if(document.getElementsByClassName('app-container') && document.getElementsByClassName('app-container').length > 0) document.getElementsByClassName('app-container')[0]['style']['paddingTop'] = 0;
  }

  useEffect(() => {
  // 👇️ run a function when the component unmounts 👇️
  return () => {
    if(window){
      if(document.getElementById('app-header')) document.getElementById('app-header').hidden = false;
      // if(document.getElementsByClassName('app-container') && document.getElementsByClassName('app-container').length > 0) document.getElementsByClassName('app-container')[0]['style']['paddingTop'] = '60px';
      }
  };
}, []);

  return (
    <>
      <div className="row align-items-stretch login-page m-0">
        <div className="col-md-4 p-0 d-flex flex-wrap">
            <img src="../content/images/login-robo.gif" className="login-robo" />
          {/* <div className="form-left-bar">
            <h2>Transformative collaboration for larger teams</h2>
            <p>Lorem ipsum dolor sit amet consectetur. Eget orci molestie ultricies sed pharetra porttitor facilisi arcu.</p>
          </div> */}
        </div>
        <div className="col-md-8 p-0 bg-white d-flex align-items-center">
          <div className="login-form">
            <div className="row">
              <div className="col-md-12">
                <a href="/" style={{display:'inline-block',marginBottom: '15px'}}> <ConductorLogo /></a>
                {/* <img className="logo-icon" src="content/images/logo.png" alt="logo" /> */}
                {/* <h2 jhiTranslate="login.title" data-cy="loginTitle" className="title">Sign in</h2> */}
                <h4 className="subtitle">Welcome back! Please enter your details</h4>
              </div>
              <div className="col-md-12">
                <div className="alert alert-danger d-none">
                  <strong>Failed to sign in!</strong> Please check your credentials and try again.
                </div>
              </div>
              <Form onSubmit={handleLoginSubmit}>
                  {/* <Translate contentKey="login.title">Login</Translate> */}
                <ModalBody>
                  <Row>
                    <Col md="12">
                      {loginError ? (
                        <Alert color="danger" data-cy="loginError">
                          <Translate contentKey="login.messages.error.authentication">
                            <strong>Failed to sign in!</strong> Please check your credentials and try again.
                          </Translate>
                        </Alert>
                      ) : null}
                    </Col>
                    <Col md="12">
                      <ValidatedField
                        name="username"
                        // label={translate('global.form.username.label')}
                        placeholder={translate('global.form.username.label')}
                        required
                        autoFocus
                        data-cy="username"
                        validate={{ required: 'Username cannot be empty!' }}
                        register={register}
                        error={errors.username}
                        isTouched={touchedFields.username}
                      />
                      <ValidatedField
                        name="password"
                        type="password"
                        // label={translate('login.form.password')}
                        placeholder={translate('login.form.password')}
                        required
                        data-cy="password"
                        validate={{ required: 'Password cannot be empty!' }}
                        register={register}
                        error={errors.password}
                        isTouched={touchedFields.password}
                      />
                      <Row>
                        <Col>
                          <ValidatedField
                            name="rememberMe"
                            type="checkbox"
                            check
                            label={translate('login.form.rememberme')}
                            value={true}
                            register={register}
                          />
                        </Col>
                        <Col>
                          <Link className="forgotpassword" to="/account/reset/request" data-cy="forgetYourPasswordSelector">
                            <Translate contentKey="login.password.forgot">Forgot Password</Translate>
                          </Link>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <div className="mt-1">&nbsp;</div>
                  {/* <Alert color="warning"> */}
                  {/* <Link to="/account/reset/request" data-cy="forgetYourPasswordSelector">
                      <Translate contentKey="login.password.forgot">Did you forget your password?</Translate>
                    </Link> */}
                  {/* </Alert> */}
                  {/* <Alert color="warning">
                    <span>
                      <Translate contentKey="global.messages.info.register.noaccount">You don&apos;t have an account yet?</Translate>
                    </span>{' '}
                    <Link to="/account/register">
                      <Translate contentKey="global.messages.info.register.link">Register a new account</Translate>
                    </Link>
                  </Alert> */}
                </ModalBody>
                <Button color="primary" type="submit" data-cy="submit" className="btn-lg login-btn">
                    <Translate contentKey="login.form.button">Login</Translate>
                </Button>
              </Form>

              <div className="col-md-12 new-account-btn">
                {/* <span jhiTranslate="global.messages.info.register.noaccount">You don't have an account yet?</span> */}
                <br />
                <a href="/account/register" className="alert-link">
                  <Translate contentKey="global.messages.info.register.link">Register a new account</Translate>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  {
    /**
return (
    <Modal isOpen={props.showModal} toggle={handleClose} backdrop="static" id="login-page" autoFocus={false}>
      <Form onSubmit={handleLoginSubmit}>
        <ModalHeader id="login-title" data-cy="loginTitle" toggle={handleClose}>
          <Translate contentKey="login.title">Sign in</Translate>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md="12">
              {loginError ? (
                <Alert color="danger" data-cy="loginError">
                  <Translate contentKey="login.messages.error.authentication">
                    <strong>Failed to sign in!</strong> Please check your credentials and try again.
                  </Translate>
                </Alert>
              ) : null}
            </Col>
            <Col md="12">
              <ValidatedField
                name="username"
                label={translate('global.form.username.label')}
                placeholder={translate('global.form.username.placeholder')}
                required
                autoFocus
                data-cy="username"
                validate={{ required: 'Username cannot be empty!' }}
                register={register}
                error={errors.username}
                isTouched={touchedFields.username}
              />
              <ValidatedField
                name="password"
                type="password"
                label={translate('login.form.password')}
                placeholder={translate('login.form.password.placeholder')}
                required
                data-cy="password"
                validate={{ required: 'Password cannot be empty!' }}
                register={register}
                error={errors.password}
                isTouched={touchedFields.password}
              />
              <ValidatedField
                name="rememberMe"
                type="checkbox"
                check
                label={translate('login.form.rememberme')}
                value={true}
                register={register}
              />
            </Col>
          </Row>
          <div className="mt-1">&nbsp;</div>
          <Alert color="warning">
            <Link to="/account/reset/request" data-cy="forgetYourPasswordSelector">
              <Translate contentKey="login.password.forgot">Did you forget your password?</Translate>
            </Link>
          </Alert>
          <Alert color="warning">
            <span>
              <Translate contentKey="global.messages.info.register.noaccount">You don&apos;t have an account yet?</Translate>
            </span>{' '}
            <Link to="/account/register">
              <Translate contentKey="global.messages.info.register.link">Register a new account</Translate>
            </Link>
          </Alert>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleClose} tabIndex={1}>
            <Translate contentKey="entity.action.cancel">Cancel</Translate>
          </Button>{' '}
          <Button color="primary" type="submit" data-cy="submit">
            <Translate contentKey="login.form.button">Sign in</Translate>
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
*/
  }
};

export default LoginModal;
