import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Type from './type';
import TypeDetail from './type-detail';
import TypeUpdate from './type-update';
import TypeDeleteDialog from './type-delete-dialog';

const TypeRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Type />} />
    <Route path="new" element={<TypeUpdate />} />
    <Route path=":id">
      <Route index element={<TypeDetail />} />
      <Route path="edit" element={<TypeUpdate />} />
      <Route path="delete" element={<TypeDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default TypeRoutes;
