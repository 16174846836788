import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import CoreScreen from './core-screen';
import CoreScreenDetail from './core-screen-detail';
import CoreScreenUpdate from './core-screen-update';
import CoreScreenDeleteDialog from './core-screen-delete-dialog';

const CoreScreenRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<CoreScreen />} />
    <Route path="new" element={<CoreScreenUpdate />} />
    <Route path=":id">
      <Route index element={<CoreScreenDetail />} />
      <Route path="edit" element={<CoreScreenUpdate />} />
      <Route path="delete" element={<CoreScreenDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default CoreScreenRoutes;
