// import './home.scss';

import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { Row, Col, Alert } from 'reactstrap';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';
// import { addProduct } from '../../shared/reducers/authentication';
import { addProduct } from 'app/shared/reducers/authentication';
// import { getEntities } from '../../entities/core-functionality/core-functionality.reducer';
import { getEntities } from 'app/entities/core-functionality/core-functionality.reducer';

export const Demo = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const coreFunctionalityList = useAppSelector(state => state.coreFunctionality.entities);
  useEffect(() => {
    loadCoreFunctionalities();
  }, []);

  async function loadCoreFunctionalities() {
    console.log(await dispatch(getEntities({})));
  }

  console.log(coreFunctionalityList);

  let functionalityList =
    coreFunctionalityList.length > 0 &&
    coreFunctionalityList.map((item, i) => {
      return (
        <option key={i} value={item.id}>
          {item.name}
        </option>
      );
    }, this);

  const account = useAppSelector(state => state.authentication.account);
  const [productName, setProductName] = useState('');
  const [functionality, setFunctionality] = useState(1);
  const handleSubmit = async e => {
    e.preventDefault();
    await dispatch(addProduct(productName, functionality));
    console.log('TTTTT', productName, functionality);

    navigate('/demo-list');
  };

  const onChangeProductName = event => setProductName(event.target.value);

  const onChangeFunctionality = event => setFunctionality(event.target.value);

  return (
    <div className="main-dashboard">
      <div className="dashboard-wrap container">
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label for="productName">Product Name</Label>
            <Input
              type="text"
              name="productName"
              id="productName"
              value={productName}
              placeholder="Enter Product Name"
              onChange={onChangeProductName}
            />
          </FormGroup>
          <FormGroup>
            <Label for="functionality">Functionality</Label>
            <Input type="select" name="functionality" value={functionality} id="functionality" onChange={onChangeFunctionality}>
              {functionalityList}
            </Input>
          </FormGroup>
          <Button>Submit</Button>
        </Form>
      </div>
    </div>
  );
};

export default Demo;
