import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, Form, FormGroup, Input, Label } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity } from 'app/entities/app-entity/app-entity.reducer';
import AppEntityField from '../editAppEntityField/AppEntityField';
import EnumMember from 'app/entities/enum-member/enum-member';
import { getEntities as entityFielsMembers } from 'app/entities/enum-member/enum-member.reducer';
import {EntityType} from 'app/shared/model/enumerations/entity-type.model';

// import { getEntity } from './app-entity.reducer';
// import AppEntityField from '../app-entity-field/app-entity-field';

export const AppEntityView = () => {
  const dispatch = useAppDispatch();
  const { productId } = useParams<'productId'>();
  const { appId } = useParams<'appId'>();
  const { appEntityId } = useParams<'appEntityId'>();

  const navigate = useNavigate()
  useEffect(() => {
      if(!Number(appEntityId)) navigate(-1)
    dispatch(getEntity(appEntityId));
  }, []);


  const appEntityDetails = useAppSelector(state => state.appEntity.entity);

  return (
    <div className='inner-content-wrap'>
    <Form>
    <Row>
      <Col md={12}>
        <h2  className="row  page-title" data-cy="appEntityDetailsHeading">
          <Translate contentKey="conductorMonolithApp.appEntity.detail.title">App Entity</Translate>
        </h2>
        <div className="jh-entity-details">
          <Col md ={6}>
            <FormGroup>
            <span id="name">
              <Label contentKey="conductorMonolithApp.appEntity.name">Name</Label>
            </span>
            <Input value = {appEntityDetails.name}/>
            </FormGroup>
          </Col>

          <Col md ={6}>
            <FormGroup>
            <span id="type">
              <Label contentKey="conductorMonolithApp.appEntity.type">Type</Label>
              </span>
              <Input value = {appEntityDetails.type}/>
            </FormGroup>
          </Col>
          { appEntityDetails && appEntityDetails.length > 0 && <Col md ={6}>
            <FormGroup>
            <Label contentKey="conductorMonolithApp.appEntity.functionality">Functionality</Label>
            <Input value = {appEntityDetails.functionality ? appEntityDetails.functionality.name : ''}/>
            </FormGroup>
          </Col>}
        </div>
        <Col md = {4}>
        <Button
         tag={Link}
        to={`/product/${productId}/app/${appId}`}
        replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        {/* <Button tag={Link} to={`/app-entity/${appEntityEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button> */}
        </Col>
      </Col>
    </Row>
    </Form>
    {appEntityDetails.type === EntityType.Enum ? <EnumMember /> : <AppEntityField entityName={appEntityDetails.name} entityDescription={appEntityDetails.description} />}
    </div>
  );
};

export default AppEntityView;
