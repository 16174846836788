import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, UncontrolledTooltip, Row, Col, Label, FormGroup, Input } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './app-screen.reducer';
import {getEntities as getScreenComponents } from '../screen-component/screen-component.reducer'

export const AppScreenDetail = () => {
  const dispatch = useAppDispatch();

  const { productId } = useParams<'productId'>();
  const { appId } = useParams<'appId'>();
  const { appScreenId } = useParams<'appScreenId'>();


  useEffect(() => {
    dispatch(getEntity(appScreenId));
    dispatch(getScreenComponents({query: 'appScreenId.equals=' + appScreenId}))
  }, []);


  const appScreenEntity = useAppSelector(state => state.appScreen.entity);
  const appScreenComponent = useAppSelector(state => state.screenComponent.entities);
  console.log("appScreenEntities", appScreenComponent)
  return (
    <div className='inner-content-wrap'>
    <Row>
      <Col md="12">
        <h2 className="row  page-title" data-cy="appScreenDetailsHeading">
          <Translate contentKey="conductorMonolithApp.appScreen.detail.title">AppScreen</Translate>
        </h2>
        <dl className="jh-entity-details">
          {/* <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{appScreenEntity.id}</dd> */}
          <Col md ={6}>
          <FormGroup>
            <span id="title">
              <Label contentKey="conductorMonolithApp.appScreen.title">Title</Label>
            </span>
            <Input value = {appScreenEntity.title}/>
            </FormGroup>
          </Col>
          <Col md ={6}>
          <FormGroup>
            <span id="path">
              <Label contentKey="conductorMonolithApp.appScreen.path">Path</Label>
            </span>
            <Input value = {appScreenEntity.path}/>
            </FormGroup>
          </Col>
          {/* <Col md ={6}>
          <FormGroup>
            <span id="metadata">
              <Translate contentKey="conductorMonolithApp.appScreen.metadata">Metadata</Translate>
            </span>
            <UncontrolledTooltip target="metadata">
              <Label contentKey="conductorMonolithApp.appScreen.help.metadata" />
            </UncontrolledTooltip>
            <Input value = {appScreenEntity.metadata}/>
            </FormGroup>
          </Col> */}
          <Col md ={6}>
          <FormGroup>
            <span id="template">
              <Label contentKey="conductorMonolithApp.appScreen.template">Template</Label>
            </span>
            <Input value = {appScreenEntity.template}/>
            </FormGroup>
          </Col>
          <Col md ={6}>
          <FormGroup>
            <span id="accessRoles">
              <Label contentKey="conductorMonolithApp.appScreen.accessRoles">Access Roles</Label>
            </span>
            <UncontrolledTooltip target="accessRoles">
              <Translate contentKey="conductorMonolithApp.appScreen.help.accessRoles" />
            </UncontrolledTooltip>
            <Input value = {appScreenEntity.accessRoles}/>
            </FormGroup>
          </Col>
          {/* <Col md ={6}>
          <FormGroup>
            <Label contentKey="conductorMonolithApp.appScreen.app">App</Label>
            <Input value = {appScreenEntity.app ? appScreenEntity.app.name : ''}/>
            </FormGroup>
          </Col> */}
          {/* <Col md ={6}>
          <FormGroup>
            <Translate contentKey="conductorMonolithApp.appScreen.functionality">Functionality</Translate>
            <Input value = {appScreenEntity.functionality ? appScreenEntity.functionality.name : ''}/>
            </FormGroup>
          </Col> */}
        </dl>
        <Button tag={Link} to={`/product/${productId}/app/${appId}`} replace color="back" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        {/* <Button tag={Link} to={`/app-screen/${appScreenEntity.id}/edit`} replace color="save">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button> */}
      </Col>
    </Row>
    </div>
  );
};

export default AppScreenDetail;
