import AppFormView from './AppFormView';

import ProductFormView from './ProductFormView';

import CorefunctionalityListView from './CorefunctionalityListView';

import React from 'react';
import { Route } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
interface Props {}

export const CreateAppWizard = (props: Props) => {
  return (
    <Row>
      <Col md="12" className="mx-5">
        <h1> CreateAppWizard</h1>
      </Col>

      <Col md="12">
        <AppFormView />
      </Col>

      <Col md="12">
        <ProductFormView />
      </Col>

      <Col md="12">
        <CorefunctionalityListView />
      </Col>

      <span id="custom-components" />
    </Row>
  );
};

export default CreateAppWizard;
