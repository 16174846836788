import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';


const initialState: EntityState<any> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: {},
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};


// Actions
export const suggestEntityFields = createAsyncThunk(
  '/ai/suggest/entityfields',
  async (userPrompt: any) => {
    const requestUrl = `api/ai/suggest/entityfields?entityName=${userPrompt.entityName}&entityDescription=${userPrompt.entityDescription}`;
    const result = axios.post<any>(requestUrl).then(function (response) {
      return response;
    });
    return result;
  },
  { serializeError: serializeAxiosError }
);


export const suggestFeatureEntities = createAsyncThunk(
  '/ai/suggest/featureentities',
  async (userPrompt: any) => {
    const requestUrl = `api/ai/suggest/featureentities?productName=${userPrompt.productName}&productDescription=${userPrompt.productDescription}&featureName=${userPrompt.featureName}&featureDescription=${userPrompt.featureDescription}`; 
    const result = axios.post<any>(requestUrl).then(function (response) {
      return response;
    });
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const suggestProductFeature = createAsyncThunk(
  '/ai/suggest/productfeatures',
  async (userPrompt: any) => {
    const requestUrl = `api/ai/suggest/productfeatures?productName=${userPrompt.productName}&productDescription=${userPrompt.productDescription}`;
    const result = axios.post<any>(requestUrl).then(function (response) {
      return response;
    });
    return result;
  },
  { serializeError: serializeAxiosError }
);


// slice
export const AiSuggestor = createEntitySlice({
  name: 'aiSuggestor',
  initialState,
  extraReducers(builder) {
    builder
     .addCase(suggestEntityFields.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload.data;
      })
      .addCase(suggestFeatureEntities.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload.data;
      })
      .addCase(suggestProductFeature.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload.data;
      })
      .addMatcher(isFulfilled(suggestEntityFields, suggestFeatureEntities, suggestProductFeature), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entities = action.payload.data;
      })
      .addMatcher(isPending(suggestEntityFields, suggestFeatureEntities, suggestProductFeature), state => {
        state.loading = true;
      })
  },
});

export const { reset } = AiSuggestor.actions;

// Reducer
export default AiSuggestor.reducer; 
