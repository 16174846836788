import dayjs from 'dayjs';
import { IProductUserRole } from 'app/shared/model/product-user-role.model';
import { IAppEntity } from 'app/shared/model/app-entity.model';

export interface IProductUserRoleAccess {
  id?: number;
  canRead?: boolean | null;
  canCreate?: boolean | null;
  canUpdate?: boolean | null;
  canDelete?: boolean | null;
  whenCreatedByUser?: boolean | null;
  whenCreatedByGroup?: boolean | null;
  lastSyncTimestamp?: string | null;
  productUserRole?: IProductUserRole | null;
  appEntity?: IAppEntity | null;
}

export const defaultValue: Readonly<IProductUserRoleAccess> = {
  canRead: false,
  canCreate: false,
  canUpdate: false,
  canDelete: false,
  whenCreatedByUser: false,
  whenCreatedByGroup: false,
};
