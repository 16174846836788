import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col, Input, FormGroup } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './dependency.reducer';

export const DependencyDetail = () => {
  const dispatch = useAppDispatch();

  const { productId } = useParams<'productId'>();
  const { appId } = useParams<'appId'>();
  const { dependencyId } = useParams<'dependencyId'>();

  useEffect(() => {
    dispatch(getEntity(dependencyId));
  }, []);

  const dependencyEntity = useAppSelector(state => state.dependency.entity);
  return (
    <div className="inner-content-wrap">
      <Row>
        <Col md="12">
          <h2  className="row  page-title"  data-cy="dependencyDetailsHeading">
            <Translate contentKey="conductorMonolithApp.dependency.detail.title">Dependency</Translate>
          </h2>
          <div className="jh-entity-details">
            {/* <Col md={6}>
              <FormGroup>
                <span id="id">
                  <Translate contentKey="global.field.id">ID</Translate>
                </span>
                <Input value={dependencyEntity.id} disabled />
              </FormGroup>
            </Col> */}

            <Col md={6}>
              <FormGroup>
                <span id="packageName">
                  <Translate contentKey="conductorMonolithApp.dependency.packageName">Package Name</Translate>
                </span>
                <Input value={dependencyEntity.packageName} disabled />
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <span id="declaration">
                  <Translate contentKey="conductorMonolithApp.dependency.declarations">Declaration</Translate>
                </span>
                <Input type='textarea' value={dependencyEntity.declarations} disabled  style={{"height":"120px"}} className='multiline'/>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <span id="id">
                  <Translate contentKey="conductorMonolithApp.dependency.minVersion">minVersion</Translate>
                </span>
                <Input value={dependencyEntity.minVersion} disabled />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <span id="id">
                  <Translate contentKey="conductorMonolithApp.dependency.maxVersion">maxVersion</Translate>
                </span>
                <Input value={dependencyEntity.maxVersion} disabled />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <span id="id">
                  <Translate contentKey="conductorMonolithApp.dependency.type">type</Translate>
                </span>
                <Input value={dependencyEntity.type} disabled />
              </FormGroup>
            </Col>
          </div>
          <Col md={4}>
            <Button tag={Link} to={`/product/${productId}/app/${appId}`} replace color="info" data-cy="entityDetailsBackButton">
              <FontAwesomeIcon icon="arrow-left" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.back">Back</Translate>
              </span>
            </Button>
            &nbsp;
            <Button tag={Link} to={`/product/${productId}/app/${appId}/dependency/${dependencyEntity.id}/edit`} replace color="primary">
              <FontAwesomeIcon icon="pencil-alt" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.edit">Edit</Translate>
              </span>
            </Button>
          </Col>
        </Col>
      </Row>
    </div>
  );
};

export default DependencyDetail;
