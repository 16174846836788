import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col, Form, FormGroup, Input, Label } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './app-entity.reducer';
// import AppEntityField from '../app-entity-field/app-entity-field';

export const AppEntityDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const appEntityEntity = useAppSelector(state => state.appEntity.entity);
  return (
    <div className='inner-content-wrap'>
    <Form>
    <Row>
      <Col md={12}>
        <h2 data-cy="appEntityDetailsHeading">
          <Translate contentKey="conductorMonolithApp.appEntity.detail.title">AppEntity</Translate>
        </h2>
        <div className="jh-entity-details">
          {/* <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt> */}
          {/* <dd>{appEntityEntity.id}</dd> */}
          <Col md ={6}>
            <FormGroup>
            <span id="name">
              <Label contentKey="conductorMonolithApp.appEntity.name">Name</Label>
            </span>
            <Input value = {appEntityEntity.name}/>
            </FormGroup>
          </Col>
          {/* <Col md ={6}>
            <FormGroup>
            <span id="lastSyncTimestamp">
              <Translate contentKey="conductorMonolithApp.appEntity.lastSyncTimestamp">Last Sync Timestamp</Translate>
            </span>
            <Input value = {appEntityEntity.name}/>
            </FormGroup>
          </Col> */}
          {/* <dd>
            {appEntityEntity.lastSyncTimestamp ? (
              <TextFormat value={appEntityEntity.lastSyncTimestamp} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd> */}
          <Col md ={6}>
            <FormGroup>
            <span id="special">
              <Label contentKey="conductorMonolithApp.appEntity.special">Special</Label>
            </span>
            <Input value = {appEntityEntity.special ? 'true' : 'false'}/>
            </FormGroup>
          </Col>
          <Col md ={6}>
            <FormGroup>
            <Label contentKey="conductorMonolithApp.appEntity.functionality">Functionality</Label>
            <Input value = {appEntityEntity.functionality ? appEntityEntity.functionality.name : ''}/>
            </FormGroup>
          </Col>
          <Col md ={6}>
            <FormGroup>
            <Label contentKey="conductorMonolithApp.appEntity.app">App</Label>
            <Input value = {appEntityEntity.app ? appEntityEntity.app.name : ''}/>
            </FormGroup>
          </Col>
          <Col md ={6}>
            <FormGroup>
            <Label contentKey="conductorMonolithApp.appEntity.coreEntity">Core Entity</Label>
            <Input value = {appEntityEntity.coreEntity ? appEntityEntity.coreEntity.name : ''}/>
            </FormGroup>
          </Col>
        </div>
        <Col md = {4}>
        <Button tag={Link} to="/app-entity" replace color="back" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/app-entity/${appEntityEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
        </Col>
      </Col>
    </Row>
    </Form>

    </div>
  );
};

export default AppEntityDetail;
