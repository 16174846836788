import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { ToastContainer, toast } from 'react-toastify';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IProduct, defaultValue } from 'app/shared/model/product.model';
//import { IAddProduct, defaultValue } from 'app/shared/model/add-product.model';
const initialState: EntityState<IProduct> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};
interface deployProductParams{
  id:string|number
}
// Actions

export const deployProduct = createAsyncThunk('product/fetch_entity_list', async ({ id }:deployProductParams) => {
  const requestUrl = `api/extsetup/deployment/${id}`;
  return axios.get<IProduct[]>(requestUrl);
});

export const publishProduct = createAsyncThunk(
  'product/update_entity',
  async (id: number, thunkAPI) => {
    const result = axios.post<IProduct[]>(`api/extsetup/product/${id}`);
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);


// export const publishProduct = createAsyncThunk('product/fetch_entity_list', async ({ id }:deployProductParams) => {
//   const requestUrl = `api/extsetup/product/${id}`;
//   return axios.get<IProduct[]>(requestUrl);
// });


// slice

export const CustomReducerSlice = createEntitySlice({
  name: 'product',
  initialState,
  extraReducers(builder) {
    builder.addMatcher(isFulfilled(deployProduct), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
      })
      .addMatcher(isPending(deployProduct), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isRejected(publishProduct), (state, action) => {
        state.errorMessage = action.error.message;
        state.updateSuccess = false;
        state.loading = false;
      })
      .addMatcher(isFulfilled(publishProduct), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
      })
  },
});

export const { reset } = CustomReducerSlice.actions;

// Reducer
export default CustomReducerSlice.reducer;
