export enum JobScheduleType {
  CRON = 'CRON',

  DAY_OF_YEAR = 'DAY_OF_YEAR',

  DAY_OF_MONTH = 'DAY_OF_MONTH',

  DAY_OF_WEEK = 'DAY_OF_WEEK',

  TIME_OF_DAY = 'TIME_OF_DAY',
}
