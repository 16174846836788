import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import AppEntityField from './app-entity-field';
import AppEntityFieldDetail from './app-entity-field-detail';
import AppEntityFieldUpdate from './app-entity-field-update';
import AppEntityFieldDeleteDialog from './app-entity-field-delete-dialog';

const AppEntityFieldRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<AppEntityField />} />
    <Route path="app-entity-view/:entityID/new" element={<AppEntityFieldUpdate />} />
    <Route path=":id">
      <Route index element={<AppEntityFieldDetail />} />
      <Route path="edit" element={<AppEntityFieldUpdate />} />
      <Route path="delete" element={<AppEntityFieldDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default AppEntityFieldRoutes;
