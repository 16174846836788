export enum DeploymentType {
  None = 'None',

  Ssh = 'Ssh',

  Cloud = 'Cloud',

  Kubernetes = 'Kubernetes',

  Artifactory = 'Artifactory',
}
