import { faker } from '@faker-js/faker';
// import './layouts.css';

interface Layouts {
    listLayout: Function;
    detailsLayout: Function;
    formLayout: Function;
  }

  const layouts: Layouts = {
    listLayout: (obj, fields) => {
        // console.log("@@@@@", obj, fields);
        let temp_obj = {};
        let faker_data = faker.helpers.multiple(
            () => {
              fields.forEach(key => {
                temp_obj[key] = faker.internet.displayName();
              });
              // console.log("temp_obj", temp_obj);
              return temp_obj;
            },
            { count: 10 }
        );
        
        let temp_obj_details = {};
        let faker_data_details = faker.helpers.multiple(
          () => {
            fields.forEach(key => {
              temp_obj_details[key] = faker.lorem.words(20);
            });
            return temp_obj_details;
          },
          { count: 1 }
        );

        let template;
        if (obj && obj.layoutTemplate == 'table') {
          template = `<div class="my-component" id="custom-${obj.layout}-${obj.layoutTemplate}-${obj.name?.toLowerCase()}">
          <table>
              <tr>
              ${fields.map(field => `<th>${field}</th>`).join('')}
              </tr>
              ${faker_data
                .map(fake_obj => {
                  return `<tr>${Object.keys(fake_obj)
                    .map(fake_obj_key => `<td>${fake_obj[fake_obj_key]}</td>`)
                    .join('')}</tr>`;
                })
                .join('')}
          </table></div>`
        } else if (obj && obj.layoutTemplate == 'card') {
          template = `<div class="my-component" id="custom-${obj.layout}-${obj.layoutTemplate}-${obj.name?.toLowerCase()}" >
          
          ${fields.map(field => `<div class = 'card-${field}' style="border: 1px solid #ccc; border-radius: 8px; overflow: hidden; width: 300px; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);">
          <h2>${field}</h2>
          ${faker_data_details
            .map(fake_obj => {
              return `<p>${Object.keys(fake_obj)
                .map(fake_obj_key => `<p>${fake_obj[fake_obj_key]}</p>`)
                .join('')}</p>`;
            })
          }
          </div>
          `)}
          </div>`
        } else if (obj && obj.layoutTemplate == 'sidebar') {
          template = `<div class="my-component" id="custom-${obj.layout}-${obj.layoutTemplate}-${obj.name?.toLowerCase()}">
          <table>
              <tr>
              ${fields.map(field => `<th>${field}</th>`).join('')}
              </tr>
              ${faker_data
                .map(fake_obj => {
                  return `<tr>${Object.keys(fake_obj)
                    .map(fake_obj_key => `<td>${fake_obj[fake_obj_key]}</td>`)
                    .join('')}</tr>`;
                })
                .join('')}
          </table></div>`
        }
        // console.log("listLayout", template);
        return template;
    },
    detailsLayout: (obj, fields) => {
      // console.log("@@@@@details", obj, fields);
      let temp_obj_details = {};
      let faker_data_details = faker.helpers.multiple(
        () => {
          fields.forEach(key => {
            temp_obj_details[key] = faker.lorem.words(20);
          });
          return temp_obj_details;
        },
        { count: 1 }
      );
      const template = `<div class="my-component" id="custom-${obj.layout}-${obj.name?.toLowerCase()}">
          ${fields.map(field => `<h2>${field}</h2>
          ${faker_data_details
            .map(fake_obj => {
              return `<p>${Object.keys(fake_obj)
                .map(fake_obj_key => `<p>${fake_obj[fake_obj_key]}</p>`)
                .join('')}</p>`;
            })
            .join('')}
          `)}
        </div>`
      // console.log("listLayout", template);
      return template;
    },
    formLayout: (obj, fields) => {
      // console.log("@@@@@form", obj, fields);
      let temp_obj = {};
      let faker_data = faker.helpers.multiple(
        () => {
          fields.forEach(key => {
            temp_obj[key] = faker.internet.displayName();
          });
          // console.log("temp_obj", temp_obj);
          return temp_obj;
        },
        { count: 10 }
      );
      const template = `<div class="my-component" id="custom-${obj.layout}-${obj.name?.toLowerCase()}">
          ${fields.map(field => `<form>
          <label for=${field}>${field}</label>
          <input type="text" id=${field} name=${field} />
          `)}
          <button type="submit">Submit</button>
          <form>
        </div>`
      // console.log("listLayout", template);
      return template;
    }
  };
  
  export default layouts;