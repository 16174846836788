import React, { useState, useEffect } from 'react';
import { Translate, translate, ValidatedField, ValidatedForm, isEmail } from 'react-jhipster';
import { Row, Col, Alert, Button } from 'reactstrap';
import { toast } from 'react-toastify';

import PasswordStrengthBar from 'app/shared/layout/password/password-strength-bar';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { handleRegister, reset } from './register.reducer';
import { ConductorLogo } from 'app/shared/assets/svg-icon/logo';

export const RegisterPage = () => {
  if(window){
    if(document.getElementById('app-header')) document.getElementById('app-header').hidden = true
    if(document.getElementsByClassName('app-container') && document.getElementsByClassName('app-container').length > 0) document.getElementsByClassName('app-container')[0]['style']['paddingTop'] = 0;
  }

  const [password, setPassword] = useState('');
  const dispatch = useAppDispatch();

  const currentLocale = useAppSelector(state => state.locale.currentLocale);

  const handleValidSubmit = ({ username, email, firstPassword }) => {
    dispatch(handleRegister({ login: username, email, password: firstPassword, langKey: currentLocale }));
  };

  const updatePassword = event => setPassword(event.target.value);

  const successMessage = useAppSelector(state => state.register.successMessage);

  useEffect(() => {
    if (successMessage) {
      toast.success(translate(successMessage));
    }
  }, [successMessage]);

  useEffect(
    () => () => {
      if(window){
        if(document.getElementById('app-header')) document.getElementById('app-header').hidden = false;
        // if(document.getElementsByClassName('app-container') && document.getElementsByClassName('app-container').length > 0) document.getElementsByClassName('app-container')[0]['style']['paddingTop'] = '60px';
        }
      dispatch(reset());
    },
    []
  );

  return (
    <div>
      <div className="row align-items-stretch login-page m-0">
        <div className="col-md-4 p-0 d-flex flex-wrap">
            <img src="../content/images/login-robo.gif" className="login-robo" />
          {/* <div className="form-left-bar">
            <h2>Transformative collaboration for larger teams</h2>
            <p>Lorem ipsum dolor sit amet consectetur. Eget orci molestie ultricies sed pharetra porttitor facilisi arcu.</p>
          </div> */}
        </div>
        <div className="col-md-8 p-0 bg-white d-flex align-items-center">
          <div className="login-form">
        <Row>
          <Col md="12">
            <a href="/" style={{display:'inline-block',marginBottom: '15px'}}> <ConductorLogo /></a>
            <h4 id="register-title" data-cy="registerTitle" className="subtitle">
              <Translate contentKey="register.title">Registration</Translate>
            </h4>
          </Col>
          <Col md="12">
            <ValidatedForm id="register-form" onSubmit={handleValidSubmit}>
              <ValidatedField
                name="username"
                // label={translate('global.form.username.label')}
                placeholder={translate('global.form.username.label')}
                validate={{
                  required: { value: true, message: translate('register.messages.validate.login.required') },
                  pattern: {
                    value: /^[a-zA-Z0-9!$&*+=?^_`{|}~.-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$|^[_.@A-Za-z0-9-]+$/,
                    message: translate('register.messages.validate.login.pattern'),
                  },
                  minLength: { value: 1, message: translate('register.messages.validate.login.minlength') },
                  maxLength: { value: 50, message: translate('register.messages.validate.login.maxlength') },
                }}
                data-cy="username"
              />
              <ValidatedField
                name="email"
                // label={translate('global.form.email.label')}
                placeholder={translate('global.form.email.label')}
                type="email"
                validate={{
                  required: { value: true, message: translate('global.messages.validate.email.required') },
                  minLength: { value: 5, message: translate('global.messages.validate.email.minlength') },
                  maxLength: { value: 254, message: translate('global.messages.validate.email.maxlength') },
                  validate: v => isEmail(v) || translate('global.messages.validate.email.invalid'),
                }}
                data-cy="email"
              />
              <ValidatedField
                name="firstPassword"
                // label={translate('global.form.newpassword.label')}
                placeholder={translate('global.form.newpassword.label')}
                type="password"
                onChange={updatePassword}
                validate={{
                  required: { value: true, message: translate('global.messages.validate.newpassword.required') },
                  minLength: { value: 4, message: translate('global.messages.validate.newpassword.minlength') },
                  maxLength: { value: 50, message: translate('global.messages.validate.newpassword.maxlength') },
                }}
                data-cy="firstPassword"
              />
              <PasswordStrengthBar password={password} />
              <ValidatedField
                name="secondPassword"
                // label={translate('global.form.confirmpassword.label')}
                placeholder={translate('global.form.confirmpassword.label')}
                type="password"
                validate={{
                  required: { value: true, message: translate('global.messages.validate.confirmpassword.required') },
                  minLength: { value: 4, message: translate('global.messages.validate.confirmpassword.minlength') },
                  maxLength: { value: 50, message: translate('global.messages.validate.confirmpassword.maxlength') },
                  validate: v => v === password || translate('global.messages.error.dontmatch'),
                }}
                data-cy="secondPassword"
              />
              <Button id="register-submit" color="primary" type="submit" data-cy="submit">
                <Translate contentKey="register.form.button">Register</Translate>
              </Button>
            </ValidatedForm>
            <p>&nbsp;</p>
            {/* <Alert color="warning">
              <span>
                <Translate contentKey="global.messages.info.authenticated.prefix">If you want to </Translate>
              </span>
              <a className="alert-link">
                <Translate contentKey="global.messages.info.authenticated.link"> sign in</Translate>
              </a>
              <span>
                <Translate contentKey="global.messages.info.authenticated.suffix">
                  , you can try the default accounts:
                  <br />- Administrator (login=&quot;admin&quot; and password=&quot;admin&quot;)
                  <br />- User (login=&quot;user&quot; and password=&quot;user&quot;).
                </Translate>
              </span>
            </Alert> */}
          </Col>
          
        </Row>
        </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
