import { faSquareRootVariable } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getEntity } from '../app/app.reducer';
import Deployment from '../deployment/deployment';
import DeploymentTab from './deployTab/DeploymentTab';
import EntityRelationship from '../entity-relationship/entity-relationship';
import AppFeature from './AppFeature';
import Relationship from './relationshipForm/Relationship';
import AppEntity from './editEntityFunctionality/AppEntity';
import '../../app.scss';
import { Nav, NavItem, NavLink } from 'reactstrap';
import AppScreen from '../app-screen/app-screen';
import Screentab from './screentab/Screentab';
import Dependency from 'app/entities/dependency/dependency';
import {AppStatus} from 'app/shared/model/enumerations/app-status.model';
import AppDependency from "app/entities/app-dependency/app-dependency";
import Job from "app/entities/job/job";
import Endpoints from "app/entities/app-end-point-controller/app-end-point-controller"
import CoreFunctionalityDependency from "app/entities/core-functionality-dependency/core-functionality-dependency";

function ProductAllFeatures() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { appId } = useParams<'appId'>();
  const { productId } = useParams<'productId'>();
  const [showTab, setShowTab] = useState(localStorage.getItem('selectedTab') || 'AppFeature');
  const appEntity = useAppSelector(state => state);

  useEffect(() => {
    dispatch(getEntity(appId));
  }, []);


  useEffect(() => {
    localStorage.setItem('selectedTab', showTab);
  }, [showTab]);

  useEffect(() => {
    if(appEntity?.app?.entity?.status == AppStatus.Deleted)
      navigate(`/product/${productId}`)
  }, [appEntity]);

  return (
    <div className="inner-content-wrap">
      <Nav tabs>
        <NavItem>
          <NavLink herf="#" onClick={() => setShowTab('AppFeature')} active={showTab == 'AppFeature'}>
          Features
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink onClick={() => setShowTab('AppEntity')} active={showTab == 'AppEntity'}>
            Entities{' '}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink onClick={() => setShowTab('EntityRelationship')} active={showTab == 'EntityRelationship'}>
            Relationships{' '}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink onClick={() => setShowTab('AppScreen')} active={showTab == 'AppScreen'}>
            Screens{' '}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink onClick={() => setShowTab('Deployment')} active={showTab == 'Deployment'}>
            Deployments
          </NavLink>
        </NavItem>
        {/*<NavItem>*/}
        {/*  <NavLink onClick={() => setShowTab('Dependency')} active={showTab == 'Dependency'}>*/}
        {/*    Dependencies*/}
        {/*  </NavLink>*/}
        {/*</NavItem>*/}
        <NavItem>
          <NavLink onClick={() => setShowTab('AppDependency')} active={showTab == 'AppDependency'}>
            App Dependencies
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink onClick={() => setShowTab('CoreFunctionalityDependency')} active={showTab == 'CoreFunctionalityDependency'}>
            Core Functionality Dependencies
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink onClick={() => setShowTab('Jobs')} active={showTab == 'Jobs'}>
            Jobs
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink onClick={() => setShowTab('Endpoints')} active={showTab == 'Endpoints'}>
            Endpoints
          </NavLink>
        </NavItem>
      </Nav>

      {showTab == 'AppFeature' && <AppFeature />}
      {showTab == 'AppEntity' && <AppEntity />}
      {showTab == 'EntityRelationship' && <Relationship />}
      {showTab == 'AppScreen' && <Screentab />}
      {showTab == 'Deployment' && <DeploymentTab />}
      {/*{showTab == 'Dependency' && <Dependency />}*/}
      {showTab == 'AppDependency' && <AppDependency />}
      {showTab == 'CoreFunctionalityDependency' && <CoreFunctionalityDependency />}
      {showTab == 'Jobs' && <Job />}
      {showTab == 'Endpoints' && <Endpoints />}
    </div>
  );
}

export default ProductAllFeatures;
