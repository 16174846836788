import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col, Input, FormGroup, Label } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './functionality.reducer';

export const FunctionalityDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();
  const { appId } = useParams<'appId'>();

  console.log("::::::", appId)

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const functionalityEntity = useAppSelector(state => state.functionality.entity);
  return (
    <div className="inner-content-wrap">
      <Row>
        <Col md="12">
          <h2 className="row  page-title" data-cy="functionalityDetailsHeading">
            <Translate contentKey="conductorMonolithApp.functionality.detail.title">Functionality</Translate>
          </h2>
          <dl className="jh-entity-details">
            {/* <dt>
              <span id="id">
                <Translate contentKey="global.field.id">ID</Translate>
              </span>
            </dt>
            <dd>{functionalityEntity.id}</dd> */}
            <Col md ={6}>
          <FormGroup>
              <span id="name">
                <Label contentKey="conductorMonolithApp.functionality.name">Name</Label>
              </span>
              <Input value={functionalityEntity.name}/>
            </FormGroup>
          </Col>
          <Col md ={6}>
          <FormGroup>
              <span id="description">
                <Label contentKey="conductorMonolithApp.functionality.description">Description :</Label>
              </span>
              <Input value={functionalityEntity.description}/>
            </FormGroup>
          </Col>
            <Col md ={6}>
          <FormGroup>
              <span id="link">
                <Label contentKey="conductorMonolithApp.functionality.link">Link</Label>
              </span>
              <Input value={functionalityEntity.link ? (functionalityEntity.link) : "-"} />
            </FormGroup>
          </Col>
          <Col md ={6}>
          <FormGroup>
              <Label contentKey="conductorMonolithApp.functionality.app">App</Label>
              <Input value={functionalityEntity.app ? functionalityEntity.app.name : '-'}/>
            </FormGroup>
          </Col>
          <Col md ={6}>
          <FormGroup>
              <Label contentKey="conductorMonolithApp.functionality.coreFunctionality">Core Functionality</Label>
              <Input value={functionalityEntity.coreFunctionality ? functionalityEntity.coreFunctionality.name : ''}/>
            </FormGroup>
          </Col>
          </dl>
          <Button tag={Link} to={`/product-feature/${appId}`} replace color="back" data-cy="entityDetailsBackButton">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          {/* <Button tag={Link} to={`/functionality/${functionalityEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button> */}
        </Col>
      </Row>
    </div>
  );
};

export default FunctionalityDetail;
