import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardText,
  CardTitle,
  Col,
  Row,
  UncontrolledTooltip,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
  Table,
} from 'reactstrap';
import { Translate, getSortState, JhiPagination, JhiItemCount, isNumber } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCode } from '@fortawesome/free-solid-svg-icons';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities, updateEntities, reset } from './app.reducer';
import { toast } from 'react-toastify';
import { EditIcon } from 'app/shared/assets/svg-icon/icon';
import { deployProduct } from '../customreducers/ustomReducer';
import AppDeleteDialog from './app-delete-dialog';
import { AppStatus } from 'app/shared/model/enumerations/app-status.model';
import AppGenerateDialog from './app-generate-dialog';
import { getEntities as getDeploymentEntities } from 'app/entities/deployment/deployment.reducer';
import DeploymentTab from '../productAllFeatures/deployTab/DeploymentTab';
import { deployEntity } from 'app/entities/deployment/deployment.reducer';
import { keysIn } from 'lodash';

import SearchModule from 'app/shared/component/searchModule/SearchModule';

export const App = () => {
  const dispatch = useAppDispatch();
  const { id: productId } = useParams<'id'>();
  const { productid } = useParams<'productid'>();
  const { appId } = useParams<'appId'>();
  const location = useLocation();
  const navigate = useNavigate();
  const [deleteId, setDeleteId] = useState({});
  const [generateId, setGenerateId] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showGenerateModal, setShowGenerateModal] = useState(false);

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id', DESC), location.search)
  );

  const appList = useAppSelector(state => state.app.entities);
  const loading = useAppSelector(state => state.app.loading);
  const totalItems = useAppSelector(state => state.app.totalItems);
  const updateSuccess = useAppSelector(state => state.app.updateSuccess);
  const deployUpdating = useAppSelector(state => state.deployment.updating);
  const productEntity = useAppSelector(state => state.product.entity);
  const app = useAppSelector(state => state.app.entity);
  const deploymentList = useAppSelector(state => state.deployment.entities);
  const [query, setQuery] = useState('');
  const [appListDeploymentList, setAppListDeploymentList] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState('');

  useEffect(() => {
    if (!productId) navigate('/product');
  });

  const getAllEntities = (emptyQuery?: any) => {
    const queryValue = emptyQuery != undefined ? emptyQuery : query;
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `id,desc`,
        query: `productId.equals=${productId}&status.notEquals=${AppStatus.Deleted}${queryValue ? `&name.contains=${queryValue}` : ''}`,
      })
    );
  };

  const handleDeploymentEntity = async (appId, deploymentId) => {
    // let productid = Number(productId);
    // let deployId = index;
    // let deploymentEntity = deploymentList[deployId];
    const deployment = {
      id: deploymentId,
      appid: appId,
    };
    const { payload } = await dispatch(deployEntity(deployment));

    if (payload) {
      let deploymentData = payload['data'];
      if (deploymentData?.statusCodeValue === 200) {
        toast.success('Deployment in Progess.....');
      } else {
        toast.error(deploymentData?.body);
      }
    }
  };

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  useEffect(() => {
    if (appList.length > 0 && !loading) {
      setAppListDeploymentList(appList);
      getAllDeploymentEntities();
    } else {
      setAppListDeploymentList([]);
    }
  }, [appList, loading]);

  // const updateEntity = async id => {
  //   try {
  //     const data = await dispatch(updateEntities({ id, fileName: 'updateEntities' }));
  //     if (data) toast('App Updated Successfully..!!!');
  //     await navigate('/product');
  //   } catch (error) {
  //     if (error) toast('Unable to update app. Please try again...');
  //   } finally {
  //   }
  // };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  useEffect(() => {
    if (productId) {
      getAllEntities();
    }
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);
  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleDelete = entity => {
    setShowModal(true);
    setDeleteId(entity);
  };
  const handleGenerateApp = entity => {
    setShowGenerateModal(true);
    setGenerateId(entity);
  };

  const closeModal = val => {
    setShowModal(val);
  };
  const closeGenerateModal = val => {
    setShowGenerateModal(val);
  };

  localStorage.removeItem('selectedTab');

  const getAllDeploymentEntities = async () => {
    if (appList.length > 0) {
      let tempAppList = [...appList];

      const promises = tempAppList.map(async (items: any) => {
        const { payload } = await dispatch(
          getDeploymentEntities({
            page: paginationState.activePage - 1,
            size: paginationState.itemsPerPage,
            sort: `${paginationState.sort},${paginationState.order}`,
            query: `appId.equals=${parseInt(items.id)}`,
          })
        );

        return { ...items, deploymentList: payload['data'] };
      });

      const updatedAppList = await Promise.all(promises);
      setAppListDeploymentList(updatedAppList);
    }
  };

  const toggle = appId => {
    if (appId == dropdownOpen) {
      setDropdownOpen('');
    } else {
      setDropdownOpen(appId);
    }
  };
 

  return (
    <>
      <div>
        <div>
          <Row>
            <Col>
              <SearchModule setQuery={setQuery} fetchFunction={getAllEntities} placeHolder="App Name" />
            </Col>
          </Row>
          <Row className="cardLayout">
            <Col md={'3'}>
              <Card className="newCard prodDetailsCard">
                <Link
                  to={`/product/${productId}/app/new`}
                  className="btn btn-create-new jh-create-entity"
                  id="jh-create-entity"
                  data-cy="entityCreateButton"
                >
                  <CardBody>
                    <EditIcon />
                    <Translate contentKey="conductorMonolithApp.app.home.createLabel">Add App</Translate>
                  </CardBody>
                </Link>
              </Card>
            </Col>
            {appListDeploymentList && appListDeploymentList.length > 0
              ? appListDeploymentList.map((app, i) => (
                  <Col md={'3'} key={`entity-${i}`} data-cy="entityTable" style={{ paddingBottom: '20px' }}>
                    <Card className="prodDetailsCard">
                      <CardBody
                        style={{ paddingBottom: '0px' }}
                        key={`entity-${i}`}
                        data-cy="entityTable"
                        className="clickable-row"
                        onClick={() => navigate(`/product/${productId}/app/${app.id}`)}
                      >
                        <CardText className="ProductImg">
                          <span>{app.name}</span>
                        </CardText>
                        <CardText className="m-0">App</CardText>

                        <CardTitle>
                          <CardText>Application Type: {app.applicationType}</CardText>
                          {app.name}
                        </CardTitle>
                      </CardBody>
                      <div className="floating-buttons">
                        {productEntity && productEntity.gitDescriptorsRepo ? (
                          <a
                            className="repo-btn btn"
                            href={`https://gitlab.redblink.net/${productEntity.gitDescriptorsRepo}`}
                            target="_blank"
                          >
                            <FontAwesomeIcon icon={faFileCode} />
                          </a>
                        ) : null}
                        <a
                          className="manage-button btn"
                          id={`manageapp-${app.id}`}
                          href={`/product/${productId}/app/${app.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        >
                          <FontAwesomeIcon icon="pencil-alt" />
                        </a>
                        <UncontrolledTooltip placement="top" target={`manageapp-${app.id}`}>
                          Manage
                        </UncontrolledTooltip>

                        <Button
                          className="del-button"
                          id={`deleteapp-${app.id}`}
                          onClick={e => {
                            handleDelete(app);
                          }}
                        >
                          <FontAwesomeIcon icon="trash" />
                        </Button>
                        <UncontrolledTooltip placement="top" target={`deleteapp-${app.id}`}>
                          Delete
                        </UncontrolledTooltip>
                      </div>

                      <CardFooter>
                        <Button onClick={() => handleGenerateApp(app.product.id)} color="manage">
                          {/* <FontAwesomeIcon icon="server" />  */}
                          Generate
                        </Button>
                        {/* <Button onClick={() => dispatch(deployProduct(app.product.id))} color="manage">
                          <FontAwesomeIcon icon="server" /> Deploy
                        </Button> */}

                        <div>
                          {app && app.deploymentList != undefined ? (
                            app?.deploymentList && app?.deploymentList.length > 0 ? (
                              <Dropdown
                                id="deploy-dropdown"
                                isOpen={app.id == dropdownOpen}
                                toggle={() => {
                                  toggle(app.id);
                                }}
                              >
                                <DropdownToggle caret color="primary">
                                  {/* <UncontrolledTooltip placement='right' target={`deploy-dropdown`}>Check environments</UncontrolledTooltip> */}
                                  Deploy
                                </DropdownToggle>
                                <DropdownMenu>
                                  {app?.deploymentList.map((deployment, index) => (
                                    <DropdownItem
                                      key={index}
                                      onClick={e => {
                                        handleDeploymentEntity(app.id, deployment.id);
                                      }}
                                    >
                                      {deployment['envName']}
                                    </DropdownItem>
                                  ))}
                                </DropdownMenu>
                              </Dropdown>
                            ) : (
                              <Button id="deploy-btn" style={{ fontSize: '14px', padding: '7px 21px', fontWeight: '500' }}>
                                <UncontrolledTooltip placement="bottom" target={`deploy-btn`}>
                                  No deployments exist
                                </UncontrolledTooltip>
                                Deploy
                              </Button>
                            )
                          ) : (
                            <div style={{ padding: '7px 21px', width: '95px', textAlign: 'center' }}>
                              <Spinner size="sm" color="primary">
                                Loading...
                              </Spinner>
                            </div>
                          )}
                        </div>
                      </CardFooter>
                    </Card>
                  </Col>
                ))
              : !loading && (
                  <Col md={'3'} key={`entity-noappscol`} data-cy="entityTable">
                    <Card className="noDataCard">
                      <span className="noDataTitle">
                        <h5> No Apps found</h5>
                      </span>
                    </Card>
                  </Col>
                )}
            {/* <Col md={'6'}>
              <Card>
                <CardBody>
                  <CardTitle>
                    <CardText>
                    <span>
                      <h5>ENVIRONMENTS</h5>
                    </span>
                    </CardText>
                  </CardTitle>
                  <div>                  
                  <div>
                  {deploymentList && deploymentList.length > 0 ? (
                    <Table responsive>
                      <thead className="table-primary">
                        <tr>
                          <th className="hand" onClick={sort('deploymentType')}>
                            <Translate contentKey="conductorMonolithApp.deployment.deploymentType">Deployment Type</Translate>
                            <FontAwesomeIcon icon="sort" />
                          </th>
                          <th className="hand" onClick={sort('envName')}>
                            <Translate contentKey="conductorMonolithApp.deployment.envName">Environment Name</Translate>
                            <FontAwesomeIcon icon="sort" />
                          </th>
                          <th className="hand" onClick={sort('resourceName')}>
                            <Translate contentKey="conductorMonolithApp.deployment.resourceName">Resource Name</Translate>
                            <FontAwesomeIcon icon="sort" />
                          </th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {deploymentList.map((deployment, i) => (
                          <tr key={`entity-${i}`} data-cy="entityTable">
                            <td>{deployment.deploymentType}</td>
                            <td>{deployment.envName}</td>
                            <td>{deployment.resourceName}</td>

                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    loading ? (<div className="conductor_loader"></div>) : (
                      <div className="alert alert-warning">
                        <Translate contentKey="conductorMonolithApp.deployment.home.notFound">No Deployments found</Translate>
                      </div>
                    )
                  )}
                </div>
                
                </div>
                </CardBody>
              </Card>
            </Col> */}
          </Row>
        </div>
        {totalItems ? (
          <div className={appList && appList.length > 0 ? '' : 'd-none'}>
            {/* <div className="justify-content-center d-flex">
              <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
            </div> */}
            <div className="justify-content-center d-flex  mt-5">
              <JhiPagination
                activePage={paginationState.activePage}
                onSelect={handlePagination}
                maxButtons={5}
                itemsPerPage={paginationState.itemsPerPage}
                totalItems={totalItems}
              />
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      {showModal && <AppDeleteDialog itemId={deleteId} closeModal={closeModal} />}
      {showGenerateModal && <AppGenerateDialog generateId={generateId} closeGenerateModal={closeGenerateModal} />}
    </>
  );
};

export default App;
