import { useAppSelector } from 'app/config/store';
import React from 'react';
import { Button, Col, Input, Row } from 'reactstrap';

type Props = {
  fetchFunction?: any;
  setQuery?: any;
  placeHolder?: string;
};

const SearchModule = (props: Props) => {
  const { setQuery, fetchFunction, placeHolder } = props;
  const appEntities = useAppSelector(state => state.appEntity.entities);

  const handleQuery = e => {
    setQuery(e.target.value);
    if (!e.target.value) {
      fetchFunction(e.target.value);
    }
  };

  const handleKeyPress = async e => {
    if (e.key === 'Enter') {
      await fetchFunction();
    }
  };

  return (
    <Row style={{ marginBottom: '12px' }}>
      <Col>
        <Input type="text" onChange={handleQuery} placeholder={placeHolder ? `Search ${placeHolder}` : ''} onKeyDown={handleKeyPress} />
      </Col>
      <Col>
        <Button size="sm" onClick={() => fetchFunction()} color="info">
          Search
        </Button>
      </Col>
    </Row>
  );
};

export default SearchModule;
