import { IAppEndPoint } from 'app/shared/model/app-end-point.model';
import { IApp } from 'app/shared/model/app.model';

export interface IAppEndPointController {
  id?: number;
  packageName?: string | null;
  className?: string | null;
  description?: string | null;
  appEndPoints?: IAppEndPoint[] | null;
  app?: IApp | null;
}

export const defaultValue: Readonly<IAppEndPointController> = {};
