import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { ChangeEventHandler, useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';

type Props = {
  changeFxn: ChangeEventHandler<HTMLSelectElement>;
  callbackFXN?: any;
  paginateState?: any;
  enitityData?: any;
  callbackFxnProps?: any;
  setPageState?: any;
  entity?: string;
  name?: string;
  label?: string;
  defaultValue?: any;
  isNew?: boolean;
};

const DynamicSelect = (props: Props) => {
  const { enitityData, callbackFXN, paginateState, callbackFxnProps, setPageState, entity, changeFxn, defaultValue, name, isNew } = props;

  const [data, setData] = useState([]);
  const dispatch = useAppDispatch();
  const [searchInputValue, setSearchInputValue] = useState('');
  const loading = useAppSelector(state => state[entity].loading);
  const totalItems = useAppSelector(state => state[entity].totalItems);
  const [tempValueObj, setTempValueObj] = useState(undefined);
  useEffect(() => {
    if (defaultValue != undefined && Object.keys(defaultValue).length > 0) {
      if (!data.includes(defaultValue)) {
        setTempValueObj({
          ...defaultValue,
          value: defaultValue?.id,
          label: entity == 'dependency' ? defaultValue?.packageName : defaultValue?.name,
        });
        setSearchInputValue('')
        loadMoreData();
      }
    }
  }, [defaultValue]);

  const loadMoreData = async (searchQuery?: any, type?: string) => {
    try {
      if (isNew || isNew == undefined) {
        if (type != 'reset' && typeof searchQuery != 'string') {
          if (data.length >= totalItems) return;
        }

        const apiData = await dispatch(
          callbackFXN({
            ...callbackFxnProps,
            page:
              searchQuery && typeof searchQuery == 'string' ? 0 : type != 'reset' ? paginateState.activePage : paginateState.activePage - 1,
            query:
              searchQuery && typeof searchQuery == 'string'
                ? `${callbackFxnProps.query}&${entity == 'dependency' ? 'packageName' : 'name'}.contains=${searchQuery}`
                : `${callbackFxnProps.query}`,
          })
        );
        if (apiData && apiData?.payload?.data.length > 0 && type != 'reset') {
          setPageState(prevPageState => ({ ...prevPageState, activePage: paginateState.activePage + 1 }));
        }
      }
    } catch (error) {
      console.log('Error loading more data:', error);
    }
  };

  useEffect(() => {
    if (name != 'appentity') {
      if (enitityData.length > 0) {
        let newElems = [];
        enitityData.forEach(item => {
          let find = data.find(existingElem => existingElem.value === item.id);
          if (find) return;
          newElems.push({ value: item.id, label: entity == 'dependency' ? item?.packageName : item?.name });
        });

        setData(data => [...data, ...newElems]);
      } else {
        setData([]);
      }
    } else {
      if (enitityData.length > 0) {
        setData(enitityData);
      }
    }
  }, [enitityData]);

  const searchValue = async (newValue, { action }) => {
    if (action === 'input-change') {
      setSearchInputValue(newValue);
      if (newValue) {
        loadMoreData(newValue, 'reset');
      } else {
        loadMoreData({}, 'reset');
      }
    }
  };

  return (
    <Select
      onChange={changeFxn}
      onMenuScrollToBottom={loadMoreData}
      options={loading ? [...data, { value: '', label: 'Loading...' }] : data}
      value={tempValueObj}
      onInputChange={searchValue}
      // defaultOptions
      inputValue={searchInputValue}
      required
    />
  );
};

export default DynamicSelect;
