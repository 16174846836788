import React from 'react';
import { Translate } from 'react-jhipster';

import { NavItem, NavLink, NavbarBrand } from 'reactstrap';
import { NavLink as Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ConductorLogo } from 'app/shared/assets/svg-icon/logo';
export const BrandIcon = props => (
  <div {...props} className="brand-icon">
    {/* <img src="content/images/logo-jhipster.png" alt="Logo" /> */}
    {/* <img src="content/images/logo.png" alt="Logo" /> */}
    <ConductorLogo />
  </div>
);

export const Brand = () => (
  <NavbarBrand tag={Link} to="/" className="brand-logo">
    <BrandIcon />
    {/* <span className="brand-title">
      <Translate contentKey="global.title">Conductor_monolith</Translate>
    </span> */}
    {/* <span className="navbar-version">{VERSION}</span> */}
  </NavbarBrand>
);

export const Home = () => (
  <NavItem>
    <NavLink tag={Link} to="/" className="d-flex align-items-center">
      <FontAwesomeIcon icon="home" />
      <span>
        <Translate contentKey="global.menu.home">Home</Translate>
      </span>
    </NavLink>
  </NavItem>
);

export const Demo = () => (
  <NavItem>
    <NavLink tag={Link} to="/demo-list" className="d-flex align-items-center">
      <span>Demo</span>
    </NavLink>
  </NavItem>
);
