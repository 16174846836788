import React from 'react';
export const ConductorLogo = () => (
    <svg width="154" height="76" viewBox="0 0 154 76" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M68.5815 34.253C68.5815 27.6383 74.0376 22.5491 81.1281 22.5491C84.065 22.5913 87.217 23.8516 89.5518 25.9319L86.0742 30.3155C84.6733 29.0674 82.8239 28.2473 81.1403 28.2473C77.6381 28.2473 74.7135 30.8703 74.7135 34.2952C74.7135 37.7202 77.7672 40.313 81.0175 40.313C82.9222 40.313 84.6364 39.4266 86.1049 38.1362L89.5518 42.6405C87.3767 44.5821 84.4828 45.9267 81.0175 45.9267C74.5906 45.9267 68.5815 41.2657 68.5815 34.253Z" fill="#1764AF"/>
<path d="M136.076 34.2771C136.076 41.2114 131.345 45.4263 123.603 45.4263H116.175V23.0918L123.603 23.0797C131.345 23.0496 136.076 27.2946 136.076 34.2832V34.2771ZM130.012 34.2651C130.012 30.7557 127.523 28.6212 123.443 28.6212H122.061V39.8366H123.499C127.542 39.8366 130.012 37.7262 130.012 34.2651Z" fill="#1764AF"/>
<path d="M144.408 28.597V31.7144H151.639V36.6649H144.408V39.8668H153.194V45.4262H138.429V23.0737H153.194V28.591H144.408V28.597Z" fill="#1764AF"/>
<path d="M69.6812 56.2799C69.6812 53.464 72.0958 51.0882 74.9529 51.0882C76.2739 51.1063 77.4475 51.6189 78.5104 52.6258L77.3307 54.1996C76.6364 53.5303 75.8069 53.1263 74.9283 53.1263C73.294 53.1263 71.7395 54.5494 71.7395 56.292C71.7395 58.0346 73.3493 59.4395 74.9222 59.4395C75.8561 59.4395 76.661 58.9511 77.3369 58.2999L78.5104 59.9219C77.6133 60.7963 76.5258 61.4475 74.9898 61.4475C72.059 61.4475 69.6812 59.0778 69.6812 56.2799Z" fill="#1764AF"/>
<path d="M78.5107 56.2859C78.5107 53.4218 80.8886 51.0882 83.7886 51.0882C86.6887 51.0882 89.0727 53.4278 89.0727 56.2859C89.0727 59.1441 86.6887 61.4475 83.7825 61.4475C80.8763 61.4475 78.5107 59.12 78.5107 56.2859ZM87.0082 56.2739C87.0082 54.5313 85.5582 53.1082 83.7825 53.1082C82.0068 53.1082 80.5691 54.5313 80.5691 56.2739C80.5691 58.0165 82.013 59.4215 83.7825 59.4215C85.552 59.4215 87.0082 58.0044 87.0082 56.2739Z" fill="#1764AF"/>
<path d="M98.2889 51.2691V61.4475H98.1968L92.1693 55.689V61.2726H90.1294V51.1063H90.2277L96.2429 56.8527V51.2691H98.2889Z" fill="#1764AF"/>
<path d="M108.015 56.286C108.015 59.3793 106.012 61.2545 102.731 61.2545H99.7759V51.2933H102.731C106.012 51.2812 108.015 53.1746 108.015 56.286ZM105.957 56.2799C105.957 54.4107 104.734 53.2771 102.725 53.2771H101.81V59.2647H102.744C104.74 59.2647 105.957 58.1371 105.957 56.286V56.2799Z" fill="#1764AF"/>
<path d="M108.974 57.5763V51.2932H111.026V57.5703C111.026 58.6557 111.899 59.4516 112.882 59.4516C113.865 59.4516 114.756 58.6557 114.756 57.5703V51.2932H116.808V57.5763C116.808 59.8315 115.051 61.4776 112.888 61.4776C110.725 61.4776 108.968 59.8315 108.968 57.5763H108.974Z" fill="#1764AF"/>
<path d="M117.766 56.2799C117.766 53.464 120.181 51.0882 123.038 51.0882C124.359 51.1063 125.532 51.6189 126.595 52.6258L125.416 54.1996C124.721 53.5303 123.892 53.1263 123.013 53.1263C121.379 53.1263 119.824 54.5494 119.824 56.292C119.824 58.0346 121.434 59.4395 123.007 59.4395C123.941 59.4395 124.746 58.9511 125.422 58.2999L126.595 59.9219C125.698 60.7963 124.611 61.4475 123.075 61.4475C120.144 61.4475 117.766 59.0778 117.766 56.2799Z" fill="#1764AF"/>
<path d="M133.895 53.2771H131.4V61.2605H129.354V53.2771H126.854V51.2932H133.895V53.2771Z" fill="#1764AF"/>
<path d="M133.821 56.2859C133.821 53.4218 136.199 51.0882 139.099 51.0882C141.999 51.0882 144.383 53.4278 144.383 56.2859C144.383 59.1441 141.999 61.4475 139.093 61.4475C136.186 61.4475 133.821 59.12 133.821 56.2859ZM142.318 56.2739C142.318 54.5313 140.868 53.1082 139.093 53.1082C137.317 53.1082 135.879 54.5313 135.879 56.2739C135.879 58.0165 137.323 59.4215 139.093 59.4215C140.862 59.4215 142.318 58.0044 142.318 56.2739Z" fill="#1764AF"/>
<path d="M150.712 61.2605L147.48 56.5512V61.2605H145.44V51.2932H148.746C150.644 51.2932 152.199 52.7283 152.199 54.5192C152.199 55.7975 151.412 56.9191 150.318 57.2929L153.206 61.2545H150.712V61.2605ZM147.474 55.7855H148.782C149.477 55.7975 150.091 55.2488 150.091 54.5373C150.091 53.8258 149.464 53.2831 148.782 53.2831H147.474V55.7855Z" fill="#1764AF"/>
<path d="M94.5779 34.0239C95.1616 34.2711 95.6409 34.4821 96.1201 34.6811C97.1216 35.1032 97.478 35.9172 97.5026 36.9061C97.5271 37.9071 97.5087 38.914 97.5148 39.915C97.5148 40.9943 97.7913 41.332 98.8604 41.537C99.1861 41.6033 99.524 41.6697 99.8496 41.6576C100.243 41.6456 100.372 41.7722 100.366 42.1521C100.347 43.0023 100.36 43.8464 100.366 44.6966C100.366 44.974 100.317 45.1971 99.9602 45.2031C98.1293 45.2152 96.3044 45.2212 94.5718 44.5157C93.1955 43.955 92.5503 42.9239 92.5134 41.5008C92.4889 40.4577 92.5134 39.4205 92.495 38.3774C92.4827 37.3222 92.0833 36.7132 91.0573 36.3755C90.5289 36.2006 89.9574 36.1102 89.3983 36.0619C88.9314 36.0197 88.7225 35.8388 88.7286 35.3806C88.7347 34.4761 88.7286 33.5656 88.7163 32.6611C88.7163 32.3355 88.8085 32.1787 89.171 32.1365C89.7178 32.0762 90.2585 31.9858 90.7869 31.8531C91.9543 31.5516 92.4766 30.8703 92.495 29.6703C92.5134 28.7236 92.5011 27.777 92.5134 26.8363C92.5319 24.9671 93.4965 23.6526 95.3582 23.3149C96.8513 23.0436 98.3935 23.0255 99.9111 22.8988C100.249 22.8687 100.353 23.0616 100.353 23.3631C100.353 24.2495 100.353 25.1359 100.378 26.0223C100.384 26.3539 100.237 26.4444 99.9172 26.4625C99.4503 26.4926 98.9772 26.5529 98.5225 26.6554C97.7852 26.8303 97.521 27.18 97.521 27.9337C97.521 28.9769 97.5333 30.0201 97.521 31.0572C97.5087 32.5104 96.753 33.445 95.3214 33.8128C95.1063 33.8671 94.8913 33.9334 94.5779 34.0178V34.0239Z" fill="#1764AF"/>
<path d="M108.452 34.0841C107.8 33.8248 107.118 33.6259 106.51 33.2942C105.711 32.854 105.38 32.0883 105.361 31.2019C105.343 30.1406 105.361 29.0794 105.355 28.0181C105.355 27.1619 105.097 26.8302 104.249 26.6313C103.85 26.5408 103.438 26.4926 103.026 26.4805C102.658 26.4624 102.504 26.3479 102.516 25.968C102.541 25.1178 102.504 24.2676 102.541 23.4234C102.547 23.2546 102.75 22.9591 102.873 22.9531C104.82 22.8747 106.762 22.9169 108.581 23.743C109.779 24.2857 110.319 25.2625 110.362 26.5167C110.393 27.4634 110.381 28.404 110.375 29.3507C110.375 31.0572 111.075 31.841 112.808 32.0521C113.627 32.1526 114.035 32.6028 114.031 33.4028C114.031 34.0781 114.012 34.7534 114.024 35.4288C114.024 35.8569 113.871 36.0498 113.404 36.0619C112.961 36.074 112.501 36.1403 112.077 36.273C110.793 36.6649 110.375 37.2438 110.375 38.5583C110.375 39.4265 110.375 40.2948 110.375 41.1631C110.375 43.2495 109.502 44.4192 107.426 44.7991C105.969 45.0644 104.47 45.0946 102.983 45.2031C102.836 45.2152 102.547 45.0162 102.547 44.9077C102.516 43.8524 102.529 42.7912 102.529 41.6877C102.946 41.6395 103.309 41.5913 103.678 41.5611C104.906 41.4586 105.355 40.9943 105.355 39.7944C105.355 38.908 105.355 38.0216 105.355 37.1352C105.367 35.7725 105.933 34.9705 107.217 34.4881C107.634 34.3313 108.058 34.2107 108.445 34.0841H108.452Z" fill="#1764AF"/>
<path d="M32.9299 1.72876H30.7578V15.3293H32.9299V1.72876Z" fill="#B1CCF2"/>
<path d="M31.6267 3.14832H30.7578V16.3647H31.6267V3.14832Z" fill="#C8DAF6"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M30.6292 12.6307L4.96485 27.1729C4.24877 27.5758 3.80957 28.3254 3.80957 29.1359V58.0891C3.80957 58.8996 4.24877 59.6492 4.96485 60.0521L30.634 74.7301C31.35 75.133 32.2284 75.133 32.9445 74.7301L58.6136 60.1879C59.3297 59.785 59.7689 59.0354 59.7689 58.2249V29.1406C59.7689 28.3301 59.3297 27.5805 58.6136 27.1776L32.9445 12.6354C32.2284 12.2325 31.35 12.2325 30.634 12.6354L30.6292 12.6307Z" fill="#C8DAF6"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M58.3942 60.2442L33.1977 74.5193C32.7871 74.7536 32.3288 74.8754 31.8705 74.8848L31.8037 43.5984L59.411 27.9553C59.6545 28.3676 59.7882 28.8314 59.7882 29.3233V57.8783C59.7882 58.8574 59.2583 59.757 58.3942 60.2442Z" fill="#C8DAF6"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M31.8751 74.8848C31.3739 74.8988 30.8678 74.777 30.4191 74.5193L5.22258 60.1083C4.36329 59.6164 3.83339 58.7169 3.82861 57.7424V29.3233C3.82861 28.8079 3.97183 28.3207 4.23439 27.9037L31.8083 43.5984L31.8751 74.8848Z" fill="#A1C0E5"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M59.4156 27.96L31.8083 43.6031L4.23438 27.9037C4.47307 27.5196 4.80724 27.1916 5.21779 26.9574L30.4143 12.6822C31.2784 12.195 32.3382 12.195 33.2022 12.6822L58.3988 26.9574C58.8284 27.201 59.1769 27.5524 59.4156 27.9553V27.96Z" fill="#B1CCF2"/>
<path d="M31.7844 60.1458L17.2527 51.9096V35.4466L31.7844 27.2104L46.3161 35.4466L54.594 30.7569L31.7892 17.831L8.98438 30.7569V56.5993L31.7892 69.5204L54.594 56.5993L46.3161 51.9096L31.7844 60.1458Z" fill="#3B8BFF"/>
<path d="M8.97949 30.7569L17.2526 35.4466V51.9096L31.7843 60.1458V69.5204L8.97949 56.6039V30.7569Z" fill="#1764AF"/>
<path d="M31.8463 5.74379C33.4625 5.74379 34.7727 4.458 34.7727 2.87189C34.7727 1.28579 33.4625 0 31.8463 0C30.2301 0 28.9199 1.28579 28.9199 2.87189C28.9199 4.458 30.2301 5.74379 31.8463 5.74379Z" fill="#3B8BFF"/>
<path d="M17.2529 35.4466L31.7846 27.2104L46.3163 35.4466V51.9096L31.8753 60.3051L17.2529 51.9096V35.4466Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M54.589 30.7569V56.6039L46.3159 51.9096V35.4138L54.589 30.7569Z" fill="#B1CCF2"/>
<path d="M23.9984 43.3033L26.2087 42.118L23.9984 40.9187L23.9268 38.7682L29.4119 42.0618V42.1649L24.0031 45.4537V43.2986L23.9984 43.3033Z" fill="#1764AF"/>
<path d="M34.1475 42.1649V42.0618L39.6374 38.7682L39.5658 40.9187L37.3555 42.118L39.5658 43.3033V45.4584L34.1522 42.1695L34.1475 42.1649Z" fill="#1764AF"/>
<path d="M27.984 49.4761L27.8516 51.815L35.4494 52.2292L35.5818 49.8903L27.984 49.4761Z" fill="#1764AF"/>
<path d="M2.8691 35.217H2.19598C0.983175 35.217 0 36.1819 0 37.3721V45.0789C0 46.2692 0.983175 47.234 2.19598 47.234H2.8691C4.08191 47.234 5.06508 46.2692 5.06508 45.0789V37.3721C5.06508 36.1819 4.08191 35.217 2.8691 35.217Z" fill="#A1C0E5"/>
<path d="M61.1342 35.217H60.4611C59.2483 35.217 58.2651 36.1819 58.2651 37.3721V45.0789C58.2651 46.2692 59.2483 47.234 60.4611 47.234H61.1342C62.347 47.234 63.3302 46.2692 63.3302 45.0789V37.3721C63.3302 36.1819 62.347 35.217 61.1342 35.217Z" fill="#C8DAF6"/>
</svg>
  );