import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppSelector } from 'app/config/store';
import ProductAllFeatures from 'app/entities/productAllFeatures/ProductAllFeatures';
import {
  AppEntityFieldSvgIcon,
  AppEntitySvgIcon,
  AppSvgIcon,
  DeploymentSvgIcon,
  EntityRelationshipSvgIcon,
  FunctionalitySvgIcon,
  ProductSvgIcon,
} from 'app/shared/assets/svg-icon/icon';
import { ConductorLogo } from 'app/shared/assets/svg-icon/logo';
import React, { useEffect, useMemo, useState } from 'react';
import { Route, useLocation, useNavigate } from 'react-router-dom';
import { Nav, NavLink, NavItem, Spinner } from 'reactstrap';
import { Brand } from '../header/header-components';
import './sidebar.scss';

function Sidebar() {
  const appEntity = useAppSelector(state => state.app.entity);
  const [isProductVisited, setIsProductVisited] = useState(false);
  const [productId, setProductId] = useState('');

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const match = location.pathname.match(/^\/product\/(\d+)\/app\/(\d+).*?$/);
    setIsProductVisited(match !== null);
    const path = location.pathname.split('/');
    if (path.length >= 3) {
      setProductId(path[2]);
    }
  }, [location]);

  const handleAppClick = () => {
    if (productId != '') {
      navigate(`/product/${productId}`);
    } else {
      navigate(`/product`)
    }
  };

  // useEffect(() => {
  //   let loc_Arr = location.pathname.split('/');
  //   if (loc_Arr[1] == 'product-feature' && !isNaN(parseInt(loc_Arr[2]))) {
  //     setIsProductVisited(true);
  //   }
  // }, [location]);

  // // Navigation for app sidebar
  // const url = window.location.href;
  // const id = url.substring(url.lastIndexOf('/') + 1);

  // const handleAppClick = () => {
  //   const loc_Arr = location.pathname.split('/');
  //   const id = loc_Arr[2];
  //   const newUrl = `/product-feature/${id}`;
  //   navigate(newUrl);
  // };


  return (
    <div className="sidemenu col-md-3 col-xl-2">
      <NavLink href="./product" className="Logo">
        <ConductorLogo />
        {/* <span className="navbar-version">{VERSION}</span> */}
      </NavLink>
      <Nav vertical>
        <NavItem>
          <NavLink href="/product">
            <ProductSvgIcon color={'#1764AF'} /> Products
          </NavLink>
        </NavItem>
        {/*<NavItem isOpen={isProductVisited} disabled={!isProductVisited}>*/}
        {/*  <NavLink*/}
        {/*    onClick={isProductVisited ? handleAppClick : null}*/}
        {/*    style={{ color: isProductVisited ? '#1764AF' : '#111111', cursor: isProductVisited ? 'pointer' : 'not-allowed' }}*/}
        {/*  >*/}
        {/*    <AppSvgIcon color={isProductVisited ? '#1764AF' : '#111111'} /> App*/}
        {/*    {isProductVisited*/}
        {/*      ? appEntity && appEntity.name*/}
        {/*        ? ` - ${appEntity.name}`*/}
        {/*        : [<span>&nbsp;</span>,<Spinner size={'sm'}>Loading...</Spinner>]*/}
        {/*      : ''}*/}
        {/*  </NavLink>*/}
        {/*</NavItem>*/}
        {isProductVisited ?
          <NavItem isOpen>
            <NavLink
              onClick={handleAppClick}
              style={{ color: '#1764AF', cursor: 'pointer' }}
            >
              <AppSvgIcon color={'#1764AF'} />
              {appEntity && appEntity.name
                ? ` ${appEntity.name}`
                : [<span>&nbsp;</span>,<Spinner size={'sm'}>Loading...</Spinner>]
              }
            </NavLink>
          </NavItem>
          : null}
      </Nav>
    </div>
  );
}

export default Sidebar;
