import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText, Label, ButtonGroup } from 'reactstrap';
import { Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import {getEntities as getApps, getEntity as getApp} from 'app/entities/app/app.reducer';
import { EntityType } from 'app/shared/model/enumerations/entity-type.model';
import { getEntity, updateEntity, createEntity, reset } from './app-entity.reducer';
import {getEntities as getFunctionalities, getEntity as getFunctionality} from 'app/entities/functionality/functionality.reducer';

export const AppEntityUpdate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const { appId } = useParams<'appId'>();
  const isNew = id === undefined;

  const app = useAppSelector(state => state.app.entity);
  const appEntity = useAppSelector(state => state.appEntity.entity);
  const loading = useAppSelector(state => state.appEntity.loading);
  const updating = useAppSelector(state => state.appEntity.updating);
  const updateSuccess = useAppSelector(state => state.appEntity.updateSuccess);
  const [type, setType] = useState(EntityType.Standard);
  const entityTypeValues = Object.keys(EntityType);
  // debugger
  console.log("entityTypeValues :: ", Object.keys(entityTypeValues))
  const functionalityEntities = useAppSelector(state => state.functionality.entities);
  const functionalityEntity = useAppSelector(state => state.functionality.entity);
  const [selectFunctionalityId, setSelectFunctionalityId] = useState(0);
  const [appEntityName, setAppEntityName] = useState('');
  const chatGptSearchData = useAppSelector(state => state.chatGptSearch);
  const [appEntityDescription, setAppEntityDescription] = useState('');

  const handleClose = () => {
    navigate(`/product-feature/${appId}` + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
    dispatch(getApp(appId));
    dispatch(getFunctionalities({query: `appId.equals=${appId}`,
    }));
  }, []);

  useEffect(() => {
    if(!isNew && appEntity && appEntity.id) {
      setType(appEntity.type);
    }
    if (!isNew && appEntity && appEntity.functionality && appEntity.functionality.id) {
      setSelectFunctionalityId(appEntity.functionality.id);
      dispatch(getFunctionality(appEntity.functionality.id));
    }
    if (!isNew && appEntity && appEntity.name) {
      setAppEntityName(appEntity.name);
    }
    if (!isNew && appEntity && appEntity.description) {
      setAppEntityDescription(appEntity.description)
    }
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess, appEntity]);

  const saveEntity = values => {
    const entity = {
      ...appEntity,
      ...values,
      type,
      app: app,
      functionality: functionalityEntity && functionalityEntity.id ? functionalityEntity : null,
      name: appEntityName,
      description: appEntityDescription,
    };
    console.log({entity})
    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>{
    if (isNew) {
      return chatGptSearchData?.entity?.identifier ? { appEntityName: chatGptSearchData.entity.identifier } : {};
    }
    else{
      return {
        type: appEntity?.app?.type,
        ...appEntity,
        app: appEntity?.app?.id,
      };
    }
  }

const handleFunctionalityChange = (e) => {
  setSelectFunctionalityId(e.target.value)
  dispatch(getFunctionality(e.target.value))
}

const handleAppEntityNameChange = (e) => {
  setAppEntityName(e.target.value);
}

const handleAppEntityDescription = (e) => {
    setAppEntityDescription(e.target.value);
}

  return (
    <div className="inner-content-wrap">
      <Row className="justify-content-center">
        <Col md="12">
          <h2 className="row  page-title" id="conductorMonolithApp.appEntity.home.createOrEditLabel" data-cy="AppEntityCreateUpdateHeading">
            <Label>{isNew ? `Add` : ` Manage`} App Entity</Label>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="12">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity} className="row">
              <ValidatedField
                className="col-md-6"
                label={translate('conductorMonolithApp.appEntity.name')}
                id="app-entity-name"
                name="appEntityName"
                data-cy="appEntityName"
                type="text"
                value={appEntityName}
                onChange={handleAppEntityNameChange}
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <div className="col-md-6">
                <Label>Type</Label>
                <ButtonGroup style={{ width: "100%" }} >
                  {entityTypeValues.map(entityType => (
                    <Button
                      color="primary"
                      name="type"
                      data-cy="type"
                      id="app-entity-type"
                      outline
                      onClick={() => setType(EntityType[entityType])}
                      active={type === EntityType[entityType]}
                      value={EntityType[entityType]}
                      key={EntityType[entityType]}
                    >
                      {EntityType[entityType]}
                    </Button>
                  ))}
                </ButtonGroup>
              </div>
              <ValidatedField
              onChange={handleFunctionalityChange}
              className="col-md-6"
              id="app-entity-functionality-name"
              name="selectFunctionalityId"
              data-cy="selectFunctionalityId"
              label={translate('conductorMonolithApp.functionality.detail.title')}
              type="select"
              value={selectFunctionalityId}
              >
                {functionalityEntities
                ? functionalityEntities.map(functionality => (
                  <option value={functionality.id} key={functionality.id} selected={true}>
                    {functionality.name}
                  </option>
                  ))
                : null}
              </ValidatedField>
              <ValidatedField
                className="col-md-6"
                label={translate('conductorMonolithApp.appEntity.description')}
                id="app-entity-description"
                name="appEntityDescription"
                data-cy="appEntityDescription"
                type="text"
                value={appEntityDescription}
                onChange={handleAppEntityDescription}
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <Col md={'12'}>
                <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to={`/product-feature/${appId}`} replace color="back">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="save" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </Col>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default AppEntityUpdate;
