import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Dependency from './dependency';
import DependencyDetail from './dependency-detail';
import DependencyUpdate from './dependency-update';
import DependencyDeleteDialog from './dependency-delete-dialog';

const DependencyRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Dependency />} />
    <Route path="/:appId/new" element={<DependencyUpdate />} />
    <Route path=":appId/:id">
      <Route path="edit" element={<DependencyUpdate />} />
      <Route path="view" element={<DependencyDetail />} />
    </Route>
    <Route path=':appId'>
      <Route path="" element={<DependencyDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default DependencyRoutes;
