import { IUser } from 'app/shared/model/user.model';
import { IProduct } from 'app/shared/model/product.model';

export interface IProductUser {
  id?: number;
  user?: IUser | null;
  products?: IProduct[] | null;
}

export const defaultValue: Readonly<IProductUser> = {};
