import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ProductUserRole from './product-user-role';
import ProductUserRoleDetail from './product-user-role-detail';
import ProductUserRoleUpdate from './product-user-role-update';
import ProductUserRoleDeleteDialog from './product-user-role-delete-dialog';

const ProductUserRoleRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ProductUserRole />} />
    <Route path="new" element={<ProductUserRoleUpdate />} />
    <Route path=":id">
      <Route index element={<ProductUserRoleDetail />} />
      <Route path="edit" element={<ProductUserRoleUpdate />} />
      <Route path="delete" element={<ProductUserRoleDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ProductUserRoleRoutes;
