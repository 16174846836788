import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import CoreFunctionality from './core-functionality';
import CoreFunctionalityDetail from './core-functionality-detail';
import CoreFunctionalityUpdate from './core-functionality-update';
import CoreFunctionalityDeleteDialog from './core-functionality-delete-dialog';

const CoreFunctionalityRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<CoreFunctionality />} />
    <Route path="new" element={<CoreFunctionalityUpdate />} />
    <Route path=":id">
      <Route index element={<CoreFunctionalityDetail />} />
      <Route path="edit" element={<CoreFunctionalityUpdate />} />
      <Route path="delete" element={<CoreFunctionalityDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default CoreFunctionalityRoutes;
