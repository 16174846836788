import React, { useState, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Button, Col, Table, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Dropdown, Input, Spinner } from 'reactstrap';
import { Translate, translate, getSortState, JhiPagination, JhiItemCount, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities } from 'app/entities/app-entity/app-entity.reducer';
import AppEntityDeleteDialog from 'app/entities/app-entity/app-entity-delete-dialog';
import SearchModule from 'app/shared/component/searchModule/SearchModule';
import { getEntities as getFeatures, getEntity as getFeature } from 'app/entities/feature/feature.reducer';
import { suggestFeatureEntities } from 'app/entities/ai-suggestor/ai-suggestor.reducer';
import { getEntity as getProduct } from 'app/entities/product/product.reducer';
import { getEntity, updateEntity, createEntity, reset } from 'app/entities/app-entity/app-entity.reducer';
import { EntityType } from 'app/shared/model/enumerations/entity-type.model';

export const AppEntity = (props) => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const { productId } = useParams<'productId'>();
  const { appId } = useParams<'appId'>();
  const { appEntityId } = useParams<'appEntityId'>();
  var [deleteId, setDeleteId] = useState("")
  const [showModal, setShowModal] = useState(false)
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id', DESC), location.search)
  );
  const [query,setQuery] = useState("")

  const app = useAppSelector(state => state.app.entity);
  const appEntities = useAppSelector(state => state.appEntity.entities);
  const appEntity = useAppSelector(state => state.appEntity.entity);
  const productEntity = useAppSelector(state => state.product.entity);
  const featureEntities = useAppSelector(state => state.feature.entities);
  const featureEntity = useAppSelector(state => state.feature.entity);
  const loading = useAppSelector(state => state.appEntity.loading);
  const totalItems = useAppSelector(state => state.appEntity.totalItems);
  const [aiSuggestLoading, setAiSuggestLoading] = useState<boolean>(false);
  const [appEntityList, setAppEntityList] = useState<any>();
  const [showFeatureModel, setShowFeatureModel] = useState(false);
  const [selectFeatureId, setSelectFeatureId] = useState(0);
  const [inputValue, setInputValue] = useState({productName: '', productDescription: '', featureName: '', featureDescription: ''});
  const [selectedFeature, setSelectedFeature] = useState({});

  useEffect(() => {
    // dispatch(getEntity(appEntityId));
    dispatch(getFeatures({ query: `appId.equals=${appId}` }));
    dispatch(getProduct(productId));
    // console.log("featureEntities", featureEntities);
  },[]);

  const getAllEntities = (emptyQuery?:any) => {
    const queryValue = emptyQuery != undefined ? emptyQuery : query
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        // query: `appId.equals=${appId}`,
        query: `appId.equals=${appId}${queryValue?`&name.contains=${queryValue}`:""}`,

      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
  };

  useEffect(() => {
    setAppEntityList(appEntities);
  },[appEntities]);

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const handleDelete = (appEntity_id) => {
    setShowModal(true)
    setDeleteId(appEntity_id)
    deleteId = appEntity_id;
  }

  const viewModal = (modal) => {
    setShowModal(modal)
  }

  const viewFeatureListModal = () => {
    setShowFeatureModel(true);
  }

  const handleCloseFeatureMoal = () => {
    setShowFeatureModel(false);
  };

  // const propss;
  const handleFeautreSelect = (e) => {
    e.preventDefault();
    const filteredFeature = featureEntities.find(feature => feature.id == e.target.value);
    setInputValue({
      productName: productEntity?.name,
      productDescription: productEntity?.description,
      featureName: filteredFeature?.name,
      featureDescription: filteredFeature?.description
    })
    setSelectFeatureId(e.target.value);
    setSelectedFeature(filteredFeature);
  }

  const handleAiSuggest = async(e) => {
    setShowFeatureModel(false);
    setAiSuggestLoading(true);

    const {payload} = await dispatch(suggestFeatureEntities(inputValue));
    let result = payload['data']
    let temp_result = [];
    result.map(ele => {
      let temp_obj = {...ele, key: `${ele.name}-${Date.now()}`, feature: selectedFeature}
      temp_result.push(temp_obj);
    })
    let temp_arr = appEntityList.concat(temp_result);
    
    const distinctArray = temp_arr.reduce((accumulator, current) => {
      const found = accumulator.find(item => item.name === current.name || "id" === current.name);
      if (!found) {
        accumulator.push(current);
      }
      return accumulator;
    }, []);
    setAiSuggestLoading(false);
    setAppEntityList(distinctArray);
  }

  const handleOnSaveForSuggested = async(data) => {
    const entity = {
      ...data,
      type: "Standard",
      app: app,
      feature: selectedFeature,
    };
    const {payload} =  await dispatch(createEntity(entity));
    let ele = document.getElementById('entity-key-'+data.key);
    ele.parentNode.removeChild(ele);
    let temp_arr = appEntityList.concat([payload['data']]);

    if(payload['data']){setAppEntityList(temp_arr)}
  }

  const handleSuggestedDelete = (data) => {
    let ele = document.getElementById('entity-key-'+data.key);
    ele.parentNode.removeChild(ele);
  }

  return (
    <>
      <div className='inner-content-wrap' id="app-entity">
        <h2 id="app-entity-heading" data-cy="AppEntityHeading" className="row mb-4 mt-3">
        <Col>
              <SearchModule
                setQuery={setQuery}
                fetchFunction={getAllEntities}
                placeHolder='Entity Name'
              />
            </Col>
          <Col md="12">
            <div className="d-flex justify-content-end">
              {/* <Button className="me-2" color="refresh" onClick={handleSyncList} disabled={loading}>
                <FontAwesomeIcon icon="sync" spin={loading} />{' '}
                <Translate contentKey="conductorMonolithApp.appEntity.home.refreshListLabel">Refresh List</Translate>
              </Button> */}
              <Button className="me-2" color="refresh" onClick={viewFeatureListModal} disabled={loading}>
                <FontAwesomeIcon icon="magic-wand-sparkles" spin={loading || aiSuggestLoading} />{' '}
                  AI Suggest
              </Button>
              <Link to={`/product/${productId}/app/${appId}/app-entity/new`} className="btn btn-save jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
                <FontAwesomeIcon icon="plus" />
                &nbsp;
                <Translate contentKey="conductorMonolithApp.appEntity.home.createLabel">Create new App Entity</Translate>
              </Link>
            </div>
          </Col>
        </h2>

        {((aiSuggestLoading) || (appEntityList && appEntityList.length)) > 0 ? (
          <div className="table-responsive">
            <Table>
              <thead className="table-primary">
                <tr>
                  {/* <th className="hand" onClick={sort('id')}>
                    <Translate contentKey="conductorMonolithApp.appEntity.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                  </th> */}
                  <th className="hand" onClick={sort('name')}>
                    <Translate contentKey="conductorMonolithApp.appEntity.name">Name</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  {/* <th>
                    <Translate contentKey="conductorMonolithApp.appEntity.app">App</Translate> <FontAwesomeIcon icon="sort" />
                  </th> */}
                  <th>
                    <Translate contentKey="conductorMonolithApp.appEntity.feature">Feature</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  {/* <th>
                    <Translate contentKey="conductorMonolithApp.appEntity.coreEntity">Core Entity</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th> */}
                  <th />
                </tr>
              </thead>
              <tbody>
                {appEntityList.map((appEntity, i) => (
                  <tr key={`entity-${i}`} id={`entity-key-${appEntity.id || appEntity.key}`} data-cy="entityTable">
                    <td>{appEntity.name}</td>
                    <td>
                      {appEntity.feature ? (
                        <Link style={{ cursor: "auto", textDecoration: "none", fontWeight: "normal", color: "#000" }} to={`/product/${productId}/app/${appId}`}>{appEntity.feature.name}</Link>
                      ) : (
                        ''
                      )}
                    </td>
                    <td className="text-end">
                      {appEntity?.id? <div className="btn-group flex-btn-group-container">
                        <Button
                          tag={Link}
                          to={`/product/${productId}/app/${appId}/app-entity/${appEntity.id}/view`}
                          color="primary" size="sm" data-cy="entityDetailsButton">
                          <FontAwesomeIcon icon="eye" />{' '}
                        </Button>
                        <Button
                          tag={Link}
                          to={`/product/${productId}/app/${appId}/app-entity/${appEntity.id}/edit`}
                          color="warning"
                          size="sm"
                          data-cy="entityEditButton"
                        >
                          <FontAwesomeIcon icon="pencil-alt" />{' '}
                        </Button>
                        <Button
                          onClick={() => handleDelete(appEntity.id)}
                          color="danger"
                          size="sm"
                          data-cy="entityDeleteButton"
                        >
                          <FontAwesomeIcon icon="trash" />{' '}
                        </Button>
                      </div> : <div className="btn-group flex-btn-group-container">
                        <Button
                        onClick={(e) => {handleOnSaveForSuggested(appEntity)}}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                      Save
                    </Button>
                    <Button
                        onClick={() => handleSuggestedDelete(appEntity)}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>}
                    </td>
                  </tr>
                ))}
                {(aiSuggestLoading) ? <tr data-cy="entityTable">
                  <td></td>
                  <td style={{textAlign: 'center'}}>
                  <Spinner className="sm-5" color="primary"> Loading... </Spinner>
                  </td>
                  <td></td>
                </tr> : ''}
              </tbody>
            </Table>

          </div>) : (
          loading ? (<div className="conductor_loader"></div>) : (
            <div className="alert alert-warning">
              <Translate contentKey="conductorMonolithApp.appEntity.home.notFound">No App Entities found</Translate>
            </div>
          )
        )}
        {totalItems ? (
          <div className={appEntityList && appEntityList.length > 0 ? '' : 'd-none'}>
            <div className="justify-content-center d-flex">
              <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
            </div>
            <div className="justify-content-center d-flex">
              <JhiPagination
                activePage={paginationState.activePage}
                onSelect={handlePagination}
                maxButtons={5}
                itemsPerPage={paginationState.itemsPerPage}
                totalItems={totalItems}
              />
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      {showModal && <AppEntityDeleteDialog itemId={deleteId} showModal={viewModal} />}
      {showFeatureModel && 
      // <AppEntityDeleteDialog/>
        <Modal isOpen 
        >
          <ModalHeader
          //  toggle={handleClose} 
           data-cy="appEntityFeatureHeading">
            Select Feature
          </ModalHeader>
          <ValidatedForm onSubmit={handleAiSuggest} className="row">
          <ModalBody id="conductorMonolithApp.appEntity.feature.select">
              <ValidatedField
                  onChange={handleFeautreSelect}
                  id="app-entity-feature-name"
                  name="selectFeatureId"
                  data-cy="selectFeatureId"
                  label={translate('conductorMonolithApp.feature.detail.title')}
                  type="select"
                  value={selectFeatureId}
                  validate={{
                    required: { value: true, message: translate('entity.validation.required') },
                  }}
                >
                  {featureEntities
                    ? featureEntities.map(feature => (
                        <option value={feature.id} key={feature.id} selected={true}>
                          {feature.name}
                        </option>
                      ))
                    : null}
              </ValidatedField>
          </ModalBody>
          <ModalFooter>
          <Button color="secondary" 
          onClick={handleCloseFeatureMoal}
          >
            Close
          </Button>
            <Button id="jhi-appEntity-feature-select" data-cy="entityFeatureSelectButton" color="primary" 
            type="submit" 
            // onClick={handleAiSuggest}
            >
              Suggest
            </Button>
          </ModalFooter>
          </ValidatedForm>
        </Modal>
        }
    </>
  );
};

export default AppEntity;
