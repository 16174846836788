import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ScreenComponent from './screen-component';
import ScreenComponentDetail from './screen-component-detail';
import ScreenComponentUpdate from './screen-component-update';
import ScreenComponentDeleteDialog from './screen-component-delete-dialog';

const ScreenComponentRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ScreenComponent />} />
    <Route path="new" element={<ScreenComponentUpdate />} />
    <Route path=":id">
      <Route index element={<ScreenComponentDetail />} />
      <Route path="edit" element={<ScreenComponentUpdate />} />
      <Route path="delete" element={<ScreenComponentDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ScreenComponentRoutes;
