import React, { useEffect, useState } from 'react';
import { Button, Spinner, Row, Col } from 'reactstrap';
import { ValidatedForm, ValidatedField, getSortState } from 'react-jhipster';
import { extractUserIntentContext } from 'app/entities/chatgpt/chatgpt-search.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getApps, updateEntity as updateApp } from 'app/entities/app/app.reducer';
import { ToastContainer, toast } from 'react-toastify';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { DESC, ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import AiConfirmationDialog from '../../ai-confirmation-dialog';


export const ChatGptSearch = () => {
  const dispatch = useAppDispatch();
  const [updateReq, setUpdateReq] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [productContext, setProductContext] = useState({ appName: undefined, action: undefined, identifier: undefined, columnValue: undefined });
  const coreFunctionalities = useAppSelector(state => state.coreFunctionality.entities);
  const apps = useAppSelector(state => state.app.entities);
  const [selectApp, setSelectApp] = useState(false);
  const [userPrompt, setUserPropmt] = useState('')
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id', DESC), location.search)
  );

  const loading = useAppSelector(state => state.chatGptSearch.loading);

  useEffect(() => {
    if (updateReq && apps && apps.length > 0) {
      if (apps.length === 1 && productContext.action) {
        //app update
        console.log("inside if of apps")
        toast(`Prompt processed successfully, Please update ${productContext.action.split('_').pop()}...`);
        var app = apps && apps[0];
        var appValues = {
          name: productContext.appName ? productContext.appName : productContext.columnValue ? productContext.columnValue : productContext.identifier,
        }
        dispatch(updateApp({
          ...app,
          ...appValues,
        }));
        // Navigate({ to: '/product/' + app.product.id });
      } else {
        console.log("inside else of apps")
        setShowModal(true);
        setSelectApp(true);
      }
    }
    setUpdateReq(false);
    setUserPropmt('')
  }, [updateReq, apps]);


  const searchText = async values => {
    console.log({ values });
    let productContext;
    var count = 0;
    try {
      // productContext = await dispatch(extractProductContext(values.userPrompt));
      productContext = await dispatch(extractUserIntentContext(values.userPrompt));
      count = count + 1;
    } catch (error) {

    } finally {
      console.log(productContext.payload.data);
      console.log(count);
      if (productContext && productContext.payload && productContext.payload.data.action && (productContext.payload.data.identifier || productContext.payload.data.appName)) {
        handleAiResponse(productContext);
      } else if (count === 1) {
        console.log("Calling chatgpt again...")
        productContext = await dispatch(extractUserIntentContext(values.userPrompt));
        console.log({ productContext });
        if (productContext && productContext.payload.data && productContext.payload.data.action && (productContext.payload.data.identifier || productContext.payload.data.appName)) {
          handleAiResponse(productContext);
        } else {
          toast("Hey!! I am not able to understand what you want to do, can you please explain a bit more...");
        }
      } else {
        toast("Hey!! I am not able to understand what you want to do, can you please explain a bit more...");
      }
    }
  };
  
  const getProductAndAppIds = () =>{
    let productId = null, appId = null
    if (location && location.pathname) {
      var splitPath = location.pathname.split("/");
      if (splitPath[2] && typeof Number(splitPath[2]) === 'number') {
        if(location.pathname.includes("product"))
          productId = Number(splitPath[2]);
        else if (location.pathname.includes("product-feature"))
          appId = Number(splitPath[2]);
      }
    }
    return ({productId, appId});
  }

  const handleAiResponse = async productContext => {

    setProductContext(productContext.payload.data);
    var action = productContext.payload.data.action;
    switch(action){
      case 'create_app':
      case 'add_app':
        setSelectApp(false);
        setShowModal(true);
        break;
      case 'update_app':
      case 'add_functionality':
      case 'add_entity':
      case "create_screen":
        let {productId, appId} = getProductAndAppIds();        
        // if (action) {
          await dispatch(getApps({
            page: paginationState.activePage - 1,
            size: paginationState.itemsPerPage,
            sort: `id,desc`,
            query: `${appId ? `id.equals=${appId}` : productId ? `productId.equals=${productId}` : ""}`,
          }));
          setUpdateReq(true);
          setUserPropmt('')
          
        // } else {
        //   toast(`Hey!! You can't update ${action.split('_').pop()}, we are working on this...`)
        // }
        break;
      default:
        toast(`Hey!! You can't update ${action.split('_').pop()}, we are working on this...`)
        toast(`Hey! Please tell me to which app you want to add ${action.split('_').pop()}`);
    }
    // if (action) {

    //   if (action.includes("create") || action.includes("add")) {
    //     if (action.includes("app")) {

    //       setShowModal(true);
    //     } else {
    //       toast(`Hey! Please tell me to which app you want to add ${action.split('_').pop()}`);
    //     }
    //   } else if (action.includes("update")) {
    //     let productId; let appId;

    //     if (location && location.pathname) {
    //       var splitPath = location.pathname.split("/");
    //       if (location.pathname.includes("product") && splitPath[2] && typeof Number(splitPath[2]) === 'number') {
    //         productId = Number(splitPath[2]);
    //       }
    //       if (location.pathname.includes("product-feature") && splitPath[2] && typeof Number(splitPath[2]) === 'number') {
    //         appId = Number(splitPath[2]);
    //       }
    //     }
    //     console.log({ appId })
    //     console.log({ productId })
    //     if (action.includes("app")) {
    //       await dispatch(getApps({
    //         page: paginationState.activePage - 1,
    //         size: paginationState.itemsPerPage,
    //         sort: `id,desc`,
    //         query: `${appId ? `id.equals=${appId}` : productId ? `productId.equals=${productId}` : ""}`,
    //       }));
    //       setUpdateReq(true);
    //     } else {
    //       toast(`Hey!! You can't update ${action.split('_').pop()}, we are working on this...`)
    //     }
    //   }
    // }
  }

  const closeModal = (val) => {
    setShowModal(val)
  }
  const handleUserPrompt = (event) => {
    if(event.target){
      setUserPropmt(event.target.value)
    }else{
      setUserPropmt('')
    }

  }

  return (
    <div>

      <div className="test" style={{ marginLeft: '20px', marginRight: '20px' }}>
      
        
        <ValidatedForm defaultValues={{}} onSubmit={searchText} className="row">
          <ValidatedField
            className="col-md-11"
            id="userPrompt"
            name="userPrompt"
            data-cy="userPrompt"
            value={userPrompt}
            onChange={handleUserPrompt}
            placeholder="Let me know what you want to build..."
            validate={{
              required: { value: true, message: "Please enter valid prompt!!" },
            }}
          />
        
          <Button
            className="col-md-1 mb-3"
            color="save"
            id="save-entity"
            data-cy="entityCreateSaveButton"
            type="submit"
            style={{height:"40px"}}
            
          >
            {loading ? <span><Spinner size="sm">
              Loading...
            </Spinner>
              {' '}Loading
            </span> : "Submit"}
          </Button>
        </ValidatedForm>
       
      </div>

      {showModal && <AiConfirmationDialog productContext={productContext} userPrompt={handleUserPrompt} coreFunctionalities={coreFunctionalities} apps={apps} selectApp={selectApp} closeModal={closeModal} />}
    </div>
  );
};

export default ChatGptSearch;
