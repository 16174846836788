import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText, Label } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IApp } from 'app/shared/model/app.model';
import { getEntities as getApps } from 'app/entities/app/app.reducer';
import { ICoreFunctionality } from 'app/shared/model/core-functionality.model';
import { getEntities as getCoreFunctionalities } from 'app/entities/core-functionality/core-functionality.reducer';
import { IFunctionality } from 'app/shared/model/functionality.model';
import { getEntity, updateEntity, createEntity, reset, getEntities } from './functionality.reducer';

export const FunctionalityUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { productId } = useParams<'productId'>();
  const { appId } = useParams<'appId'>();
  const { functionalityId } = useParams<'functionalityId'>();

  const isNew = functionalityId === undefined;
  const params = new URLSearchParams(location.search).get('app');

  const apps = useAppSelector(state => state.app.entities);
  const coreFunctionalities = useAppSelector(state => state.coreFunctionality.entities);
  const functionalityEntity = useAppSelector(state => state.functionality.entity);
  const loading = useAppSelector(state => state.functionality.loading);
  const updating = useAppSelector(state => state.functionality.updating);
  const updateSuccess = useAppSelector(state => state.functionality.updateSuccess);
  const chatGptSearchData = useAppSelector(state => state.chatGptSearch);

  const handleClose = () => {
    navigate(`/product/${productId}/app/${appId}` + location.search);
  };
  useEffect(() => {

    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(functionalityId));
    }

    dispatch(getApps({query: `id.equals=${appId}`,
}));
    dispatch(getCoreFunctionalities({}));
  }, []);

  useEffect(() => {

    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);



  const saveEntity = values => {
    // console.log("functionalityValues:::::", apps.find(it => it.id.toString() === values.app.toString()))
    const entity = {
      ...functionalityEntity,
      ...values,
      app: apps.find(it => it.id == appId),
      coreFunctionality:coreFunctionalities.find(it => it.id === values.coreFunctionality)
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };


  const defaultValues = () =>{
    if (isNew) {
      {}
      // return chatGptSearchData?.entity?.identifier ? { name: chatGptSearchData.entity.identifier } : {};
    } else {
        return {
        ...functionalityEntity,
        app: functionalityEntity?.app?.id,
        coreFunctionality: functionalityEntity?.coreFunctionality?.id,
        };
    }
  }


  return (
    <div className="inner-content-wrap">
      <Row className="justify-content-center">
        <Col md="12">
          <h2 className="row  page-title" id="conductorMonolithApp.functionality.home.createOrEditLabel" data-cy="FunctionalityCreateUpdateHeading">
            <Label>{isNew ? `Add` : `Manage`} Functionality </Label>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="12">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}  className='row'>
              {!isNew ? (
                // <ValidatedField
                //   className="col-md-6"
                //   name="id"
                //   required
                //   readOnly
                //   id="functionality-id"
                //   label={translate('global.field.id')}
                //   validate={{ required: true }}
                // />
                ""
              ) : null}
              <ValidatedField
               className="col-md-6"
                label={translate('conductorMonolithApp.functionality.name')}
                id="functionality-name"
                name="name"
                data-cy="name"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
               className="col-md-6"
                label={translate('conductorMonolithApp.functionality.description')}
                id="functionality-description"
                name="description"
                data-cy="description"
                type="text"
              />
              <ValidatedField
               className="col-md-6"
                label={translate('conductorMonolithApp.functionality.link')}
                id="functionality-link"
                name="link"
                data-cy="link"
                type="text"
              />
              {/* <ValidatedField
               className="col-md-6"
                id="functionality-app"
                name="app"
                data-cy="app"
                label={translate('conductorMonolithApp.functionality.app')}
                type="select"
                value={apps.name}
              > */}
                {/* <option value="" key="0" /> */}
                {/* {apps
                  ? apps.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id} selected={otherEntity.id == 1 ? true : false}>
                        {otherEntity.name}
                      </option>
                    ))
                  : null}
              </ValidatedField> */}

              {/* <ValidatedField
               className="col-md-6"
                id="functionality-coreFunctionality"
                name="coreFunctionality"
                data-cy="coreFunctionality"
                label={translate('conductorMonolithApp.functionality.coreFunctionality')}
                type="select"
              >
                <option value="" key="0" />
                {coreFunctionalities
                  ? coreFunctionalities.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name}
                      </option>
                    ))
                  : null}
              </ValidatedField> */}
              <Col md={'12'}>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to={`/product/${productId}/app/${appId}`} replace color="back">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span>
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="save" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
              </Col>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default FunctionalityUpdate;
