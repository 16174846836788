import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import ProductAllFeatures from './ProductAllFeatures';
import AppEntityUpdate from './editEntityFunctionality/AppEntityUpdate';
import AppEntityFieldUpdate from './editAppEntityField/AppEntityFieldUpdate';
import AppEntityView from './editEntityFunctionality/AppEntityView';
import EnumMemberUpdate from '../enum-member/enum-member-update';
import AppEntityFieldDetail from '../app-entity-field/app-entity-field-detail';
import AppEntityDeleteDialog from '../app-entity/app-entity-delete-dialog';
import EnumMemberDetail from '../enum-member/enum-member-detail';
import FeatureUpdate from "app/entities/feature/feature-update";
import EntityRelationshipUpdate from "app/entities/entity-relationship/entity-relationship-update";
import AppScreenUpdate from "app/entities/app-screen/app-screen-update";
import AppScreenDetail from "app/entities/app-screen/app-screen-detail";
import DeploymentUpdate from "app/entities/deployment/deployment-update";
import DeploymentDetail from "app/entities/deployment/deployment-detail";
import DependencyUpdate from '../dependency/dependency-update';
import DependencyDetail from '../dependency/dependency-detail';
import DependencyDeleteDialog from "app/entities/dependency/dependency-delete-dialog";
import AppUpdate from '../app/app-update';
import AppDependencyUpdate from "app/entities/app-dependency/app-dependency-update";
import AppDependencyDetail from "app/entities/app-dependency/app-dependency-detail";
import AppDependencyDeleteDialog from "app/entities/app-dependency/app-dependency-delete-dialog";
import JobUpdate from "app/entities/job/job-update";
import JobDetail from "app/entities/job/job-detail";
import AppEndPointControllerUpdate from "app/entities/app-end-point-controller/app-end-point-controller-update";
import AppEndPointControllerDetail from "app/entities/app-end-point-controller/app-end-point-controller-detail";
import AppEndPointUpdate from '../app-end-point/app-end-point-update';
import AppEndPointDetail from '../app-end-point/app-end-point-detail';
import CoreFunctionalityDependencyUpdate
  from 'app/entities/core-functionality-dependency/core-functionality-dependency-update';
import CoreFunctionalityDependencyDetail
  from 'app/entities/core-functionality-dependency/core-functionality-dependency-detail';
import CoreFunctionalityDeleteDialog from 'app/entities/core-functionality/core-functionality-delete-dialog';

const ProductAllFeatureRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ProductAllFeatures />} />
    <Route path="edit" element={<AppUpdate />} />
    <Route path="feature">
      <Route path="new" element={<FeatureUpdate />} />
      <Route path=":featureId/edit" element={<FeatureUpdate />} />
    </Route>
    <Route path="job">
    <Route path="new" element={<JobUpdate />} />
      <Route path=":jobId">
        <Route path="edit" element={<JobUpdate />} />
        <Route path="view" element={<JobDetail />} />
      </Route>
    </Route>
    <Route path="controller">
    <Route path="new" element={<AppEndPointControllerUpdate />} />
      <Route path=":endpointControllerId">
        <Route path="edit" element={<AppEndPointControllerUpdate />} />
        <Route path="view" element={<AppEndPointControllerDetail />} />
        <Route path="endpoint">
          <Route path="new" element={<AppEndPointUpdate />} />
          <Route path=":endpointId">
            <Route path="edit" element={<AppEndPointUpdate />} />
            <Route path="view" element={<AppEndPointDetail />} />
          </Route>
        </Route>
      </Route>
    </Route>
    <Route path="app-entity">
      <Route path="new" element={<AppEntityUpdate />} />
      <Route path=":appEntityId">
        <Route path="view" element={<AppEntityView />} />
        <Route path="view/app-entity-field">
          <Route path="new" element={<AppEntityFieldUpdate />} />
          <Route path=":appEntityFieldId/edit" element={<AppEntityFieldUpdate />} />
          <Route path=":appEntityFieldId/view" element={<AppEntityFieldDetail />} />
        </Route>
        <Route path="view/app-entity-enum">
          <Route path="new" element={<EnumMemberUpdate />} />
          <Route path=":appEntityEnumId/edit" element={<EnumMemberUpdate />} />
          <Route path=":appEntityEnumId/view" element={<EnumMemberDetail />} />
        </Route>
        <Route path="edit" element={<AppEntityUpdate />} />
      </Route>
    </Route>
    <Route path="entity-relationship">
      <Route path="new" element={<EntityRelationshipUpdate />} />
      <Route path=":entityRelationshipId/edit" element={<EntityRelationshipUpdate />} />
    </Route>
    <Route path="app-screen">
      <Route path="new" element={<AppScreenUpdate />} />
      <Route path=":appScreenId">
        <Route path="edit" element={<AppScreenUpdate />} />
        <Route path="view" element={<AppScreenDetail />} />
      </Route>
    </Route>
    <Route path="deployment">
      <Route path="new" element={<DeploymentUpdate />} />
      <Route path=":deploymentId">
        <Route path="edit" element={<DeploymentUpdate />} />
        <Route path="view" element={<DeploymentDetail />} />
      </Route>
    </Route>
    <Route path="dependency">
      <Route path="new" element={<DependencyUpdate />} />
      <Route path=":dependencyId">
        <Route path="edit" element={<DependencyUpdate />} />
        <Route path="view" element={<DependencyDetail />} />
        <Route path="delete" element={<DependencyDeleteDialog />} />
      </Route>
    </Route>
    <Route path="app-dependency">
      <Route path="new" element={<AppDependencyUpdate />} />
      <Route path=":appDependencyId">
        <Route path="edit" element={<AppDependencyUpdate />} />
        <Route path="view" element={<AppDependencyDetail />} />
        <Route path="delete" element={<AppDependencyDeleteDialog />} />
      </Route>
    </Route>
    <Route path="core-functionality-dependency">
      <Route path="new" element={<CoreFunctionalityDependencyUpdate />} />
      <Route path=":coreFunctionalityDependencyId">
        <Route path="edit" element={<CoreFunctionalityDependencyUpdate />} />
        <Route path="view" element={<CoreFunctionalityDependencyDetail />} />
        <Route path="delete" element={<CoreFunctionalityDeleteDialog />} />
      </Route>
    </Route>
    <Route path="app-entity-view/:entityID/:entityFieldId/delete" element={<AppEntityDeleteDialog/>}/>
  </ErrorBoundaryRoutes>
);

export default ProductAllFeatureRoutes;
