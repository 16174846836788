import { ICoreFunctionality } from 'app/shared/model/core-functionality.model';
import { ICoreEntity } from 'app/shared/model/core-entity.model';
import { RelationshipType } from 'app/shared/model/enumerations/relationship-type.model';

export interface ICoreEntityRelationship {
  id?: number;
  type?: RelationshipType;
  userId?: number | null;
  suffix?: string | null;
  coreEntityTo?: ICoreEntity | null;
  coreEntityFrom?: ICoreEntity | null;
  coreFunctionality?: ICoreFunctionality | null;
}

export const defaultValue: Readonly<ICoreEntityRelationship> = {};
