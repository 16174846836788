import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText, Label } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IAppEntity } from 'app/shared/model/app-entity.model';
import { getEntities as getAppEntities, getEntity as getAppEntity } from 'app/entities/app-entity/app-entity.reducer';
import { IAppEntityField } from 'app/shared/model/app-entity-field.model';
import { getEntity, updateEntity, createEntity, reset } from 'app/entities/app-entity-field/app-entity-field.reducer';
import { getEntities as getTypes } from 'app/entities/type/type.reducer';
import { getEntity as getEnumMember, updateEntity as updateEnumEntity, createEntity as createEnumEntity, reset as resetEnumEntity } from '../../enum-member/enum-member.reducer';
import {EntityType} from 'app/shared/model/enumerations/entity-type.model';

export const AppEntityFieldUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { productId } = useParams<'productId'>();
  const { appId } = useParams<'appId'>();
  const { appEntityId } = useParams<'appEntityId'>();
  const { appEntityFieldId } = useParams<'appEntityFieldId'>();

  const isNew = appEntityFieldId === undefined;

  const dataTypes = useAppSelector(state => state.type.entities)
  const enumTypes = useAppSelector(state => state.appEntity.entities);
  const appEntityFieldEntity = useAppSelector(state => state.appEntityField.entity);
  const loading = useAppSelector(state => state.appEntityField.loading);
  const updating = useAppSelector(state => state.appEntityField.updating);
  const updateSuccess = useAppSelector(state => state.appEntityField.updateSuccess);
  const updateSuccessEnum = useAppSelector(state => state.enumMember.updateSuccess);
  const [fieldTypes, setFieldTypes] = useState([]);
  const appEntity = useAppSelector(state => state.appEntity.entity);

  const handleClose = () => {
    navigate(`/product/${productId}/app/${appId}/app-entity/${appEntityId}/view` + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      if (appEntity.type !== EntityType.Enum) {
        dispatch(getEntity(appEntityFieldId));
      } else {
        dispatch(getEnumMember(appEntityFieldId))
      }
    }
    dispatch(getTypes({}))
    dispatch(getAppEntity(appEntityId));
    // dispatch(getAppEntities({}));
    dispatch(getAppEntities({ query: `appId.equals=${appId}&type.equals=${EntityType.Enum}` }));
  }, []);

  useEffect(() => {
    if (updateSuccess || updateSuccessEnum) {
      handleClose();
    }
  }, [updateSuccess, updateSuccessEnum]);


  const saveEntity = values => {
    console.log("values", values)
    const entity = {
      ...appEntityFieldEntity,
      ...values,
      // appEntity: appEntity.type == 'ENUM' ? appEntity : appEntities.find(it => it.id === values.appEntity)
      appEntity: appEntity
    };

    if (appEntity.type == EntityType.Enum) {
      if (isNew) {
        dispatch(createEnumEntity(entity));
      } else {
        dispatch(updateEnumEntity(entity));
      }
    } else {
      if (isNew) {
        dispatch(createEntity(entity));
      } else {
        dispatch(updateEntity(entity));
      }
    }
  };


  const defaultValues = () => {
    return isNew
      ? {}
      : {
        ...appEntityFieldEntity,
        appEntity: appEntityFieldEntity?.appEntity?.id,
      };
  }


  return (
    <div className='inner-content-wrap'>
      <Row className="justify-content-center">
        <Col md="12">
          <h2 className="row  page-title" id="conductorMonolithApp.appEntityField.home.createOrEditLabel" data-cy="AppEntityFieldCreateUpdateHeading">
            <Label>{(isNew ? 'Add' : 'Update') + (appEntity.type == EntityType.Enum ? ` Enum Members` : ` Entity Field`)}</Label>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="12">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}
              className="row">
              {!isNew ? '' : null}
              <ValidatedField
                className='col-md-6'
                label={translate('conductorMonolithApp.appEntityField.name')}
                id="app-entity-field-name"
                name="name"
                data-cy="name"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              {appEntity.type != EntityType.Enum && <ValidatedField
                className='col-md-6'
                label={translate('conductorMonolithApp.appEntityField.type')}
                id="app-entity-field-type"
                name="type"
                data-cy="type"
                type="select"
              >
                {dataTypes ? dataTypes.map(type => {
                  return <option value={type.dataType} key={type.id}>{type.dataType}</option>
                }) : null}
                {enumTypes ? enumTypes.map(enumEntity => {
                  return <option value={enumEntity.name} key={enumEntity.id}>{enumEntity.name}</option>
                }) : null}
              </ValidatedField>}
              {appEntity.type != EntityType.Enum && <ValidatedField
              className='col-md-6'
                label={translate('conductorMonolithApp.appEntityField.isRequired')}
                id="app-entity-field-isRequired"
                name="isRequired"
                data-cy="isRequired"
                check
                type="checkbox"
              />}
              {appEntity.type != EntityType.Enum && <ValidatedField
                className='col-md-6'
                label={translate('conductorMonolithApp.appEntityField.description')}
                id="app-entity-field-description"
                name="description"
                data-cy="description"
                type="text"
              />}
              {/* <ValidatedField
              className='col-md-6'
                id="app-entity-field-appEntity"
                name="appEntity"
                data-cy="appEntity"
                label={translate('conductorMonolithApp.appEntityField.appEntity')}
                type="select"
              >
                <option value="" key="0" />
                {appEntity
                  ?
                      <option value={appEntity.id} key={appEntity.id} selected = {appEntity.id}>
                        {appEntity.name}
                      </option>

                  : null}
              </ValidatedField>  */}
              <Col md={12}>
                <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to={`/product/${productId}/app/${appId}/app-entity/${appEntityId}/view`} replace color="back">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="dark" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </Col>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default AppEntityFieldUpdate;
