import { IAppEndPointController } from 'app/shared/model/app-end-point-controller.model';
import { RestMethod } from 'app/shared/model/enumerations/rest-method.model';

export interface IAppEndPoint {
  id?: number;
  path?: string;
  method?: RestMethod;
  description?: string | null;
  pseudoCode?: string | null;
  outputsList?: boolean | null;
  appEndPointController?: IAppEndPointController | null;
}

export const defaultValue: Readonly<IAppEndPoint> = {
  outputsList: false,
};
