import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText, Label, ListGroupItem, Badge } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IProduct } from 'app/shared/model/product.model';
import { getEntity as getProduct } from 'app/entities/product/product.reducer';
import { IDeployment } from 'app/shared/model/deployment.model';
import { DeploymentType } from 'app/shared/model/enumerations/deployment-type.model';
import { DeploymentCloud } from 'app/shared/model/enumerations/deployment-cloud.model';
import { getEntity, updateEntity, createEntity, reset } from './deployment.reducer';
import { getEntity as getApp } from '../app/app.reducer';
import { cond } from 'lodash';
import { DomainProvider } from 'app/shared/model/enumerations/domain-provider.model';

export const DeploymentUpdate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { productId } = useParams<'productId'>();
  const { appId } = useParams<'appId'>();
  const { deploymentId } = useParams<'deploymentId'>();
  const isNew = deploymentId === undefined;
  const app = useAppSelector(state => state.app.entity);
  const productEntity = useAppSelector(state => state.product.entity);
  const deploymentEntity = useAppSelector(state => state.deployment.entity);
  const loading = useAppSelector(state => state.deployment.loading);
  const updating = useAppSelector(state => state.deployment.updating);
  const updateSuccess = useAppSelector(state => state.deployment.updateSuccess);
  const deploymentTypeValues = Object.keys(DeploymentType);
  const deploymentCloudValues = Object.keys(DeploymentCloud);
  const [deploymentType, setDeploymentType] = useState('NONE');
  const [deploymentCloud, setDeploymentCloud] = useState('NONE');
  const domainProviderValues = Object.keys(DomainProvider);
  const [domainProvider, setDomainProvider] = useState('');

  const handleClose = () => {
    setDeploymentType('NONE');
    setDeploymentCloud('NONE');
    setDomainProvider('')
    navigate(`/product/${productId}/app/${appId}` + location.search);
  };

  const onDeploymentTypeChange = e => {
    if (e.target.value !== 'CLOUD') {
      setDeploymentCloud('NONE');
      setDomainProvider('')
    }
    setDeploymentType(e.target.value);
  };

  const onDeploymentCloudChange = e => {
    if (e.target.value !== 'NONE') {
      setDomainProvider('GODADDY')
    }
    setDeploymentCloud(e.target.value);
  };
  const onDomainProviderChange = e => {

    setDomainProvider(e.target.value);
  };

  useEffect(() => {
    if (!isNew) {
      dispatch(getEntity(deploymentId));
    } 
    dispatch(getApp(appId));
    return () => {
      dispatch(reset());
    };
  }, []);

  useEffect(() => {
    console.log(deploymentEntity)
    if (!isNew) {
      if (deploymentEntity && deploymentEntity.id) {
        setDeploymentType(deploymentEntity.deploymentType);
      }
      if (deploymentEntity && deploymentEntity.deploymentCloud && deploymentEntity.deploymentCloud.toUpperCase() === 'VULTR') {
        setDeploymentCloud(deploymentEntity.deploymentCloud);
      }
      if (deploymentEntity && deploymentEntity.deploymentCloud && deploymentEntity.deploymentCloud.toUpperCase() === 'DIGITALOCEAN') {
        setDeploymentCloud(deploymentEntity.deploymentCloud);
      }
      if (deploymentEntity && deploymentEntity.domainProvider != null) {
        setDomainProvider(deploymentEntity.domainProvider);
      }
    }
  }, [deploymentEntity]);

  // useEffect(() => {
  //   if (isNew) {
  //     if (app && app.product) dispatch(getApp(app?.product?.id));
  //   } else if (deploymentEntity && deploymentEntity.product) {
  //     dispatch(getProduct(deploymentEntity?.product?.id));
  //   }
  // }, [app, deploymentEntity]);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    if (values && !values.resourcePath) {
      values['resourcePath'] = productEntity && productEntity.name && productEntity.name.toLowerCase();
    }

    values['subDomain'] = '@';
    if (values && values.domainName) {
      var domain = values && values.domainName;
      if (domain.split('.').length - 1 === 1) {
        values['subDomain'] = '@';
      } else {
        if (domain.includes('.')) {
          var subDomain = domain.slice(0, domain.slice(0, domain.lastIndexOf('.')).lastIndexOf('.'));
          values['subDomain'] = subDomain;
        }
      }
    }
    const entity = {
      ...deploymentEntity,
      ...values,
      app: app,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
        deploymentType: 'NONE',
        deploymentCloud: 'NONE',
        ...deploymentEntity,
        app: deploymentEntity?.app?.id,
        domainProvider: domainProvider || null 
      };

  return (
    <div className="inner-content-wrap">
      <Row className="justify-content-center">
        <Col md="12">
          <h2
            className="row  page-title"
            id="conductorMonolithApp.deployment.home.createOrEditLabel"
            data-cy="DeploymentCreateUpdateHeading"
          >
            {/* <Translate contentKey="conductorMonolithApp.deployment.home.createOrEditLabel">Add Deployment</Translate> */}
            <Label>{isNew ? `Add` : `Manage`} Deployment</Label>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="12">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity} className="row">
              <ValidatedField
                className="col-md-6"
                label={translate('conductorMonolithApp.deployment.deploymentType')}
                id="deployment-deploymentType"
                name="deploymentType"
                data-cy="deploymentType"
                type="select"
                onChange={onDeploymentTypeChange}
                disabled={isNew ? false : true}
              >
                {deploymentTypeValues.map(deploymentType => (
                  <option value={DeploymentType[deploymentType]} key={DeploymentType[deploymentType]}>
                    {translate('conductorMonolithApp.DeploymentType.' + DeploymentType[deploymentType])}
                  </option>
                ))}
              </ValidatedField>
              {deploymentType && deploymentType.toUpperCase() === 'CLOUD' ? (
                <ValidatedField
                  className="col-md-6"
                  label={translate('conductorMonolithApp.deployment.deploymentCloud')}
                  id="deployment-deploymentCloud"
                  name="deploymentCloud"
                  data-cy="deploymentCloud"
                  type="select"
                  disabled={isNew ? false : true}
                  onChange={onDeploymentCloudChange}
                >
                  {deploymentCloudValues.map(deploymentCloud => (
                    <option value={DeploymentCloud[deploymentCloud]} key={DeploymentCloud[deploymentCloud]}>
                      {translate('conductorMonolithApp.DeploymentCloud.' + DeploymentCloud[deploymentCloud])}
                    </option>
                  ))}
                </ValidatedField>
              ) : (
                ''
              )}

              {deploymentCloud && deploymentCloud.toUpperCase() !== 'NONE' && (
                <ValidatedField
                  className="col-md-6"
                  label={translate('conductorMonolithApp.deployment.cloudToken')}
                  id="deployment-cloudToken"
                  name="cloudToken"
                  data-cy="cloudToken"
                  type="text"
                  validate={{
                    required: { value: true, message: translate('entity.validation.required') },
                  }}
                />
              )}

              {deploymentType && deploymentType.toUpperCase() === 'SSH' && (
                <ValidatedField
                  className="col-md-6"
                  label={translate('conductorMonolithApp.deployment.envName')}
                  id="deployment-envName"
                  name="envName"
                  data-cy="envName"
                  type="text"
                  validate={{
                    required: { value: true, message: translate('entity.validation.required') },
                  }}
                  placeholder='Enter environment name'
                />
              )}
              {deploymentType && deploymentType.toUpperCase() === 'SSH' && (
                <ValidatedField
                  className="col-md-6"
                  label={translate('conductorMonolithApp.deployment.credentials')}
                  id="deployment-credentials"
                  name="credentials"
                  data-cy="credentials"
                  placeholder="username : password"
                  type="text"
                  validate={{
                    required: { value: true, message: translate('entity.validation.required') },
                  }}
                />
              )}

              {deploymentType && deploymentType.toUpperCase() === 'SSH' && (
                <ValidatedField
                  className="col-md-6"
                  label={translate('conductorMonolithApp.deployment.resourceName')}
                  id="deployment-resourceName"
                  name="resourceName"
                  data-cy="resourceName"
                  placeholder="147.93.89.107:22"
                  type="text"
                  validate={{
                    required: { value: true, message: translate('entity.validation.required') },
                  }}
                />
              )}
              {deploymentType && deploymentType.toUpperCase() !== 'NONE' && (
                <ValidatedField
                  className="col-md-6"
                  label={translate('conductorMonolithApp.deployment.resourcePath')}
                  id="deployment-resourcePath"
                  name="resourcePath"
                  data-cy="resourcePath"
                  type="text"
                />
              )}
              {deploymentType && deploymentType !== 'NONE' && (
                <ValidatedField
                  className="col-md-6"
                  label={translate('conductorMonolithApp.deployment.branchName')}
                  id="deployment-branchName"
                  name="branchName"
                  data-cy="branchName"
                  type="text"
                  validate={{
                    required: { value: true, message: translate('entity.validation.required') },
                  }}
                />
              )}
              {deploymentType && deploymentType.toUpperCase() === 'SSH' && (
                <ValidatedField
                  className="col-md-6"
                  label={translate('conductorMonolithApp.deployment.domainName')}
                  id="deployment-domainName"
                  name="domainName"
                  data-cy="domainName"
                  // placeholder={'app.conductor.ai'}
                  type="text"
                  validate={{
                    required: { value: true, message: translate('entity.validation.required') },
                  }}
                />
              )}
              {deploymentType && deploymentType.toUpperCase() === 'SSH' && (
                <ValidatedField
                  className="col-md-6"
                  label={translate('conductorMonolithApp.deployment.appsFolders')}
                  id="deployment-appsFolders"
                  name="appsFolders"
                  data-cy="appsFolders"
                  type="text"
                />
              )}

              {deploymentType && deploymentType.toUpperCase() === 'SSH' && (
                <ValidatedField
                  className="col-md-6"
                  label={translate('conductorMonolithApp.deployment.appPort')}
                  id="deployment-appPort"
                  name="appPort"
                  data-cy="appPort"
                  type="text"
                />
              )}

              {deploymentType && deploymentType.toUpperCase() === 'KUBERNETES' ? (
                <ValidatedField
                  className="col-md-6"
                  label={translate('conductorMonolithApp.deployment.dockerRepositoryName')}
                  id="deployment-dockerRepositoryName"
                  name="dockerRepositoryName"
                  data-cy="dockerRepositoryName"
                  type="text"
                  validate={{
                    required: { value: true, message: translate('entity.validation.required') },
                  }}
                />
              ) : (
                ''
              )}

              {deploymentType && deploymentType.toUpperCase() !== 'NONE'
                ? deploymentType.toUpperCase() === 'CLOUD' && deploymentCloud.toUpperCase() !== 'NONE' && <h5 className="domain-details"> Add Domain Details</h5>
                : ''}

              {deploymentType && deploymentType.toUpperCase() !== 'NONE'
                ? deploymentType.toUpperCase() === 'CLOUD' &&
                deploymentCloud.toUpperCase() !== 'NONE' && (
                  <ValidatedField
                    className="col-md-6"
                    label={translate('conductorMonolithApp.deployment.domainProvider')}
                    id="deployment-domainProvider"
                    name="domainProvider"
                    data-cy="domainProvider"
                    type="select"
                    onChange={onDomainProviderChange}
                  >
                    {domainProviderValues.map(domainProvider => (
                      <option value={DomainProvider[domainProvider]} key={DomainProvider[domainProvider]}>
                        {translate('conductorMonolithApp.DomainProvider.' + DomainProvider[domainProvider])}
                      </option>
                    ))}
                  </ValidatedField>
                )
                : ''}

              {(deploymentType && deploymentType.toUpperCase() !== 'NONE') || deploymentType.toUpperCase() !== 'SSH'
                ? deploymentCloud.toUpperCase() !== 'NONE' && (
                  <ValidatedField
                    className="col-md-6"
                    label={translate('conductorMonolithApp.deployment.domainName')}
                    id="deployment-domainName"
                    name="domainName"
                    data-cy="domainName"
                    // placeholder={'app.conductor.ai'}
                    type="text"
                    validate={{
                      required: { value: true, message: translate('entity.validation.required') },
                    }}
                  />
                )
                : ''}

              {(deploymentType && deploymentType.toUpperCase() !== 'NONE') || deploymentType.toUpperCase() !== 'SSH'
                ? domainProvider.toUpperCase() === 'CLOUDFLARE' &&
                deploymentCloud.toUpperCase() !== 'NONE' && (
                  <ValidatedField
                    className="col-md-6"
                    label={translate('conductorMonolithApp.deployment.cloudFlareToken')}
                    id="deployment-cloudFlareToken"
                    name="cloudFlareToken"
                    data-cy="cloudFlareToken"
                    type="text"
                  />
                )
                : ''}
              {(deploymentType && deploymentType.toUpperCase() !== 'NONE') || deploymentType.toUpperCase() !== 'SSH'
                ? domainProvider.toUpperCase() !== 'CLOUDFLARE' &&
                deploymentCloud.toUpperCase() !== 'NONE' && (
                  <ValidatedField
                    className="col-md-6"
                    label={translate('conductorMonolithApp.deployment.domainToken')}
                    id="deployment-domainToken"
                    name="domainToken"
                    data-cy="domainToken"
                    type="text"
                    validate={{
                      required: { value: true, message: translate('entity.validation.required') },
                    }}
                  />
                )
                : ''}

              {deploymentType && deploymentType.toUpperCase() !== 'NONE'
                ? domainProvider.toUpperCase() !== 'CLOUDFLARE' &&
                deploymentCloud.toUpperCase() !== 'NONE' && (
                  <ValidatedField
                    className="col-md-6"
                    label={translate('conductorMonolithApp.deployment.domainSecretKey')}
                    id="deployment-domainSecretKey"
                    name="domainSecretKey"
                    data-cy="domainSecretKey"
                    type="text"
                    validate={{
                      required: { value: true, message: translate('entity.validation.required') },
                    }}
                  />
                )
                : ''}


              {deploymentType && deploymentType !== 'NONE'
                ? deploymentType.toUpperCase() === 'SSH' && <h5 className="domain-details"> Add Database Details</h5>
                : ''}
              {deploymentType && deploymentType.toUpperCase() === 'SSH' && (
                <ValidatedField
                  className="col-md-6"
                  label={translate('conductorMonolithApp.deployment.dbName')}
                  id="deployment-dbName"
                  name="dbName"
                  data-cy="dbName"
                  type="text"

                />
              )}

              {deploymentType && deploymentType.toUpperCase() === 'SSH' && (
                <ValidatedField
                  className="col-md-6"
                  label={translate('conductorMonolithApp.deployment.dbUser')}
                  id="deployment-dbUser"
                  name="dbUser"
                  data-cy="dbUser"
                  type="text"

                />

              )}
              {deploymentType && deploymentType.toUpperCase() === 'SSH' && (
                <ValidatedField
                  className="col-md-6"
                  label={translate('conductorMonolithApp.deployment.dbPassword')}
                  id="deployment-dbPassword"
                  name="dbPassword"
                  data-cy="dbPassword"
                  type="text"

                />

              )}
              {deploymentType && deploymentType.toUpperCase() === 'SSH' && (
                <ValidatedField
                  className="col-md-6"
                  label={translate('conductorMonolithApp.deployment.dbHost')}
                  id="deployment-dbHost"
                  name="dbHost"
                  data-cy="dbHost"
                  type="text"

                />
              )}



              <Col md={'12'}>
                <Button
                  tag={Link}
                  id="cancel-save"
                  data-cy="entityCreateCancelButton"
                  to={`/product/${productId}/app/${appId}`}
                  replace
                  color="back"
                >
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button
                  color="save"
                  id="save-entity"
                  data-cy="entityCreateSaveButton"
                  type="submit"
                  disabled={updating || deploymentType.toUpperCase() === 'NONE' || (deploymentType.toUpperCase() === 'CLOUD' && deploymentCloud.toUpperCase() === 'NONE')}
                >
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </Col>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default DeploymentUpdate;
