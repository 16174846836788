import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import CoreEntityRelationship from './core-entity-relationship';
import CoreEntityRelationshipDetail from './core-entity-relationship-detail';
import CoreEntityRelationshipUpdate from './core-entity-relationship-update';
import CoreEntityRelationshipDeleteDialog from './core-entity-relationship-delete-dialog';

const CoreEntityRelationshipRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<CoreEntityRelationship />} />
    <Route path="new" element={<CoreEntityRelationshipUpdate />} />
    <Route path=":id">
      <Route index element={<CoreEntityRelationshipDetail />} />
      <Route path="edit" element={<CoreEntityRelationshipUpdate />} />
      <Route path="delete" element={<CoreEntityRelationshipDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default CoreEntityRelationshipRoutes;
