import product from 'app/entities/product/product.reducer';
import productUserRole from 'app/entities/product-user-role/product-user-role.reducer';
import app from 'app/entities/app/app.reducer';
import codeBlueprint from 'app/entities/code-blueprint/code-blueprint.reducer';
import appCodeBlueprint from 'app/entities/app-code-blueprint/app-code-blueprint.reducer';
import appScreen from 'app/entities/app-screen/app-screen.reducer';
import appAccessConfig from 'app/entities/app-access-config/app-access-config.reducer';
import functionality from 'app/entities/functionality/functionality.reducer';
import appEntity from 'app/entities/app-entity/app-entity.reducer';
import appEntityField from 'app/entities/app-entity-field/app-entity-field.reducer';
import entityRelationship from 'app/entities/entity-relationship/entity-relationship.reducer';
import deployment from 'app/entities/deployment/deployment.reducer';
import coreScreen from 'app/entities/core-screen/core-screen.reducer';
import coreAccessConfig from 'app/entities/core-access-config/core-access-config.reducer';
import coreFunctionality from 'app/entities/core-functionality/core-functionality.reducer';
import coreEntity from 'app/entities/core-entity/core-entity.reducer';
import coreEntityField from 'app/entities/core-entity-field/core-entity-field.reducer';
import coreEntityRelationship from 'app/entities/core-entity-relationship/core-entity-relationship.reducer';
import emailConfiguration from 'app/entities/email-configuration/email-configuration.reducer';
import productUser from 'app/entities/product-user/product-user.reducer';
import enumMember from 'app/entities/enum-member/enum-member.reducer';
import type from 'app/entities/type/type.reducer';
import teamMember from 'app/entities/team-member/team-member.reducer';
import screenComponent from 'app/entities/screen-component/screen-component.reducer';
import vaultConfig from 'app/entities/vault-config/vault-config.reducer';
import dependency from 'app/entities/dependency/dependency.reducer';
import chatGptSearch from 'app/entities/chatgpt/chatgpt-search.reducer';
import appDependency from 'app/entities/app-dependency/app-dependency.reducer';
import productUserRoleAccess from 'app/entities/product-user-role-access/product-user-role-access.reducer';
import feature from 'app/entities/feature/feature.reducer'
import job from 'app/entities/job/job.reducer'
import aiSuggestor from 'app/entities/ai-suggestor/ai-suggestor.reducer';
import appEndPointController from 'app/entities/app-end-point-controller/app-end-point-controller.reducer'
import appEndPoint from 'app/entities/app-end-point/app-end-point.reducer'
import coreFunctionalityDependency from 'app/entities/core-functionality-dependency/core-functionality-dependency.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const entitiesReducers = {
  product,
  productUserRole,
  app,
  codeBlueprint,
  appCodeBlueprint,
  appScreen,
  appAccessConfig,
  functionality,
  appEntity,
  appEntityField,
  entityRelationship,
  deployment,
  coreScreen,
  coreAccessConfig,
  coreFunctionality,
  coreEntity,
  coreEntityField,
  coreEntityRelationship,
  emailConfiguration,
  productUser,
  enumMember,
  type,
  teamMember,
  screenComponent,
  vaultConfig,
  dependency,
  chatGptSearch,
  appDependency,
  productUserRoleAccess,
  feature,
  job,
  aiSuggestor,
  appEndPointController,
  appEndPoint,
  coreFunctionalityDependency
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
};

export default entitiesReducers;
