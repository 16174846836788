import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import AppEntity from './app-entity';
import AppEntityDetail from './app-entity-detail';
import AppEntityUpdate from './app-entity-update';
import AppEntityDeleteDialog from './app-entity-delete-dialog';

const AppEntityRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<AppEntity />} />
    <Route path="/:appId/new" element={<AppEntityUpdate />} />
    <Route path=":appId/:id">
      <Route index element={<AppEntityDetail />} />
      <Route path="edit" element={<AppEntityUpdate />} />
    </Route>
    <Route path=':appId'>
      <Route path="" element={<AppEntityDeleteDialog />} />
      </Route>
  </ErrorBoundaryRoutes>
);

export default AppEntityRoutes;
