import React from 'react';
import { Translate } from 'react-jhipster';

import MenuItem from 'app/shared/layout/menus/menu-item';

const EntitiesMenu = () => {
  return (
    <>
      {/* prettier-ignore */}
      <MenuItem icon="asterisk" to="/product">
        <Translate contentKey="global.menu.entities.product" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/product-user-role">
        <Translate contentKey="global.menu.entities.productUserRole" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/app">
        <Translate contentKey="global.menu.entities.app" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/code-blueprint">
        <Translate contentKey="global.menu.entities.codeBlueprint" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/app-code-blueprint">
        <Translate contentKey="global.menu.entities.appCodeBlueprint" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/app-screen">
        <Translate contentKey="global.menu.entities.appScreen" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/app-access-config">
        <Translate contentKey="global.menu.entities.appAccessConfig" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/functionality">
        <Translate contentKey="global.menu.entities.functionality" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/app-entity">
        <Translate contentKey="global.menu.entities.appEntity" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/app-entity-field">
        <Translate contentKey="global.menu.entities.appEntityField" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/entity-relationship">
        <Translate contentKey="global.menu.entities.entityRelationship" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/deployment">
        <Translate contentKey="global.menu.entities.deployment" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/core-screen">
        <Translate contentKey="global.menu.entities.coreScreen" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/core-access-config">
        <Translate contentKey="global.menu.entities.coreAccessConfig" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/core-functionality">
        <Translate contentKey="global.menu.entities.coreFunctionality" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/core-entity">
        <Translate contentKey="global.menu.entities.coreEntity" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/core-entity-field">
        <Translate contentKey="global.menu.entities.coreEntityField" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/core-entity-relationship">
        <Translate contentKey="global.menu.entities.coreEntityRelationship" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/email-configuration">
        <Translate contentKey="global.menu.entities.emailConfiguration" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/product-user">
        <Translate contentKey="global.menu.entities.productUser" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/enum-member">
        <Translate contentKey="global.menu.entities.enumMember" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/type">
        <Translate contentKey="global.menu.entities.type" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/team-member">
        <Translate contentKey="global.menu.entities.teamMember" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/screen-component">
        <Translate contentKey="global.menu.entities.screenComponent" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/vault-config">
        <Translate contentKey="global.menu.entities.vaultConfig" />
      </MenuItem>
      {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
    </>
  );
};

export default EntitiesMenu;
