import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col, FormGroup, Input } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './app-dependency.reducer';

export const AppDependencyDetail = () => {
  const dispatch = useAppDispatch();

  const { productId } = useParams<'productId'>();
  const { appId } = useParams<'appId'>();
  const { appDependencyId } = useParams<'appDependencyId'>();
  const appDependencyEntity = useAppSelector(state => state.appDependency.entity);

  useEffect(() => {
    dispatch(getEntity(appDependencyId));
  }, []);

  return (
    <div className="inner-content-wrap">
      <Row>
        <Col md="12">
          <h2 className="row  page-title" data-cy="appDependencyDetailsHeading">
            <Translate contentKey="conductorMonolithApp.appDependency.detail.title">AppDependency</Translate>
          </h2>
          <div className="jh-entity-details">
            {/* <Col md={6}>
              <FormGroup>
                <span id="id">
                  <Translate contentKey="global.field.id">ID</Translate>
                </span>
                <Input value={appDependencyEntity.id} disabled />
              </FormGroup>
            </Col> */}

            <Col md={6}>
              <FormGroup>
                <span id="version">
                  <Translate contentKey="conductorMonolithApp.appDependency.version"> version</Translate>
                </span>
                <Input value={appDependencyEntity.version} disabled />
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <span id="declaration">
                  <Translate contentKey="conductorMonolithApp.appDependency.dependency">dependency</Translate>
                </span>
                <Input value={appDependencyEntity.dependency ? appDependencyEntity.dependency.packageName : ''} disabled />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <span id="id">
                  <Translate contentKey="conductorMonolithApp.appDependency.app">app</Translate>
                </span>
                <Input value={appDependencyEntity.app ? appDependencyEntity.app.name : ''} disabled />
              </FormGroup>
            </Col>
          </div>
          <Col md={4}>
            <Button tag={Link} to={`/product/${productId}/app/${appId}`} replace color="info" data-cy="entityDetailsBackButton">
              <FontAwesomeIcon icon="arrow-left" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.back">Back</Translate>
              </span>
            </Button>
            &nbsp;
            {/* <Button tag={Link} to={`/product/${productId}/app/${appId}/app-dependency/${appDependencyEntity.id}/edit`} replace color="primary">
              <FontAwesomeIcon icon="pencil-alt" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.edit">Edit</Translate>
              </span>
            </Button> */}
          </Col>
        </Col>
      </Row>
    </div>
  );
};

export default AppDependencyDetail;
