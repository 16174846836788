import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText, Label } from 'reactstrap';
import { isNumber, getSortState, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IDependency } from 'app/shared/model/dependency.model';
import { getEntities as getDependencies } from 'app/entities/dependency/dependency.reducer';
import { IApp } from 'app/shared/model/app.model';
import { getEntities as getApps, getEntity as getApp } from 'app/entities/app/app.reducer';
import { IAppDependency } from 'app/shared/model/app-dependency.model';
import { getEntity, updateEntity, createEntity, reset } from './app-dependency.reducer';
import { getEntity as getDependency } from 'app/entities/dependency/dependency.reducer';
import DynamicSelect from 'app/modules/dynamicSelectComponent/DynamicSelect';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ITEMS_PER_PAGE, DESC } from 'app/shared/util/pagination.constants';

export const AppDependencyUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { productId } = useParams<'productId'>();
  const { appId } = useParams<'appId'>();
  const { appDependencyId } = useParams<'appDependencyId'>();
  const isNew = appDependencyId === undefined;

  const dependencies = useAppSelector(state => state.dependency.entities);
  const app = useAppSelector(state => state.app.entity);
  const apps = useAppSelector(state => state.app.entities);
  const appDependencyEntity = useAppSelector(state => state.appDependency.entity);

  const loading = useAppSelector(state => state.appDependency.loading);
  const updating = useAppSelector(state => state.appDependency.updating);
  const updateSuccess = useAppSelector(state => state.appDependency.updateSuccess);
  const dependencyEntity = useAppSelector(state => state.dependency.entity);
  const [selectDependency, setSelectDependency] = useState({});
  const [appDependencyVersion, setAppDependencyVersion] = useState('');
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id', DESC), location.search)
  );

  const handleClose = () => {
    navigate(`/product/${productId}/app/${appId}` + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(appDependencyId));
    }

    dispatch(getApp(appId));
    dispatch(
      getDependencies({
        page: paginationState.activePage - 1,
        size: 25,
        sort: `${paginationState.sort},${paginationState.order}`,
        query: `appId.equals=${appId}`,
      })
    );
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
      setSelectDependency({});
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (!isNew && appDependencyEntity?.dependency?.id) {
      setSelectDependency(appDependencyEntity?.dependency);
    }

    if (!isNew && appDependencyEntity?.version) {
      setAppDependencyVersion(appDependencyEntity.version);
    }
  }, [appDependencyEntity]);
  const saveEntity = values => {
    const entity = {
      ...appDependencyEntity,
      ...values,
      dependency: selectDependency,
      app: app,
      version: appDependencyVersion,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...appDependencyEntity,
          dependency: appDependencyEntity?.dependency,
          app: appDependencyEntity?.app?.id,
        };

  // const handleDependencyChange = async e => {
  //   if (e.target.value) {
  //     setSelectDependency(e.target.value);
  //     // await dispatch(getDependency(e.target.value));
  //   }
  // };
  const handleEntityFromChange = e => {
    var entity = dependencies.find(it => it.id.toString() === e.value.toString());
    if (entity) {
      setSelectDependency(entity);
      // await dispatch(getDependency(e.target.value));
    }
  };

  const handleAppDependencyVersionChange = e => {
    setAppDependencyVersion(e.target.value);
  };

  return (
    <div className="inner-content-wrap">
      <Row className="justify-content-center">
        <Col md="12">
          <h2 id="conductorMonolithApp.appDependency.home.createOrEditLabel" data-cy="AppDependencyCreateUpdateHeading">
            {/* <Translate contentKey="conductorMonolithApp.appDependency.home.createOrEditLabel">Create or edit a AppDependency</Translate> */}
            <Label>{isNew ? `Add` : `Manage`} AppDependency</Label>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="12">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  className="col-md-6"
                  name="id"
                  required
                  readOnly
                  id="app-dependency-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              {/* <ValidatedField
                id="app-dependency-dependency"
                name="selectDependency"
                data-cy="selectDependency"
                label={translate('conductorMonolithApp.appDependency.dependency')}
                type="select"
                value={selectDependency}
                onChange={handleDependencyChange}
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <option value="">Select One</option>
                {dependencies
                  ? dependencies.map(dependency => (
                      <option value={dependency.id} key={dependency.id} selected>
                        {dependency.packageName}
                      </option>
                    ))
                  : null}
              </ValidatedField> */}
              <div id="app-dependency-dependency" className="col-md-6">
                {translate('conductorMonolithApp.appDependency.dependency')}
                <DynamicSelect
                  data-cy="selectDependency"
                  changeFxn={handleEntityFromChange}
                  callbackFXN={getDependencies}
                  paginateState={paginationState}
                  enitityData={dependencies}
                  callbackFxnProps={{
                    page: paginationState.activePage,
                    size: 25,
                    sort: `${paginationState.sort},${paginationState.order}`,
                    query: !!appId ? `appId.equals=${appId}` : '',
                  }}
                  setPageState={setPaginationState}
                  entity={'dependency'}
                  name="selectDependency"
                  defaultValue={selectDependency}
                  label={translate('conductorMonolithApp.appDependency.dependency')}
                />
              </div>
              <ValidatedField
                className="col-md-6"
                label={translate('conductorMonolithApp.appDependency.version')}
                id="app-dependency-version"
                name="appDependencyVersion"
                data-cy="appDependencyVersion"
                type="text"
                onChange={handleAppDependencyVersionChange}
                value={appDependencyVersion}
              />
              <Button
                tag={Link}
                id="cancel-save"
                data-cy="entityCreateCancelButton"
                to={`/product/${productId}/app/${appId}`}
                replace
                color="info"
              >
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default AppDependencyUpdate;
