import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Col, Table } from 'reactstrap';
import { Translate, getSortState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities } from './deployment.reducer';

export const Deployment = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id', DESC), location.search)
  );

  const deploymentList = useAppSelector(state => state.deployment.entities);
  const loading = useAppSelector(state => state.deployment.loading);
  const totalItems = useAppSelector(state => state.deployment.totalItems);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  return (
    <>
      <div className="inner-content-wrap" id='deployment'>
        <h2 id="deployment-heading" data-cy="DeploymentHeading" className="row mb-4 mt-3">
          <Col md="12">
            <div className="d-flex justify-content-end">
              {/* <Button className="me-2" color="refresh" onClick={handleSyncList} disabled={loading}>
                <FontAwesomeIcon icon="sync" spin={loading} />{' '}
                <Translate contentKey="conductorMonolithApp.deployment.home.refreshListLabel">Refresh List</Translate>
              </Button> */}
              <Link to="/deployment/new" className="btn btn-save jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
                <FontAwesomeIcon icon="plus" />
                &nbsp;
                <Translate contentKey="conductorMonolithApp.deployment.home.createLabel">Create new Deployment</Translate>
              </Link>
            </div>
          </Col>
        </h2>
        <div>
          {deploymentList && deploymentList.length > 0 ? (
            <Table responsive>
              <thead className="table-primary">
                <tr>
                  <th className="hand" onClick={sort('deploymentType')}>
                    <Translate contentKey="conductorMonolithApp.deployment.deploymentType">Deployment Type</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('enabled')}>
                    <Translate contentKey="conductorMonolithApp.deployment.enabled">Enabled</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('envName')}>
                    <Translate contentKey="conductorMonolithApp.deployment.envName">Env Name</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('domainName')}>
                    <Translate contentKey="conductorMonolithApp.deployment.domainName">Domain Name</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('credentials')}>
                    <Translate contentKey="conductorMonolithApp.deployment.credentials">Credentials</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('resourceName')}>
                    <Translate contentKey="conductorMonolithApp.deployment.resourceName">Resource Name</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('resourcePath')}>
                    <Translate contentKey="conductorMonolithApp.deployment.resourcePath">Resource Path</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('appsFolders')}>
                    <Translate contentKey="conductorMonolithApp.deployment.appsFolders">Apps Folders</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('dockerRepositoryName')}>
                    <Translate contentKey="conductorMonolithApp.deployment.dockerRepositoryName">Docker Repository Name</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th>
                    <Translate contentKey="conductorMonolithApp.deployment.product">Product</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {deploymentList.map((deployment, i) => (
                  <tr key={`entity-${i}`} data-cy="entityTable">
                    <td>{deployment.deploymentType}</td>
                    <td>{deployment.enabled ? 'true' : 'false'}</td>
                    <td>{deployment.envName}</td>
                    <td>{deployment.domainName}</td>
                    <td>{deployment.credentials}</td>
                    <td>{deployment.resourceName}</td>
                    <td>{deployment.resourcePath}</td>
                    <td>{deployment.appsFolders}</td>
                    <td>{deployment.dockerRepositoryName}</td>
                    <td>{deployment.product ? <Link to={`/product/${deployment.product.id}`}>{deployment.product.name}</Link> : ''}</td>
                    <td className="text-end">
                      <div className="btn-group flex-btn-group-container">
                        <Button tag={Link} to={`/deployment/${deployment.id}`} color="primary" size="sm" data-cy="entityDetailsButton">
                          <FontAwesomeIcon icon="eye" />{' '}
                          <span className="d-none">
                            <Translate contentKey="entity.action.view">View</Translate>
                          </span>
                        </Button>
                        <Button
                          tag={Link}
                          to={`/deployment/${deployment.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                          color="warning"
                          size="sm"
                          data-cy="entityEditButton"
                        >
                          <FontAwesomeIcon icon="pencil-alt" />{' '}
                          <span className="d-none">
                            <Translate contentKey="entity.action.edit">Edit</Translate>
                          </span>
                        </Button>
                        <Button
                          tag={Link}
                          to={`/deployment/${deployment.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                          color="danger"
                          size="sm"
                          data-cy="entityDeleteButton"
                        >
                          <FontAwesomeIcon icon="trash" />{' '}
                          <span className="d-none">
                            <Translate contentKey="entity.action.delete">Delete</Translate>
                          </span>
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            !loading && (
              <div className="alert alert-warning">
                <Translate contentKey="conductorMonolithApp.deployment.home.notFound">No Deployments found</Translate>
              </div>
            )
          )}
        </div>
        {totalItems ? (
          <div className={deploymentList && deploymentList.length > 0 ? '' : 'd-none'}>
            <div className="justify-content-center d-flex">
              <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
            </div>
            <div className="justify-content-center d-flex">
              <JhiPagination
                activePage={paginationState.activePage}
                onSelect={handlePagination}
                maxButtons={5}
                itemsPerPage={paginationState.itemsPerPage}
                totalItems={totalItems}
              />
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default Deployment;
