import dayjs from 'dayjs';
import { IDependency } from 'app/shared/model/dependency.model';
import { IApp } from 'app/shared/model/app.model';

export interface IAppDependency {
  id?: number;
  version?: string | null;
  lastSyncTimestamp?: string | null;
  dependency?: IDependency | null;
  app?: IApp | null;
}

export const defaultValue: Readonly<IAppDependency> = {};
