import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText, Label } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IApp } from 'app/shared/model/app.model';
import { getEntities as getApps } from 'app/entities/app/app.reducer';
import { IJob } from 'app/shared/model/job.model';
import { JobType } from 'app/shared/model/enumerations/job-type.model';
import { JobScheduleType } from 'app/shared/model/enumerations/job-schedule-type.model';
import { getEntity, updateEntity, createEntity, reset } from './job.reducer';
import {ITEMS_PER_PAGE} from 'app/shared/util/pagination.constants';

export const JobUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { jobId } = useParams<'jobId'>();
  const id = jobId;
  const { productId } = useParams<'productId'>();
  const { appId } = useParams<'appId'>();

  const isNew = id === undefined;

  const apps = useAppSelector(state => state.app?.entities);
  const jobEntity = useAppSelector(state => state.job?.entity);
  const loading = useAppSelector(state => state.job?.loading);
  const updating = useAppSelector(state => state.job?.updating);
  const updateSuccess = useAppSelector(state => state.job?.updateSuccess);
  const jobTypeValues = Object.keys(JobType);
  const jobScheduleTypeValues = Object.keys(JobScheduleType);

  const handleClose = () => {
    navigate(`/product/${productId}/app/${appId}`);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getApps({sort: 'id,asc&id.equals='+appId}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    debugger
    const entity = {
      ...jobEntity,
      ...values,
      app: apps.find(it => it.id.toString() === appId.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          type: 'INSTANT',
          scheduleType: 'CRON',
          ...jobEntity,
          app: jobEntity?.app?.id,
        };

  return (
    <div className="inner-content-wrap">
      <Row className="justify-content-center">
        <Col md="12">
          <h2 id="conductorMonolithApp.job.home.createOrEditLabel" data-cy="JobCreateUpdateHeading">
            <Label>{isNew ? `Add` : ` Manage`} App Job</Label>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="12">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity} className="row">
              {!isNew ? (
                <ValidatedField
                  className="col-md-6"
                  name="id"
                  required
                  readOnly
                  id="job-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                className="col-md-6"
                label={translate('conductorMonolithApp.job.name')}
                id="job-name"
                name="name"
                data-cy="name"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                className="col-md-6"
                label={translate('conductorMonolithApp.job.type')}
                id="job-type"
                name="type"
                data-cy="type"
                type="select"
              >
                {jobTypeValues.map(jobType => (
                  <option value={jobType} key={jobType}>
                    {translate('conductorMonolithApp.JobType.' + jobType)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                className="col-md-6"
                label={translate('conductorMonolithApp.job.taskExecutor')}
                id="job-taskExecutor"
                name="taskExecutor"
                data-cy="taskExecutor"
                type="text"
              />
              <ValidatedField
                className="col-md-6"
                label={translate('conductorMonolithApp.job.scheduleType')}
                id="job-scheduleType"
                name="scheduleType"
                data-cy="scheduleType"
                type="select"
              >
                {jobScheduleTypeValues.map(jobScheduleType => (
                  <option value={jobScheduleType} key={jobScheduleType}>
                    {translate('conductorMonolithApp.JobScheduleType.' + jobScheduleType)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                className="col-md-6"
                label={translate('conductorMonolithApp.job.scheduleConfig')}
                id="job-scheduleConfig"
                name="scheduleConfig"
                data-cy="scheduleConfig"
                type="text"
              />
              <ValidatedField
                className="col-md-6"
                label={translate('conductorMonolithApp.job.queueName')}
                id="job-queueName"
                name="queueName"
                data-cy="queueName"
                type="text"
              />
              <ValidatedField
                className="col-md-6"
                label={translate('conductorMonolithApp.job.description')}
                id="job-description"
                name="description"
                data-cy="description"
                type="text"
              />
              <ValidatedField
                className="col-md-6"
                label={translate('conductorMonolithApp.job.pseudoCode')}
                id="job-pseudoCode"
                name="pseudoCode"
                data-cy="pseudoCode"
                type="text"
              />
              {/* <ValidatedField id="job-app" name="app" data-cy="app" label={translate('conductorMonolithApp.job.app')} type="select">
                <option value="" key="0" />
                {apps
                  ? apps.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name}
                      </option>
                    ))
                  : null}
              </ValidatedField> */}
              <Col md={12}>
                <Button
                tag={Link}
                id="cancel-save"
                data-cy="entityCreateCancelButton"
                to={`/product/${productId}/app/${appId}`}
                replace
                color="back"
              >
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
              </Col>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default JobUpdate;
