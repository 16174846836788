import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Product from './product';
import ProductUserRole from './product-user-role';
import App from './app';
import CodeBlueprint from './code-blueprint';
import AppCodeBlueprint from './app-code-blueprint';
import AppScreen from './app-screen';
import AppAccessConfig from './app-access-config';
import Functionality from './functionality';
import AppEntity from './app-entity';
import AppEntityField from './app-entity-field';
import EntityRelationship from './entity-relationship';
import Deployment from './deployment';
import CoreScreen from './core-screen';
import CoreAccessConfig from './core-access-config';
import CoreFunctionality from './core-functionality';
import CoreEntity from './core-entity';
import CoreEntityField from './core-entity-field';
import CoreEntityRelationship from './core-entity-relationship';
import ProductAllFeatureRoutes from './productAllFeatures';
// import AppScreenEntity from './app-screen-entity/app-screen-entity';
import EmailConfiguration from './email-configuration';
import ProductUser from './product-user';
import EnumMember from './enum-member';
import Type from './type';
import TeamMember from './team-member';
import ScreenComponent from './screen-component';
import VaultConfig from './vault-config';
import Dependency from 'app/entities/dependency';
import AppUpdate from './app/app-update';
import ProductUpdate from './product/product-update';
import ProductDetail from './product/product-detail';
/* jhipster-needle-add-route-import - JHipster will add routes here */

export default () => {
  return (
    <div>
      <ErrorBoundaryRoutes>
        {/* prettier-ignore */}
        <Route path="product">
          <Route index element={<Product />} />
          <Route path="new" element={<ProductUpdate />} />
          <Route path=":id/*">
            <Route index element={<ProductDetail />} />
            <Route path="edit" element={<ProductUpdate />} />
          </Route>
            <Route path=":productId/app">
              <Route path="new" element={<AppUpdate />} />
              <Route path=":appId/*" element={<ProductAllFeatureRoutes/>}/>
            </Route>
        </Route>
        <Route path="product-user-role/*" element={<ProductUserRole />} />
        <Route path="app/*" element={<App />} />
        <Route path="code-blueprint/*" element={<CodeBlueprint />} />
        <Route path="app-code-blueprint/*" element={<AppCodeBlueprint />} />
        <Route path="app-screen/*" element={<AppScreen />} />
        <Route path="app-access-config/*" element={<AppAccessConfig />} />
        <Route path="functionality/*" element={<Functionality />} />
        <Route path="app-entity/*" element={<AppEntity />} />
        <Route path="app-entity-field/*" element={<AppEntityField />} />
        <Route path="entity-relationship/*" element={<EntityRelationship />} />
        <Route path="deployment/*" element={<Deployment />} />
        <Route path="core-screen/*" element={<CoreScreen />} />
        <Route path="core-access-config/*" element={<CoreAccessConfig />} />
        <Route path="core-functionality/*" element={<CoreFunctionality />} />
        <Route path="core-entity/*" element={<CoreEntity />} />
        <Route path="core-entity-field/*" element={<CoreEntityField />} />
        <Route path="core-entity-relationship/*" element={<CoreEntityRelationship />} />
        <Route path="email-configuration/*" element={<EmailConfiguration />} />
        <Route path="product-user/*" element={<ProductUser />} />
        <Route path="enum-member/*" element={<EnumMember />} />
        <Route path="type/*" element={<Type />} />
        <Route path="team-member/*" element={<TeamMember />} />
        <Route path="screen-component/*" element={<ScreenComponent />} />
        <Route path="vault-config/*" element={<VaultConfig />} />
        <Route path="dependency/*" element={<Dependency />} />
        {/* jhipster-needle-add-route-path - JHipster will add routes here */}
      </ErrorBoundaryRoutes>
    </div>
  );
};
