import { ICoreEnumMember } from 'app/shared/model/core-enum-member.model';
import { ICoreEntityRelationship } from 'app/shared/model/core-entity-relationship.model';
import { ICoreScreenComponent } from 'app/shared/model/core-screen-component.model';
import { ICoreEntityField } from 'app/shared/model/core-entity-field.model';
import { IAppEntity } from 'app/shared/model/app-entity.model';
import { ICoreFunctionality } from 'app/shared/model/core-functionality.model';
import { EntityType } from 'app/shared/model/enumerations/entity-type.model';

export interface ICoreEntity {
  id?: number;
  name?: string;
  userId?: number | null;
  description?: string | null;
  type?: EntityType | null;
  coreEnumMembers?: ICoreEnumMember[] | null;
  coreEntityRelationshipFroms?: ICoreEntityRelationship[] | null;
  coreScreenComponents?: ICoreScreenComponent[] | null;
  coreEntityRelationshipTos?: ICoreEntityRelationship[] | null;
  coreEntityFields?: ICoreEntityField[] | null;
  appEntities?: IAppEntity[] | null;
  coreFunctionality?: ICoreFunctionality | null;
}

export const defaultValue: Readonly<ICoreEntity> = {};
