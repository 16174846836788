import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import AppAccessConfig from './app-access-config';
import AppAccessConfigDetail from './app-access-config-detail';
import AppAccessConfigUpdate from './app-access-config-update';
import AppAccessConfigDeleteDialog from './app-access-config-delete-dialog';

const AppAccessConfigRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<AppAccessConfig />} />
    <Route path="new" element={<AppAccessConfigUpdate />} />
    <Route path=":id">
      <Route index element={<AppAccessConfigDetail />} />
      <Route path="edit" element={<AppAccessConfigUpdate />} />
      <Route path="delete" element={<AppAccessConfigDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default AppAccessConfigRoutes;
