import { IAppDependency } from 'app/shared/model/app-dependency.model';
import { DependencyType } from 'app/shared/model/enumerations/dependency-type.model';

export interface IDependency {
  id?: number;
  packageName?: string;
  declarations?: string | null;
  minVersion?: string | null;
  type?: DependencyType | null;
  maxVersion?: string | null;
  appDependencies?: IAppDependency[] | null;
}

export const defaultValue: Readonly<IDependency> = {};
