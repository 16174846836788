import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import EnumMember from './enum-member';
import EnumMemberDetail from './enum-member-detail';
import EnumMemberUpdate from './enum-member-update';
import EnumMemberDeleteDialog from './enum-member-delete-dialog';

const EnumMemberRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<EnumMember />} />
    {/* <Route path="app-entity-view/:entityID/new-enum" element={<EnumMemberUpdate />} /> */}
    <Route path="new" element={<EnumMemberUpdate />} />
    <Route path=":enumId">
      <Route path="view" element={<EnumMemberDetail />} />
      <Route path="delete" element={<EnumMemberDeleteDialog />} />
      <Route path="edit" element={<EnumMemberUpdate />} />
    
    </Route>
      
  </ErrorBoundaryRoutes>
);

export default EnumMemberRoutes;
