import { IApp } from 'app/shared/model/app.model';
import { IFunctionality } from 'app/shared/model/functionality.model';
import { IFeature } from 'app/shared/model/feature.model';

export interface IAppAccessConfig {
  id?: number;
  pathPattern?: string;
  reason?: string | null;
  accessRoles?: string | null;
  enabled?: boolean | null;
  userId?: number | null;
  app?: IApp | null;
  functionality?: IFunctionality | null;
  feature?: IFeature | null;
}

export const defaultValue: Readonly<IAppAccessConfig> = {
  enabled: false,
};
