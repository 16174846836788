import dayjs from 'dayjs';
import { IAppEntity } from 'app/shared/model/app-entity.model';

export interface IEnumMember {
  id?: number;
  name?: string;
  lastSyncTimestamp?: string | null;
  userId?: number | null;
  description?: string | null;
  appEntity?: IAppEntity | null;
}

export const defaultValue: Readonly<IEnumMember> = {};
