import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col, FormGroup, Input } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './enum-member.reducer';

export const EnumMemberDetail = () => {
  const dispatch = useAppDispatch();

  const { enumId } = useParams<'enumId'>();
  const {id} =  useParams <'id'>()
  const { productId } = useParams<'productId'>();
  const { appId } = useParams<'appId'>();
  const { appEntityId } = useParams<'appEntityId'>();
  const { appEntityEnumId } = useParams<'appEntityEnumId'>();

  useEffect(() => {
    dispatch(getEntity(appEntityEnumId));
  }, []);


  const enumMemberEntity = useAppSelector(state => state.enumMember.entity);
  console.log(enumMemberEntity)
  return (
    <div className='inner-content-wrap'>
    <Row >
      <Col md="8">
        <h2 className="row  page-title" data-cy="enumMemberDetailsHeading">
          <Translate contentKey="conductorMonolithApp.enumMember.detail.title">EnumMember</Translate>
        </h2>
        <dl className="jh-entity-details">
          {/* <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{enumMemberEntity.id}</dd> */}
           <Col md ={6}>
          <FormGroup>
            <span id="name">
              <Translate contentKey="conductorMonolithApp.enumMember.name">Name</Translate>
            </span>
            <Input value={enumMemberEntity.name}/>
            </FormGroup>
          </Col>

          {/* <dt>
            <span id="lastSyncTimestamp">
              <Translate contentKey="conductorMonolithApp.enumMember.lastSyncTimestamp">Last Sync Timestamp</Translate>
            </span>
          </dt>
          <dd>
            {enumMemberEntity.lastSyncTimestamp ? (
              <TextFormat value={enumMemberEntity.lastSyncTimestamp} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd> */}
          {/* <dt>
            <Translate contentKey="conductorMonolithApp.enumMember.appEntity">App Entity</Translate>
          </dt>
          <dd>{enumMemberEntity.appEntity ? enumMemberEntity.appEntity.name : ''}</dd> */}
        </dl>
        <Button tag={Link}
         to={`/product/${productId}/app/${appId}/app-entity/${appEntityId}/view`}
         replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        {/* <Button tag={Link} to={`/enum-member/${enumMemberEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button> */}
      </Col>
    </Row>
    </div>
  );
};

export default EnumMemberDetail;
