import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';
import { Translate, getSortState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IAppEntityField } from 'app/shared/model/app-entity-field.model';
import { getEntities, createEntity as createAppEntityField } from 'app/entities/app-entity-field/app-entity-field.reducer';
import AppEntityFieldDeleteDialog from 'app/entities/app-entity-field/app-entity-field-delete-dialog';

import { suggestEntityFields } from 'app/entities/ai-suggestor/ai-suggestor.reducer';
import { getEntity as getAppEntity } from 'app/entities/app-entity/app-entity.reducer';
// import { getEntities as getTypes } from 'app/entities/type/type.reducer';
// import { getEntities } from './app-entity-field.reducer';

export const AppEntityField = (props) => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const { productId } = useParams<'productId'>();
  const { appId } = useParams<'appId'>();
  const { appEntityId } = useParams<'appEntityId'>();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );
  var [deleteId, setDeleteId] = useState("")
  const [showModal, setShowModal] = useState(false)

  const appEntityFields = useAppSelector(state => state.appEntityField.entities);
  const loading = useAppSelector(state => state.appEntityField.loading);
  const totalItems = useAppSelector(state => state.appEntityField.totalItems);
  const appEntity = useAppSelector(state => state.appEntity.entity);
  
  const [appEntityFieldList, setAppEntityFieldList] = useState<any>();
  const [aiSuggestLoading, setAiSuggestLoading] = useState<boolean>(false);

  useEffect(() => {
    setAppEntityFieldList(appEntityFields);
    dispatch(getAppEntity(appEntityId));
  },[appEntityFields]);

  const getAllEntities = () => {
    
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query: `appEntityId.equals=${appEntityId}`,
      })
    );
      };

  const sortEntities = () => {
    getAllEntities();
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const handleDelete = (functionality_id) => {
    setShowModal(true)
    setDeleteId(functionality_id)
    deleteId = functionality_id;
  }



  const viewModal = (modal) => {
    setShowModal(modal)
  }
  const handleAiSuggest = async() => {
    setAiSuggestLoading(true);
    const {payload} = await dispatch(suggestEntityFields(props));
    let result = payload['data']
    let temp_result = [];
    result.map(ele => {
      let temp_obj = {...ele, key: `${ele.name}-${Date.now()}`}
      temp_result.push(temp_obj);
    })

    let temp_arr = appEntityFieldList.concat(temp_result)
    
    const distinctArray = temp_arr.reduce((accumulator, current) => {
      const found = accumulator.find(item => item.name === current.name || "id" === current.name);
      if (!found) {
        accumulator.push(current);
      }
      return accumulator;
    }, []);
    setAiSuggestLoading(false);
    setAppEntityFieldList(distinctArray);
  }

  const handleOnSaveForSuggested = async(data) => {
    const {payload} =  await dispatch(createAppEntityField({name: data.name, description: data.description, type: 'String', appEntity: appEntity}))
    console.log(payload);
    let ele = document.getElementById('entity-key-'+data.key);
    ele.parentNode.removeChild(ele);
    let temp_arr = appEntityFieldList.concat([payload['data']]);

    if(payload['data']){setAppEntityFieldList(temp_arr)}
  }

  const handleSuggestedDelete = (data) => {
    let ele = document.getElementById('entity-key-'+data.key);
    ele.parentNode.removeChild(ele);
  }

  return (
    <>
      <div className='inner-content-wrap'>
        <h2 className="row mb-4 mt-3" id="app-entity-field-heading" data-cy="AppEntityFieldHeading">
          <Translate contentKey="conductorMonolithApp.appEntityField.home.title">App Entity Fields</Translate>
          <div className="d-flex justify-content-end">
          <Button className="me-2" color="refresh" onClick={handleAiSuggest} disabled={loading}>
              <FontAwesomeIcon icon="magic-wand-sparkles" spin={loading || aiSuggestLoading} />{' '}
              AI Suggest
            </Button>
            <Button className="me-2" color="refresh" onClick={handleSyncList} disabled={loading}>
              <FontAwesomeIcon icon="sync" spin={loading} />{' '}
              <Translate contentKey="conductorMonolithApp.appEntityField.home.refreshListLabel">Refresh List</Translate>
            </Button>
            <Link to={`/product/${productId}/app/${appId}/app-entity/${appEntityId}/view/app-entity-field/new`} className="btn btn-save jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
              <FontAwesomeIcon icon="plus" />
              &nbsp;
              <Translate contentKey="conductorMonolithApp.appEntityField.home.createLabel">Add Entity Field</Translate>
            </Link>
          </div>
        </h2>
        {appEntityFieldList && appEntityFieldList.length > 0 ? (
          <div className="table-responsive">
            <Table responsive>
              <thead className="table-primary">
                <tr>
                  <th className="hand" onClick={sort('name')}>
                    <Translate contentKey="conductorMonolithApp.appEntityField.name">Name</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('type')}>
                    <Translate contentKey="conductorMonolithApp.appEntityField.type">Type</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('isRequired')}>
                  <Translate contentKey="conductorMonolithApp.appEntityField.isRequired">Required</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('description')}>
                    <Translate contentKey="conductorMonolithApp.appEntityField.description">Description</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  {/* <th>
                    <Translate contentKey="conductorMonolithApp.appEntityField.appEntity">App Entity</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th> */}
                  <th />
                </tr>
              </thead>
              <tbody>
                {appEntityFieldList.map((appEntityField, i) => (
                  <tr key={`entity-${i}`} id={`entity-key-${appEntityField.id || appEntityField.key}`} data-cy="entityTable">

                    <td>{appEntityField.name}</td>
                    <td>{appEntityField.type}</td>
                    <td>{appEntityField.isRequired ? 'true' : 'false'}</td>
                    <td>{appEntityField.description}</td>
                    <td className="text-end">
                      {appEntityField?.id ? <div className="btn-group flex-btn-group-container">
                        {/* <Button
                          tag={Link}
                          to={`/product-feature/${id}/app-entity-view/${appEntityId}/${appEntityField.id}`}
                          color="info"
                          size="sm"
                          data-cy="entityDetailsButton"
                        >
                          <FontAwesomeIcon icon="eye" />{' '}
                        </Button> */}
                        <Button
                          tag={Link}
                          to={`/product/${productId}/app/${appId}/app-entity/${appEntityId}/view/app-entity-field/${appEntityField.id}/edit`}
                          color="primary"
                          size="sm"
                          data-cy="entityEditButton"
                        >
                          <FontAwesomeIcon icon="pencil-alt" />{' '}
                        </Button>
                        <Button
                          // tag={Link}
                          // to={`/product-feature/${id}/app-entity-view/${appEntityId}/${appEntityField.id}/delete`}
                          onClick={() => handleDelete(appEntityField.id)}
                          color="danger"
                          size="sm"
                          data-cy="entityDeleteButton"
                        >
                          <FontAwesomeIcon icon="trash" />{' '}
                        </Button>
                      </div> : 
                      <div className="btn-group flex-btn-group-container">
                      
                      <Button
                        onClick={(e) => {handleOnSaveForSuggested(appEntityField)}}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        Save
                      </Button>
                      <Button
                        onClick={() => handleSuggestedDelete(appEntityField)}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>}

                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        ) : (
          loading ? (<div className="conductor_loader"></div>) : (
            <div className="alert alert-warning">
              <Translate contentKey="conductorMonolithApp.appEntityField.home.notFound">No App Entity Fields found</Translate>
            </div>
          )
        )}
        {aiSuggestLoading && <div className="conductor_loader" style={{position: 'absolute',top: '10%',transform: 'translate(-50px, 20%)'}}></div>}
        {totalItems ? (
          <div className={appEntityFieldList && appEntityFieldList.length > 0 ? '' : 'd-none'}>
            <div className="justify-content-center d-flex">
              <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
            </div>
            <div className="justify-content-center d-flex">
              <JhiPagination
                activePage={paginationState.activePage}
                onSelect={handlePagination}
                maxButtons={5}
                itemsPerPage={paginationState.itemsPerPage}
                totalItems={totalItems}
              />
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      {showModal && <AppEntityFieldDeleteDialog itemId={deleteId} showModal={viewModal} />}
    </>
  );
};

export default AppEntityField;
