import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity, deleteEntity, getEntities } from './app-end-point.reducer';

export const AppEndPointDeleteDialog = (props) => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const { appId } = useParams<'appId'>();
  const { endpointControllerId } = useParams<'endpointControllerId'>();
  const { id } = useParams<'id'>();

  const [loadModal, setLoadModal] = useState(false);


  const appEndPointEntity = useAppSelector(state => state.appEndPoint.entity);
  const updateSuccess = useAppSelector(state => state.appEndPoint.updateSuccess);

  const handleClose = () => {
    // navigate('/app-end-point' + location.search);
    props.showModal(loadModal)
  };

  const getAllEntities = () => {
    dispatch(
      getEntities({
        sort: `id,asc&appEndPointControllerId.equals=${endpointControllerId}`,
      })
    );
  };

  useEffect(() => {
    if (updateSuccess) {
      props.showModal(loadModal)
      getAllEntities();
    }
  }, [updateSuccess]);

  const confirmDelete = () => {
    dispatch(deleteEntity(props.itemID));
  };

  return (
    <Modal isOpen toggle={handleClose}>
      <ModalHeader toggle={handleClose} data-cy="appEndPointDeleteDialogHeading">
        <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>
      </ModalHeader>
      <ModalBody id="conductorMonolithApp.appEndPoint.delete.question">
        <Translate contentKey="conductorMonolithApp.appEndPoint.delete.question" interpolate={{ id: appEndPointEntity.id }}>
          Are you sure you want to delete this AppEndPoint?
        </Translate>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleClose}>
          <FontAwesomeIcon icon="ban" />
          &nbsp;
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
        <Button id="jhi-confirm-delete-appEndPoint" data-cy="entityConfirmDeleteButton" color="danger" onClick={confirmDelete}>
          <FontAwesomeIcon icon="trash" />
          &nbsp;
          <Translate contentKey="entity.action.delete">Delete</Translate>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AppEndPointDeleteDialog;
