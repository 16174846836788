import React from 'react';
interface AppSvgIconProps{
  color: string;
}

export const ProductSvgIcon = (props:AppSvgIconProps) => (
  <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 19.1 19" style={{ width: '21px', height: '21px' }}>
    <path
      style={{ fill: 'none', stroke: props.color, strokeWidth: '1.5', strokeLinejoin: 'round' }}
      d="M18.3,5L9.4,0.8L0.8,5v8.8l8.8,4.4l8.8-4.4V5H18.3z M0.8,5l8.8,4.4 M9.4,18.3V9.4 M18.3,5L9.4,9.4 M13.8,2.9 L5,7.3"
    />
  </svg>
);

export const AppSvgIcon = (props:AppSvgIconProps) => (
  <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 19.3 19.3" style={{ width: '21px', height: '21px' }}>
    <path
      style={{fill: 'none', stroke: props.color, strokeWidth: '1.5', strokeLinejoin: 'round' }}
      d="M14.8,0.8h-13c-0.6,0-1,0.4-1,1v13 M4.8,3.8h12c0.6,0,1,0.4,1,1v12c0,0.6-0.4,1-1,1h-12c-0.6,0-1-0.4-1-1v-12
	C3.8,4.2,4.2,3.8,4.8,3.8z"
    />
  </svg>
);

export const FunctionalitySvgIcon = () => (
  <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 19 19" style={{ width: '21px', height: '21px' }}>
    <path
      style={{ fill: 'none', stroke: '#FFFFFF', strokeWidth: '1.5' }}
      d="M11.3,1.9c0-0.6-0.5-1.1-1.1-1.1H8.9c-0.6,0-1.1,0.5-1.1,1.1C7.8,2.4,7.5,2.8,7,3C6.9,3,6.8,3,6.7,3.1
	c-0.5,0.2-1,0.1-1.4-0.2C5.1,2.7,4.8,2.5,4.6,2.5C4.3,2.5,4,2.7,3.8,2.9L2.9,3.8C2.7,4,2.5,4.3,2.5,4.6c0,0.3,0.1,0.6,0.3,0.8
	C3.2,5.8,3.2,6.3,3,6.8v0.1C2.8,7.4,2.4,7.8,1.9,7.8c-0.6,0-1.1,0.5-1.1,1.1v1.3c0,0.6,0.5,1.1,1.1,1.1c0.5,0,0.9,0.3,1.1,0.8
	c0,0.1,0.1,0.1,0.1,0.2c0.2,0.5,0.1,1-0.2,1.4c-0.2,0.2-0.3,0.5-0.3,0.8s0.1,0.6,0.3,0.8l0.9,0.9c0.2,0.2,0.5,0.3,0.8,0.3
	s0.6-0.1,0.8-0.3c0.4-0.4,0.9-0.4,1.4-0.2h0.1c0.5,0.2,0.8,0.6,0.8,1.1c0,0.6,0.5,1.1,1.1,1.1h1.3c0.6,0,1.1-0.5,1.1-1.1
	c0-0.5,0.3-0.9,0.8-1.1c0.1,0,0.1-0.1,0.2-0.1c0.5-0.2,1-0.1,1.4,0.2c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0.1,0.3,0.1,0.4,0.1
	c0.1,0,0.3,0,0.4-0.1c0.1-0.1,0.3-0.1,0.4-0.2l0.9-0.9c0.2-0.2,0.3-0.5,0.3-0.8s-0.1-0.6-0.3-0.8c-0.4-0.4-0.4-0.9-0.2-1.4
	c0-0.1,0.1-0.1,0.1-0.2c0.2-0.5,0.6-0.8,1.1-0.8c0.6,0,1.1-0.5,1.1-1.1V8.9c0-0.6-0.5-1.1-1.1-1.1c-0.5,0-0.9-0.3-1.1-0.8
	c0-0.1-0.1-0.1-0.1-0.2c-0.2-0.5-0.1-1,0.2-1.4c0.2-0.2,0.3-0.5,0.3-0.8S16.3,4,16.1,3.8l-0.9-0.9c-0.2-0.2-0.5-0.3-0.8-0.3
	s-0.6,0.1-0.8,0.3c-0.4,0.4-0.9,0.4-1.4,0.2C12.2,3,12.1,3,12.1,3C11.6,2.8,11.3,2.4,11.3,1.9L11.3,1.9z M13,9.5
	c0,0.9-0.4,1.8-1,2.5c-0.7,0.7-1.5,1-2.5,1c-0.9,0-1.8-0.4-2.5-1c-0.7-0.7-1-1.5-1-2.5C6,8.6,6.4,7.7,7,7c0.7-0.7,1.5-1,2.5-1
	c0.9,0,1.8,0.4,2.5,1C12.6,7.7,13,8.6,13,9.5L13,9.5z"
    />
  </svg>
);

export const AppEntitySvgIcon = () => (
  <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 18.5 14.5" style={{ width: '21px', height: '21px' }}>
    <path
      style={{ fill: 'none', stroke: '#FFFFFF', strokeWidth: '1.5', strokeLinejoin: 'round' }}
      d="M0.8,9.4l8.5,4.3l8.5-4.3 M9.3,0.8L0.8,5.1l8.5,4.3l8.5-4.3L9.3,0.8z"
    />
  </svg>
);

export const AppEntityFieldSvgIcon = () => (
  <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 19 12" style={{ width: '21px', height: '21px' }}>
    <path
      style={{ fill: 'none', stroke: '#FFFFFF', strokeWidth: '1.5', strokeLinejoin: 'round' }}
      d="M3.4,2.9h1.3 M6,2.9H4.7 M4.7,2.9v6.1 M4.7,9.1H3.4 M4.7,9.1H6 M2.5,0.8h14c0.5,0,0.9,0.2,1.2,0.5
	c0.3,0.3,0.5,0.8,0.5,1.2v7c0,0.5-0.2,0.9-0.5,1.2s-0.8,0.5-1.2,0.5h-14c-0.5,0-0.9-0.2-1.2-0.5S0.8,10,0.8,9.5v-7
	c0-0.5,0.2-0.9,0.5-1.2C1.6,0.9,2,0.8,2.5,0.8L2.5,0.8z"
    />
  </svg>
);

export const EntityRelationshipSvgIcon = () => (
  <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 18.4 18.4" style={{ width: '21px', height: '21px' }}>
    <path
      style={{ fill: '#FFFFFF' }}
      d="M15.7,2.6c-0.6,0-1.1,0.2-1.6,0.6c-0.5,0.4-0.8,0.9-0.9,1.4h-2.8c-0.1-1-0.5-1.9-1.1-2.7S7.9,0.6,6.9,0.3
	C6,0,5-0.1,4.1,0.1S2.2,0.8,1.5,1.5C0.8,2.2,0.4,3.1,0.1,4.1C-0.1,5,0,6,0.3,6.9s0.9,1.7,1.7,2.4s1.7,1,2.7,1.1v2.8
	c-0.6,0.2-1.2,0.5-1.5,1.1c-0.4,0.5-0.5,1.2-0.4,1.8c0.1,0.6,0.4,1.2,0.9,1.6c0.5,0.4,1.1,0.7,1.7,0.7s1.3-0.2,1.7-0.7
	c0.5-0.4,0.8-1,0.9-1.6c0.1-0.6-0.1-1.3-0.4-1.8c-0.4-0.5-0.9-0.9-1.5-1.1v-2.8c0.9-0.1,1.8-0.5,2.6-1.1l2.4,2.4
	c-0.2,0.4-0.4,0.9-0.4,1.3c0,0.5,0.2,1,0.4,1.5c0.3,0.4,0.7,0.8,1.2,1c0.5,0.2,1,0.3,1.5,0.1c0.5-0.1,1-0.4,1.3-0.7
	c0.4-0.4,0.6-0.8,0.7-1.3s0-1-0.1-1.5c-0.2-0.5-0.5-0.9-1-1.2c-0.4-0.3-0.9-0.4-1.5-0.4c-0.5,0-0.9,0.1-1.3,0.4L9.4,8.5
	c0.6-0.7,0.9-1.6,1.1-2.6h2.8c0.1,0.5,0.4,0.9,0.7,1.2c0.4,0.3,0.8,0.6,1.3,0.7c0.5,0.1,1,0.1,1.4-0.1c0.5-0.2,0.9-0.5,1.2-0.9
	c0.3-0.4,0.5-0.9,0.5-1.3c0-0.5,0-1-0.3-1.4c-0.2-0.4-0.6-0.8-1-1.1C16.7,2.8,16.2,2.6,15.7,2.6z M6.6,15.7c0,0.3-0.1,0.5-0.2,0.7
	C6.2,16.7,6,16.9,5.8,17C5.5,17.1,5.2,17.1,5,17c-0.3-0.1-0.5-0.2-0.7-0.4C4.1,16.5,4,16.3,4,16c-0.1-0.3,0-0.5,0.1-0.8
	c0.1-0.2,0.3-0.4,0.5-0.6c0.2-0.1,0.5-0.2,0.7-0.2c0.3,0,0.7,0.1,0.9,0.4C6.4,15.1,6.6,15.4,6.6,15.7z M5.2,9.2
	C4.5,9.2,3.7,9,3.1,8.5c-0.6-0.4-1.2-1-1.5-1.8C1.3,6,1.2,5.2,1.4,4.5c0.2-0.8,0.5-1.5,1.1-2s1.3-0.9,2-1.1S6,1.3,6.8,1.6
	c0.7,0.3,1.3,0.8,1.8,1.5C9,3.7,9.2,4.5,9.2,5.2c0,1-0.4,2-1.2,2.8C7.3,8.8,6.3,9.2,5.2,9.2z M14.4,13.1c0,0.3-0.1,0.5-0.2,0.7
	c-0.1,0.2-0.3,0.4-0.6,0.5c-0.2,0.1-0.5,0.1-0.8,0.1c-0.3-0.1-0.5-0.2-0.7-0.4c-0.2-0.2-0.3-0.4-0.4-0.7c-0.1-0.3,0-0.5,0.1-0.8
	c0.1-0.2,0.3-0.4,0.5-0.6c0.2-0.1,0.5-0.2,0.7-0.2c0.3,0,0.7,0.1,0.9,0.4C14.3,12.4,14.4,12.8,14.4,13.1z M15.7,6.6
	c-0.3,0-0.5-0.1-0.7-0.2c-0.2-0.1-0.4-0.3-0.5-0.6c-0.1-0.2-0.1-0.5-0.1-0.8c0.1-0.3,0.2-0.5,0.4-0.7C15,4.1,15.2,4,15.5,4
	c0.3-0.1,0.5,0,0.8,0.1c0.2,0.1,0.4,0.3,0.6,0.5C17,4.7,17.1,5,17.1,5.2c0,0.3-0.1,0.7-0.4,0.9C16.4,6.4,16.1,6.6,15.7,6.6z"
    />
  </svg>
);

export const DeploymentSvgIcon = () => (
  <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 18.4 18.4" style={{ width: '21px', height: '21px' }}>
    <path
      style={{ fill: '#FFFFFF' }}
      d="M11.2,9.2V7.9h4.7l-1.5-1.5l0.9-0.9l3.1,3.1l-3.1,3.1l-0.9-0.9l1.5-1.5H11.2z M9.8,6.6H8.5V2.5L7,4L6.1,3.1
	L9.2,0l3.1,3.1L11.3,4L9.8,2.5V6.6z M9.8,11.9v-1.4c0-0.7-0.3-1.4-0.8-1.9C8.6,8.2,7.9,7.9,7.2,7.9H2.5L4,6.4L3.1,5.4L0,8.5l3.1,3.1
	L4,10.7L2.5,9.2h4.7c0.3,0,0.7,0.1,0.9,0.4c0.2,0.2,0.4,0.6,0.4,0.9v1.4c-0.8,0.2-1.5,0.6-2,1.3c-0.5,0.7-0.7,1.5-0.6,2.3
	C6,16.2,6.4,17,7,17.5c0.6,0.5,1.4,0.8,2.2,0.8c0.8,0,1.6-0.3,2.2-0.8c0.6-0.5,1-1.3,1.1-2.1c0.1-0.8-0.1-1.6-0.6-2.3
	C11.3,12.5,10.6,12,9.8,11.9z M9.2,17.1c-0.4,0-0.8-0.1-1.1-0.3c-0.3-0.2-0.6-0.5-0.7-0.9c-0.1-0.4-0.2-0.8-0.1-1.1
	c0.1-0.4,0.3-0.7,0.5-1c0.3-0.3,0.6-0.5,1-0.5c0.4-0.1,0.8,0,1.1,0.1c0.4,0.1,0.7,0.4,0.9,0.7c0.2,0.3,0.3,0.7,0.3,1.1
	c0,0.5-0.2,1-0.6,1.4C10.2,16.9,9.7,17.1,9.2,17.1z"
    />
  </svg>
);

export const CoreSvgIcon = () => (
  <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 447.8 512" style={{ width: '21px', height: '21px' }}>
    <path
      style={{ fill: '#FFFFFF' }}
      d="M223.8,512c-43.3,0-80.8-55.6-97.7-145.2l20.9-4c14.5,76.5,45.3,127.9,76.8,127.9c40.4,0,85.3-96.4,85.3-234.7
	S264.2,21.3,223.8,21.3c-31.5,0-62.3,51.3-76.8,127.9l-20.9-4C143,55.6,180.5,0,223.8,0c59.9,0,106.7,112.4,106.7,256
	S283.7,512,223.8,512z M77.8,416c-44.5,0-63.3-16.9-71.3-31.2c-29.2-52,43.2-149.5,164.9-221.7c88.1-52.4,178.9-77.2,231.2-63.2
	c18.1,4.8,31.1,14,38.5,27.3c4.5,8,6.7,17.2,6.7,27.2c-0.1,26-10.8,51.2-31.5,74.9l-16-14c17.5-20,26.1-40,26.3-61.1
	c0-6.4-1.3-12-3.9-16.7c-4.5-8.1-13.1-13.9-25.5-17.2c-46.4-12.4-132.7,12.1-214.9,60.9C63.1,252.1,4.9,338.3,25,374.4
	c9.5,16.9,34.1,20,53.5,20.3c8,0,15.6-0.1,25.5-0.9l1.9,21.2c-10.7,0.9-19.1,1.1-27.2,1.1C78.3,416,78.1,416,77.8,416z M369.8,416
	c-53.1,0-126.7-24.4-198.5-67.1C49.7,276.7-22.7,179.2,6.5,127.2c8-14.3,26.9-31.2,71.3-31.2h1.5c9.1,0,16.9,0.4,26.3,1.1l-1.5,21.3
	c-8.9-0.7-16.4-0.9-24.9-0.9c-19.2,0.3-44.4,3.2-54,20.3c-20.3,36,38,122.1,157.1,192.9c82.1,48.8,168.5,73.3,214.9,60.9
	c12.3-3.3,20.9-9.1,25.3-17.2c2.7-4.8,4-10.7,3.9-17.2c-0.3-16.1-7.3-59.1-66.7-109.6l13.9-16.3c46.4,39.5,73.5,85.2,74.3,125.5
	c0.1,10.4-2,19.9-6.7,28c-7.5,13.2-20.4,22.4-38.5,27.3C393,414.7,381.9,416,369.8,416z M223.8,309.3c-29.5,0-53.3-23.9-53.3-53.3
	s23.9-53.3,53.3-53.3c29.5,0,53.3,23.9,53.3,53.3S253.3,309.3,223.8,309.3z M223.8,224c-17.6,0-32,14.4-32,32s14.4,32,32,32
	c17.6,0,32-14.4,32-32S241.4,224,223.8,224z"
    />
  </svg>
);

export const EditIcon = () => (
  <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 46 46" style={{ width: '46px', height: '46px' }}>
    <circle style={{ fill: '#064CA4' }}  cx="23" cy="23" r="23"/>
    <path style={{ fill: '#FFFFFF' }} d="M32.1,24.4v4.8c0,0.5-0.2,1-0.6,1.4c-0.4,0.4-0.8,0.6-1.4,0.6H16.8c-0.5,0-1-0.2-1.4-0.6
      c-0.4-0.4-0.6-0.8-0.6-1.4V15.8c0-0.5,0.2-1,0.6-1.4c0.4-0.4,0.8-0.6,1.4-0.6h4.8v1.9h-4.8v13.4h13.4v-4.8H32.1z M32.1,17.7h-3.8
      v-3.8h-1.9v3.8h-3.8v1.9h3.8v3.8h1.9v-3.8h3.8V17.7z"/>
  </svg>
);
