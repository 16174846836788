import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IProduct, defaultValue } from 'app/shared/model/product.model';
import { IProductContext } from 'app/shared/model/product-context.model';

const initialState: EntityState<IProduct> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

interface copyCoreFunctionalityParams {
  productId: any
  coreFunctionalityId: any[]
}
const notify = () => toast('Product Successfully Generated..!!!');

const apiUrl = 'api/products';

const productUrl = 'api/setup/products';

const generateProductUrl = 'api/extsetup/product';

// Actions

export const getEntities = createAsyncThunk('product/fetch_entity_list', async ({ page, size, sort, query }: IQueryParams) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}${query ? `&${query}` : ''
    }`;
  return axios.get<IProduct[]>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'product/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IProduct>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const creatextProducts = createAsyncThunk(
  'product/fetch_entity',

  async (id: string | number) => {
    const requestUrl = `${generateProductUrl}/${id}`;
    const result = axios.post<any>(requestUrl).then(function (response) {
      notify();
      return response.data;
    });
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'product/create_entity',
  async (entity: IProduct, thunkAPI) => {
    const result = await axios.post<IProduct>(apiUrl, cleanEntity(entity));
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const createProduct = createAsyncThunk(
  'product/create_product',
  async () => {
    const result = await axios.post<any>(productUrl, undefined);
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const copyCoreFunctionality = createAsyncThunk(
  '/api/setup/copyCoreFunctionality/product ',
  async (data: copyCoreFunctionalityParams, thunkAPI) => {
    const result = await axios.put(`api/setup/copyCoreFunctionality/product`, data);
    // thunkAPI.dispatch(getEntities({}));
    // return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'product/update_entity',
  async (entity: IProduct, thunkAPI) => {
    const result = await axios.put<IProduct>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'product/partial_update_entity',
  async (entity: IProduct, thunkAPI) => {
    const result = await axios.patch<IProduct>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'product/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IProduct>(requestUrl);
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const extractProductContext = createAsyncThunk(
  'productContext/fetch_entity',

  async (userPrompt: string) => {
    const requestUrl = `api/ai/productcontext?userPrompt=${userPrompt}`;
    const result = axios.post<IProductContext>(requestUrl).then(function (response) {
      return response.data;
    });
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const extractUserIntentContext = createAsyncThunk(
  'userIntentContext/fetch_entity',

  async (userPrompt: string) => {
    const requestUrl = `api/ai/userintent?userPrompt=${userPrompt}`;
    const result = axios.post<IProductContext>(requestUrl).then(function (response) {
      return response.data;
    });
    return result;
  },
  { serializeError: serializeAxiosError }
);


// slice

export const ProductSlice = createEntitySlice({
  name: 'product',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = ProductSlice.actions;

// Reducer
export default ProductSlice.reducer; 
