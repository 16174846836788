import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Translate, getSortState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { deleteEntity, updateEntity, getEntities } from './app.reducer';
import { getEntities as getVaultConfigs, deleteEntity as deleteVaultConfig } from '../vault-config/vault-config.reducer';
import { useParams } from 'react-router-dom';
import { AppStatus } from 'app/shared/model/enumerations/app-status.model';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';

export const AppDeleteDialog = ({ itemId, closeModal }) => {
  const dispatch = useAppDispatch();
  const { id: productId } = useParams<'id'>();
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id', DESC), location.search)
  );
  const updateSuccess = useAppSelector(state => state.app.updateSuccess);
  // const vaultConfigs = useAppSelector(state => state.vaultConfig.entities);
 
  // const [isConfigDeleted, setIsConfigDeleted] = useState(undefined);

  const handleClose = () => {
    closeModal(false);
  };

  useEffect(() => {
    // dispatch(getVaultConfigs({ query: `appId.equals=${itemId}` }));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
      dispatch(
        getEntities({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `id,desc`,
          query: `productId.equals=${productId}&status.notEquals=${AppStatus.Deleted}`,
        })
      );
    }
  }, [updateSuccess]);

  // useEffect(() => {
  //   if (isConfigDeleted) {
  //     dispatch(deleteEntity(itemId));
  //     // setIsConfigDeleted(undefined);
  //   }
  // }, [isConfigDeleted]);

  const confirmDelete = async () => {
    let update_entity = { ...itemId, status: 'Deleted' };
    // console.log("update_entity ::", updateEntity);
    dispatch(updateEntity(update_entity));
  };

  return (
    <Modal isOpen toggle={handleClose}>
      <ModalHeader toggle={handleClose} data-cy="appDeleteDialogHeading">
        <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>
      </ModalHeader>
      <ModalBody id="conductorMonolithApp.app.delete.question">
        <Translate contentKey="conductorMonolithApp.app.delete.question" interpolate={{ id: itemId }}>
          Are you sure you want to delete this App?
        </Translate>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={e => handleClose()}>
          <FontAwesomeIcon icon="ban" />
          &nbsp;
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
        <Button id="jhi-confirm-delete-app" data-cy="entityConfirmDeleteButton" color="danger" onClick={confirmDelete}>
          <FontAwesomeIcon icon="trash" />
          &nbsp;
          <Translate contentKey="entity.action.delete">Delete</Translate>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AppDeleteDialog;
