import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, byteSize, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './vault-config.reducer';

export const VaultConfigDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const vaultConfigEntity = useAppSelector(state => state.vaultConfig.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="vaultConfigDetailsHeading">
          <Translate contentKey="conductorMonolithApp.vaultConfig.detail.title">VaultConfig</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{vaultConfigEntity.id}</dd>
          <dt>
            <span id="vaultToken">
              <Translate contentKey="conductorMonolithApp.vaultConfig.vaultToken">Vault Token</Translate>
            </span>
          </dt>
          <dd>{vaultConfigEntity.vaultToken}</dd>
          <dt>
            <span id="secretPath">
              <Translate contentKey="conductorMonolithApp.vaultConfig.secretPath">Secret Path</Translate>
            </span>
          </dt>
          <dd>{vaultConfigEntity.secretPath}</dd>
          <dt>
            <span id="policyName">
              <Translate contentKey="conductorMonolithApp.vaultConfig.policyName">Policy Name</Translate>
            </span>
          </dt>
          <dd>{vaultConfigEntity.policyName}</dd>
          <dt>
            <span id="profile">
              <Translate contentKey="conductorMonolithApp.vaultConfig.profile">Profile</Translate>
            </span>
          </dt>
          <dd>{vaultConfigEntity.profile}</dd>
          <dt>
            <span id="configValues">
              <Translate contentKey="conductorMonolithApp.vaultConfig.configValues">Config Values</Translate>
            </span>
          </dt>
          <dd>{vaultConfigEntity.configValues}</dd>
          <dt>
            <span id="userId">
              <Translate contentKey="conductorMonolithApp.vaultConfig.userId">User Id</Translate>
            </span>
          </dt>
          <dd>{vaultConfigEntity.userId}</dd>
          <dt>
            <span id="tokenIssueDate">
              <Translate contentKey="conductorMonolithApp.vaultConfig.tokenIssueDate">Token Issue Date</Translate>
            </span>
          </dt>
          <dd>
            {vaultConfigEntity.tokenIssueDate ? (
              <TextFormat value={vaultConfigEntity.tokenIssueDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="conductorMonolithApp.vaultConfig.app">App</Translate>
          </dt>
          <dd>{vaultConfigEntity.app ? vaultConfigEntity.app.name : ''}</dd>
        </dl>
        <Button tag={Link} to="/vault-config" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/vault-config/${vaultConfigEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default VaultConfigDetail;
