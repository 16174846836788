import React, { useState, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Button, Col, Input, Row, Spinner, Table } from 'reactstrap';
import { Translate, getSortState, JhiPagination, JhiItemCount, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities } from 'app/entities/entity-relationship/entity-relationship.reducer';
import EntityRelationshipDeleteDialog from 'app/entities/entity-relationship/entity-relationship-delete-dialog';
import { getEntities as getAppEntities } from 'app/entities/app-entity/app-entity.reducer';
import { useDispatch } from 'react-redux';

export const Relationship = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { productId } = useParams<'productId'>();
  const { appId } = useParams<'appId'>();

  var [deleteId, setDeleteId] = useState("")
  const [showModal, setShowModal] = useState(false)
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id', DESC), location.search)
  );
  const [entityFrom, setEntityFrom] = useState("")
  const [entityTo, setEntityTo] = useState("")
  const entityRelationshipList = useAppSelector(state => state.entityRelationship.entities);
  const loading = useAppSelector(state => state.entityRelationship.loading);
  const totalItems = useAppSelector(state => state.entityRelationship.totalItems);
  
  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query: !!appId ? `appId.equals=${appId}${entityFrom?`&appEntityFromId.equals=${Number(entityFrom)}`:""}${entityTo?`&appEntityToId.equals=${Number(entityTo)}`:""}` : "",
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const handleDelete = (functionality_id) => {
    setShowModal(true)
    setDeleteId(functionality_id)
    deleteId = functionality_id;
  }

  const viewModal = (modal) => {
    setShowModal(modal)
  }


  return (
    <>
      <div className='inner-content-wrap'>
        <h2 id="entity-relationship-heading" data-cy="EntityRelationshipHeading" className="row mb-4 mt-3">

          <Col md="12">
            <Row>

            <Col md={6}>
              <Row>
              <SearchModule value={entityFrom} handleChange={setEntityFrom} placeHolder="App Entity From"/>
              <SearchModule value={entityTo} handleChange={setEntityTo} placeHolder= "App Entity To"/>
              <Col>
              <Button onClick={sortEntities}>Search</Button>
              </Col>
              </Row>
            </Col>
            <Col md={6} className="d-flex justify-content-end">
              {/* <Button className="me-2" color="refresh" onClick={handleSyncList} disabled={loading}>
                <FontAwesomeIcon icon="sync" spin={loading} />{' '}
                <Translate contentKey="conductorMonolithApp.entityRelationship.home.refreshListLabel">Refresh List</Translate>
              </Button> */}
                           <Link
                to={`/product/${productId}/app/${appId}/entity-relationship/new`}
                className="btn btn-save jh-create-entity"
                id="jh-create-entity"
                data-cy="entityCreateButton"
              >
                <FontAwesomeIcon icon="plus" />
                &nbsp;
                <Translate contentKey="conductorMonolithApp.entityRelationship.home.createLabel">Create new Entity Relationship</Translate>
              </Link>
            </Col>
            </Row>
          </Col>
        </h2>
        {entityRelationshipList && entityRelationshipList.length > 0 ? (
          <div className="table-responsive">
            <Table>
              <thead className="table-primary">
                <tr>
                  <th className="hand" onClick={sort('type')}>
                    <Translate contentKey="conductorMonolithApp.entityRelationship.type">Type</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th onClick={sort('appEntityFrom')}>
                    <Translate contentKey="conductorMonolithApp.entityRelationship.appEntityFrom">Entity from</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th onClick={sort('appEntityTo')}>
                    <Translate contentKey="conductorMonolithApp.entityRelationship.appEntityTo">Entity To</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th onClick={sort('appEntityFieldFrom')}>
                    <Translate contentKey="conductorMonolithApp.entityRelationship.appEntityFieldFrom">Entity Field From</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th onClick={sort('appEntityFieldTo')}>
                    <Translate contentKey="conductorMonolithApp.entityRelationship.appEntityFieldTo">Entity Field To</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {entityRelationshipList.map((entityRelationship, i) => (
                  <tr key={`entity-${i}`} data-cy="entityTable">
                    <td>{entityRelationship.type}</td>
                    <td>{entityRelationship.appEntityFrom.name}</td>
                    <td>{entityRelationship.appEntityTo.name}</td>
                    <td>
                      {entityRelationship.appEntityFieldFrom ? (
                        <Link style={{ cursor: "auto", textDecoration: "none", fontWeight: "normal", color: "#000" }} to={`/product/${productId}/app/${appId}`}>
                          {entityRelationship.appEntityFieldFrom.name}
                        </Link>
                      ) : (
                        ''
                      )}
                    </td>
                    <td>
                      {entityRelationship.appEntityFieldTo ? (
                        <Link style={{ cursor: "auto", textDecoration: "none", fontWeight: "normal", color: "#000" }} to={`/product/${productId}/app/${appId}`}>
                          {entityRelationship.appEntityFieldTo.name}
                        </Link>
                      ) : (
                        ''
                      )}
                    </td>
                    <td className="text-end">
                      <div className="btn-group flex-btn-group-container">
                        {/* <Button
                          tag={Link}
                          to={`/entity-relationship/${appid}/${entityRelationship.id}/view`}
                          color="primary"
                          size="sm"
                          data-cy="entityDetailsButton"
                        >
                          <FontAwesomeIcon icon="eye" />{' '}
                          <span className="d-none">
                            <Translate contentKey="entity.action.view">View</Translate>
                          </span>
                        </Button> */}
                        <Button
                          tag={Link}
                          to={`/product/${productId}/app/${appId}/entity-relationship/${entityRelationship.id}/edit`}
                          color="warning"
                          size="sm"
                          data-cy="entityEditButton"
                        >
                          <FontAwesomeIcon icon="pencil-alt" />{' '}
                          <span className="d-none">
                            <Translate contentKey="entity.action.edit">Edit</Translate>
                          </span>
                        </Button>
                        <Button
                          onClick={() => handleDelete(entityRelationship.id)}
                          color="danger"
                          size="sm"
                          data-cy="entityDeleteButton"
                        >
                          <FontAwesomeIcon icon="trash" />{' '}
                          <span className="d-none">
                            <Translate contentKey="entity.action.delete">Delete</Translate>
                          </span>
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>) : (
          loading ? (<div className="conductor_loader"></div>) : (
            <div className="alert alert-warning">
              <Translate contentKey="conductorMonolithApp.entityRelationship.home.notFound">No Entity Relationships found</Translate>
            </div>
          )
        )}
        {totalItems ? (
          <div className={entityRelationshipList && entityRelationshipList.length > 0 ? '' : 'd-none'}>
            <div className="justify-content-center d-flex">
              <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
            </div>
            <div className="justify-content-center d-flex">
              <JhiPagination
                activePage={paginationState.activePage}
                onSelect={handlePagination}
                maxButtons={5}
                itemsPerPage={paginationState.itemsPerPage}
                totalItems={totalItems}
              />
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      {showModal && <EntityRelationshipDeleteDialog itemId={deleteId} showModal={viewModal} />}
    </>
  );
};

export default Relationship;
type Props = {
  handleChange:any
  placeHolder:string
  value:any
}
const SearchModule = (props: Props) => {
  const {handleChange,value,placeHolder} = props
  const dispatch = useAppDispatch()

  const appEntities = useAppSelector(state => state.appEntity.entities);
  
  const { appId } = useParams<'appId'>();

  useEffect(() => {
    dispatch(getAppEntities({
      page: 0,
      size: 500,
      sort: `id,desc`,
      // query: `appId.equals=${appId}`,
      query: `appId.equals=${appId}`
    }))
  },[])

const handleSelectChange =(e) =>{
  handleChange(e.target.value)
}

  return (<Col md={5}>
      <Input
        value={value}
        id="exampleSelect"
        name="select"
        type="select"
        onChange={handleSelectChange}
      >
          <option value={""}>
          {placeHolder}
            </option>
        {appEntities.map(item => {
          return (
            <option value={item.id}>
              {item.name}
            </option>

          )
        })}
      </Input>
  </Col>)
}
