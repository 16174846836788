import AppentityFormView from './AppentityFormView';

import React from 'react';
import { Route } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
interface Props {}

export const EntityWizard = (props: Props) => {
  return (
    <Row>
      <Col md="12" className="mx-5">
        <h1> EntityWizard</h1>
      </Col>

      <Col md="12">
        <AppentityFormView />
      </Col>

      <span id="custom-components" />
    </Row>
  );
};

export default EntityWizard;
