import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Translate,getSortState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities, deleteEntity } from './app-entity.reducer';

export const AppEntityDeleteDialog = (itemId) => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();
  const { appId } = useParams<'appId'>();


  const [loadModal, setLoadModal] = useState(false);

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id', DESC), location.search)
  );
  const appEntityEntity = useAppSelector(state => state.appEntity.entities);
  const updateSuccess = useAppSelector(state => state.appEntity.updateSuccess);

  const handleClose = () => {
    itemId.showModal(loadModal)
  };

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query: `appId.equals=${appId}`,
      })
    );
  };

  useEffect(() => {
    if (updateSuccess) {
      itemId.showModal(loadModal)
      getAllEntities()
    }
  }, [updateSuccess]);

  const confirmDelete = () => {
    dispatch(deleteEntity(itemId.itemId));
  };

  return (
    <Modal isOpen 
    // toggle={handleClose}
    >
      <ModalHeader
      //  toggle={handleClose} 
       data-cy="appEntityDeleteDialogHeading">
        <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>
      </ModalHeader>
      <ModalBody id="conductorMonolithApp.appEntity.delete.question">
        <Translate contentKey="conductorMonolithApp.appEntity.delete.question" interpolate={{ id: itemId.itemId }}>
          Are you sure you want to delete this AppEntity?
        </Translate>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" 
        onClick={handleClose}
        >
          <FontAwesomeIcon icon="ban" />
          &nbsp;
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
        <Button id="jhi-confirm-delete-appEntity" data-cy="entityConfirmDeleteButton" color="danger" onClick={confirmDelete}>
          <FontAwesomeIcon icon="trash" />
          &nbsp;
          <Translate contentKey="entity.action.delete">Delete</Translate>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AppEntityDeleteDialog;
