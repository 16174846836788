import { IAppEntity } from 'app/shared/model/app-entity.model';
import { IAppScreen } from 'app/shared/model/app-screen.model';
import { IAppAccessConfig } from 'app/shared/model/app-access-config.model';
import { IApp } from 'app/shared/model/app.model';
import { ICoreFunctionality } from 'app/shared/model/core-functionality.model';

export interface IFunctionality {
  id?: number;
  name?: string | null;
  appEntities?: IAppEntity[] | null;
  appScreens?: IAppScreen[] | null;
  appAccessConfigs?: IAppAccessConfig[] | null;
  app?: IApp | null;
  coreFunctionality?: ICoreFunctionality | null;
}

export const defaultValue: Readonly<IFunctionality> = {};
