import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import VaultConfig from './vault-config';
import VaultConfigDetail from './vault-config-detail';
import VaultConfigUpdate from './vault-config-update';
import VaultConfigDeleteDialog from './vault-config-delete-dialog';

const VaultConfigRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<VaultConfig />} />
    <Route path="new" element={<VaultConfigUpdate />} />
    <Route path=":id">
      <Route index element={<VaultConfigDetail />} />
      <Route path="edit" element={<VaultConfigUpdate />} />
      <Route path="delete" element={<VaultConfigDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default VaultConfigRoutes;
