import dayjs from 'dayjs';
import { IAppScreen } from 'app/shared/model/app-screen.model';
import { IScreenContent } from 'app/shared/model/screen-content.model';
import { IAppEntity } from 'app/shared/model/app-entity.model';
import { FormActionType } from 'app/shared/model/enumerations/form-action-type.model';

export interface IScreenComponent {
  id?: number;
  name?: string | null;
  layout?: string | null;
  entityFields?: string | null;
  userId?: number | null;
  layoutTemplate?: string | null;
  lastSyncTimestamp?: string | null;
  filter?: string | null;
  formAction?: FormActionType | null;
  formSuccessMessage?: string | null;
  displayOrder?: number | null;
  screenComponents?: IScreenComponent[] | null;
  appScreenviewEdit?: IAppScreen | null;
  appScreen?: IAppScreen | null;
  screenContent?: IScreenContent | null;
  appScreenviewDetail?: IAppScreen | null;
  appEntity?: IAppEntity | null;
  appScreenformSuccessRedirectScreen?: IAppScreen | null;
  screenComponentParent?: IScreenComponent | null;
}

export const defaultValue: Readonly<IScreenComponent> = {};
