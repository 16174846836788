import { ICoreEntity } from 'app/shared/model/core-entity.model';

export interface ICoreEntityField {
  id?: number;
  name?: string;
  type?: string;
  isRequired?: boolean | null;
  description?: string | null;
  userId?: number | null;
  defaultValue?: string | null;
  coreEntity?: ICoreEntity | null;
}

export const defaultValue: Readonly<ICoreEntityField> = {
  isRequired: false,
};
