import React, { useState, useEffect, useRef } from 'react';
import 'grapesjs/dist/css/grapes.min.css';
import grapesjs from 'grapesjs';
import './grapeEditor.css';
import layouts from "./layouts/layouts";

import { faker } from '@faker-js/faker';

type Props = {
  selectionBuilder?: any;
  //   setQuery?: any;
  //   placeHolder?: string;
};

const GrapeEditor = (props: Props) => {
  //   const { setQuery, fetchFunction, placeHolder } = props;
  //   const appEntities = useAppSelector(state => state.appEntity.entities);

  const selectorManagerDefault = {
    selectorManager: {
      appendTo: '.styles-container',
    },
  };
  const traitManagerDefault = {
    traitManager: {
      appendTo: '.traits-container',
    },
  };
  const styleManagerDefault = {
    styleManager: {
      appendTo: '.styles-container',
      sectors: [
        {
          name: 'Dimension',
          open: false,
          // Use built-in properties
          buildProps: ['width', 'min-height', 'padding'],
          // Use `properties` to define/override single property
          properties: [
            {
              // Type of the input,
              // options: integer | radio | select | color | slider | file | composite | stack
              type: 'integer',
              name: 'The width', // Label for the property
              property: 'width', // CSS property (if buildProps contains it will be extended)
              units: ['px', '%'], // Units, available only for 'integer' types
              defaults: 'auto', // Default value
              min: 0, // Min value, available only for 'integer' types
            },
          ],
        },
        {
          name: 'Extra',
          open: false,
          buildProps: ['background-color', 'box-shadow', 'custom-prop'],
          properties: [
            {
              id: 'custom-prop',
              name: 'Custom Label',
              property: 'font-size',
              type: 'select',
              defaults: '32px',
              // List of options, available only for 'select' and 'radio'  types
              options: [
                { value: '12px', name: 'Tiny' },
                { value: '18px', name: 'Medium' },
                { value: '32px', name: 'Big' },
              ],
            },
          ],
        },
      ],
    },
  };
  const panelsDefault = {
    panels: {
      defaults: [
        {
          id: 'panel-devices',
          el: '.panel__devices',
          buttons: [
            {
              id: 'device-desktop',
              label: 'D',
              command: 'set-device-desktop',
              active: true,
              togglable: false,
            },
            {
              id: 'device-mobile',
              label: 'M',
              command: 'set-device-mobile',
              togglable: false,
            },
          ],
        },
        {
          id: 'panel-switcher',
          el: '.panel__switcher',
          buttons: [
            {
              id: 'show-blocks',
              active: true,
              label: 'Blocks',
              command: 'show-blocks',
              // Once activated disable the possibility to turn it off
              togglable: false,
            },
            {
              id: 'show-layers',
              active: true,
              label: 'Layers',
              command: 'show-layers',
              // Once activated disable the possibility to turn it off
              togglable: false,
            },
            {
              id: 'show-style',
              active: true,
              label: 'Styles',
              command: 'show-styles',
              togglable: false,
            },
            {
              id: 'show-traits',
              active: true,
              label: 'Traits',
              command: 'show-traits',
              togglable: false,
            },
          ],
        },
        {
          id: 'layers',
          el: '.panel__right',
          // resizable: {
          //   maxDim: 350,
          //   minDim: 200,
          //   tc: 0, // Top handler
          //   cl: 1, // Left handler
          //   cr: 0, // Right handler
          //   bc: 0, // Bottom handler
          //   keyWidth: 'flex-basis'
          // }
        },
      ],
    },
  };
  const blockManagerDefaults = {
    blockManager: {
      appendTo: '#blocks-screens',
      blocks: [
        {
          id: 'image',
          label: 'Image',
          select: true,
          content: { type: 'image' },
          activate: true,
        },
        {
          id: 'table',
          label: 'table',
          select: true,
          content: { type: 'table' },
          activate: true,
        },
      ],
    },
  };
  const layersManagerDefaults = {
    layerManager: {
      appendTo: '.layers-container',
    },
  };
  const deviceManagerDefault = {
    deviceManager: {
      devices: [
        {
          name: 'Desktop',
          width: '', // default size
        },
        {
          name: 'Mobile',
          width: '320px', // this value will be used on canvas width
          widthMedia: '480px', // this value will be used in CSS @media
        },
      ],
    },
  };
  const grapeEditorConfigDefault = {
    container: '#gjs-screens',
    fromElement: true,
    height: '100%',
    width: '100%',
    storageManager: false,
  };
  const [grapeEditor, setGrapeEditor] = useState<any>();
  const [grapeEditorContent, setGrapeEditorContent] = useState();
  const previousSelectionBuilder = useRef([]);
  
  useEffect(() => {
    setGrapeEditorContent(props.selectionBuilder);
    previousSelectionBuilder.current = grapeEditorContent;
    if (grapeEditor && props?.selectionBuilder?.length > 0) {


      // check if fields are there 
      // check if html is previewed ; if yes then remove
      // render relevant template based on layout and sublayout with faker data
        let tmp_fak = faker;
        // debugger
      let category = {
        title: faker.internet.displayName(),
      };
      // let fakeData = mocker.addGenerator('faker', faker).schema('user', category, 2).buildSync()
      // debugger;
      props?.selectionBuilder?.map(obj => {
        let fields = obj?.entityfields?.split(',');
        // debugger
        if (fields?.length > 0 && obj.list != '') {

          let layoutId = grapeEditor.Components.getById(`custom-${obj?.layout}-${obj?.name?.toLowerCase()}`)
          let layoutTemplateId = grapeEditor.Components.getById(`custom-${obj?.layout}-${obj?.layoutTemplate}-${obj?.name?.toLowerCase()}`) 
          
          grapeEditor.Components.getById(`custom-header-${obj?.name?.toLowerCase()}`)?.remove();
          if (layoutId) {
            layoutId.remove();
          } else if (layoutTemplateId) {
            layoutTemplateId.remove();
          }
          // grapeEditor.Components.getById(`custom-${obj?.layout}-${obj.layoutTemplate}-${obj?.name?.toLowerCase()}`)?.remove();
          grapeEditor.addComponents(`<h1 id="custom-header-${obj?.name?.toLowerCase()}">${obj?.name}</h1>`);

          const clearEditor = () => {
          grapeEditor.Components.getById(`custom-header-${obj?.name?.toLowerCase()}`)?.remove();
          grapeEditor.Components.getById(`custom-${obj?.layout}-${obj?.name?.toLowerCase()}`)?.remove();
          grapeEditor.Components.getById(`custom-${obj?.layout}-${obj.layoutTemplate}-${obj?.name?.toLowerCase()}`)?.remove();
          };
          const clearButton = document.querySelector(`#deleteapp-${obj.entity}`);
          if (clearButton) {
          clearButton.addEventListener('click', clearEditor);
          }
          // let temp_obj = {};

          // debugger;
          let tmp_component;
          if (obj?.layout == 'list') {
            tmp_component = layouts.listLayout(obj, fields);
          } else if (obj?.layout == 'details') {
            tmp_component = layouts.detailsLayout(obj, fields);
          } else if (obj?.layout == 'form') {
            tmp_component = layouts.formLayout(obj, fields);
          }
          if(tmp_component != null && tmp_component != undefined) {
          console.log("addComponent", tmp_component);
          grapeEditor.addComponents(tmp_component);
          }
          previousSelectionBuilder?.current?.map(curr => {
            let prevEntity = curr.entity;
            let prevLayout = curr.layout;
            let prevEntityLayoutTemplate = curr.layoutTemplate;
            let prevLayoutId = grapeEditor.Components.getById(`custom-${prevLayout}-${curr?.name?.toLowerCase()}`)
            let prevLayoutTemplateId = grapeEditor.Components.getById(`custom-${prevLayout}-${prevEntityLayoutTemplate}-${curr?.name?.toLowerCase()}`);

            if(prevEntity && prevEntity == obj.entity && ((prevEntityLayoutTemplate && prevEntityLayoutTemplate != obj.layoutTemplate) || (prevLayout && prevLayout != obj.layout))) {
              if (prevLayoutId) {
                prevLayoutId.remove();
              } else if (prevLayoutTemplateId) {
                prevLayoutTemplateId.remove();
              }
            }

          })
        } 
      
      });
    } 
  }, [props.selectionBuilder]);

  useEffect(() => {
    let editor = grapesjs.init({
      ...grapeEditorConfigDefault,
      ...styleManagerDefault,
      ...selectorManagerDefault,
      ...layersManagerDefaults,
      ...panelsDefault,
      ...blockManagerDefaults,
      ...traitManagerDefault,
      ...deviceManagerDefault,
    });
    editor.editor.Panels.addPanel({
      id: 'panel-top',
      el: '.panel__top',
    });
    editor.Panels.addPanel({
      id: 'basic-actions',
      el: '.panel__basic-actions',
      buttons: [
        {
          id: 'visibility',
          active: true, // active by default
          className: 'btn-toggle-borders',
          label: '<u>B</u>',
          command: 'sw-visibility', // Built-in command
        },
        {
          id: 'export',
          className: 'btn-open-export',
          label: 'Exp',
          command: 'export-template',
          context: 'export-template', // For grouping context of buttons from the same panel
        },
        {
          id: 'show-json',
          className: 'btn-show-json',
          label: 'JSON',
          context: 'show-json',
          command(editor) {
            editor.Modal.setTitle('Components JSON')
              .setContent(
                `<textarea style="width:100%; height: 250px;">
                  ${JSON.stringify(editor.getComponents())}
                </textarea>`
              )
              .open();
          },
        },
      ],
    });
    editor.on('run:export-template:before', opts => {
      console.log('Before the command run');
      if (0 /* some condition */) {
        opts.abort = 1;
      }
    });
    editor.on('run:export-template', () => console.log('After the command run'));
    editor.on('abort:export-template', () => console.log('Command aborted'));

    editor.Commands.add('show-layers', {
      getRowEl(editor) {
        return editor.getContainer().closest('.editor-row');
      },
      getLayersEl(row) {
        return row.querySelector('.layers-container');
      },

      run(editor, sender) {
        const lmEl = this.getLayersEl(this.getRowEl(editor));
        lmEl.style.display = '';
      },
      stop(editor, sender) {
        const lmEl = this.getLayersEl(this.getRowEl(editor));
        lmEl.style.display = 'none';
      },
    });
    editor.Commands.add('show-blocks', {
      getRowEl(editor) {
        return editor.getContainer().closest('.editor-row');
      },
      getLayersEl(row) {
        return row.querySelector('.blocks-container');
      },

      run(editor, sender) {
        const lmEl = this.getLayersEl(this.getRowEl(editor));
        lmEl.style.display = '';
      },
      stop(editor, sender) {
        const lmEl = this.getLayersEl(this.getRowEl(editor));
        lmEl.style.display = 'none';
      },
    });
    editor.Commands.add('show-styles', {
      getRowEl(editor) {
        return editor.getContainer().closest('.editor-row');
      },
      getStyleEl(row) {
        return row.querySelector('.styles-container');
      },

      run(editor, sender) {
        const smEl = this.getStyleEl(this.getRowEl(editor));
        smEl.style.display = '';
      },
      stop(editor, sender) {
        const smEl = this.getStyleEl(this.getRowEl(editor));
        smEl.style.display = 'none';
      },
    });

    editor.Commands.add('show-traits', {
      getTraitsEl(editor) {
        const row = editor.getContainer().closest('.editor-row');
        return row.querySelector('.traits-container');
      },
      run(editor, sender) {
        this.getTraitsEl(editor).style.display = '';
      },
      stop(editor, sender) {
        this.getTraitsEl(editor).style.display = 'none';
      },
    });

    editor.Commands.add('set-device-desktop', {
      run: editor => editor.setDevice('Desktop'),
    });
    editor.Commands.add('set-device-mobile', {
      run: editor => editor.setDevice('Mobile'),
    });

    setGrapeEditor(editor);
  }, []);

  return (
    <>
      {/* <div className="panel__top">
        <div className="panel__basic-actions"></div>
        <div className="panel__devices"></div>
        <div className="panel__switcher"></div>
      </div> */}
      <div className="editor-row" style={{height: '100%'}}>
        <div className="editor-canvas">
          <div id="gjs-screens"></div>
        </div>
        {/* <div className="panel__right">
          <div className="blocks-container">
            <div id="blocks-screens"></div>
          </div>
          <div className="layers-container"></div>
          <div className="styles-container"></div>
          <div className="traits-container"></div>
        </div> */}
      </div>
    </>
  );
};

export default GrapeEditor;
