import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col, FormGroup, Input, Label } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './app-entity-field.reducer';

export const AppEntityFieldDetail = () => {
  const dispatch = useAppDispatch();

  const { productId } = useParams<'productId'>();
  const { appId } = useParams<'appId'>();
  const { appEntityId } = useParams<'appEntityId'>();
  const { appEntityFieldId } = useParams<'appEntityFieldId'>();


  useEffect(() => {
    dispatch(getEntity(appEntityFieldId));
  }, []);


  const appEntityFieldEntity = useAppSelector(state => state.appEntityField.entity);
  return (
    <div className='inner-content-wrap'>
    <Row>
      <Col md="12">
        <h2 className="row  page-title" data-cy="appEntityFieldDetailsHeading">
          <Translate contentKey="conductorMonolithApp.appEntityField.detail.title">AppEntityField</Translate>
        </h2>
        <dl className="jh-entity-details">
          {/* <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{appEntityFieldEntity.id}</dd> */}
          <Col md ={6}>
          <FormGroup>
            <span id="name">
              <Label contentKey="conductorMonolithApp.appEntityField.name">Name</Label>
            </span>
            <Input value = {appEntityFieldEntity.name}/>
            </FormGroup>
          </Col>
          <Col md ={6}>
          <FormGroup>
            <span id="type">
              <Translate contentKey="conductorMonolithApp.appEntityField.type">Type</Translate>
            </span>
            <Input value = {appEntityFieldEntity.type}/>
            </FormGroup>
          </Col>
          <Col md ={6}>
          <FormGroup>
            <span id="isRequired">
              <Label contentKey="conductorMonolithApp.appEntityField.isRequired">Is Required</Label>
            </span>
            <Input value = {appEntityFieldEntity.isRequired ? 'true' : 'false'}/>
            </FormGroup>
          </Col>
          <Col md ={6}>
          <FormGroup>
            <span id="description">
              <Label contentKey="conductorMonolithApp.appEntityField.description">Description</Label>
            </span>
            <Input value = {appEntityFieldEntity.description}/>
            </FormGroup>
          </Col>
          {/* <dt>
            <span id="lastSyncTimestamp">
              <Translate contentKey="conductorMonolithApp.appEntityField.lastSyncTimestamp">Last Sync Timestamp</Translate>
            </span>
          </dt>
          <dd>
            {appEntityFieldEntity.lastSyncTimestamp ? (
              <TextFormat value={appEntityFieldEntity.lastSyncTimestamp} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd> */}
          <Col md ={6}>
          <FormGroup>
            <Translate contentKey="conductorMonolithApp.appEntityField.appEntity">App Entity</Translate>
            <Input value = {appEntityFieldEntity.appEntity ? appEntityFieldEntity.appEntity.name : ''}/>
            </FormGroup>
          </Col>
        </dl>
        <Button tag={Link}
        // to="/app-entity-field"
        to={`/product/${productId}/app/${appId}/app-entity/${appEntityId}/view`}
        replace color="back" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        {/* <Button tag={Link} to={`/app-entity-field/${appEntityFieldEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button> */}
      </Col>
    </Row>
    </div>
  );
};

export default AppEntityFieldDetail;
