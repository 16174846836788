import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './job.reducer';

export const JobDetail = () => {
  const dispatch = useAppDispatch();

  const { productId } = useParams<'productId'>();
  const { appId } = useParams<'appId'>();
  const { jobId } = useParams<'jobId'>();
  const id = jobId
  
  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const jobEntity = useAppSelector(state => state.job.entity);
  return (
    <div className="inner-content-wrap">
      <Row>
      <Col md="12">
        <h2 data-cy="jobDetailsHeading">
          <Translate contentKey="conductorMonolithApp.job.detail.title">Job</Translate> - {jobEntity.name}
        </h2>
        <dl className="jh-entity-details row">
          {/* <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{jobEntity.id}</dd> */}
          {/* <Col md="6">
            <dt>
            <span id="name">
              <Translate contentKey="conductorMonolithApp.job.name">Name</Translate>
            </span>
          </dt>
          <dd>{jobEntity.name}</dd>
          </Col> */}
          <Col md="6">
          <dt>
            <span id="type">
              <Translate contentKey="conductorMonolithApp.job.type">Type</Translate>
            </span>
          </dt>
          <dd>{jobEntity.type}</dd>
          </Col>
          <Col md="6">
          <dt>
            <span id="taskExecutor">
              <Translate contentKey="conductorMonolithApp.job.taskExecutor">Task Executor</Translate>
            </span>
          </dt>
          <dd>{jobEntity.taskExecutor}</dd>
          </Col>
          <Col md="6">
          <dt>
            <span id="scheduleType">
              <Translate contentKey="conductorMonolithApp.job.scheduleType">Schedule Type</Translate>
            </span>
          </dt>
          <dd>{jobEntity.scheduleType}</dd>
          </Col>
          <Col md="6">
          <dt>
            <span id="scheduleConfig">
              <Translate contentKey="conductorMonolithApp.job.scheduleConfig">Schedule Config</Translate>
            </span>
          </dt>
          <dd>{jobEntity.scheduleConfig}</dd>
          </Col>
          <Col md="6">
          <dt>
            <span id="queueName">
              <Translate contentKey="conductorMonolithApp.job.queueName">Queue Name</Translate>
            </span>
          </dt>
          <dd>{jobEntity.queueName}</dd>
          </Col>
          <Col md="6">
          <dt>
            <span id="description">
              <Translate contentKey="conductorMonolithApp.job.description">Description</Translate>
            </span>
          </dt>
          <dd>{jobEntity.description}</dd>
          </Col>
          <Col md="6">
          <dt>
            <span id="pseudoCode">
              <Translate contentKey="conductorMonolithApp.job.pseudoCode">Pseudo Code</Translate>
            </span>
          </dt>
          <dd>{jobEntity.pseudoCode}</dd>
          </Col>
          <Col md="6">
          <dt>
            <Translate contentKey="conductorMonolithApp.job.app">App</Translate>
          </dt>
          <dd>{jobEntity.app ? jobEntity.app.name : ''}</dd>
          </Col>
        </dl>
        <Button tag={Link} to="/job" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/job/${jobEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
    </div>
  );
};

export default JobDetail;
