import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity } from './product.reducer';
// import { getEntities as getAppEntities } from '../app/app.reducer';
import App from '../app/app';
import '../../app.scss';
import {AppStatus} from 'app/shared/model/enumerations/app-status.model';

export const ProductDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();
  const productEntity = useAppSelector(state => state.product.entity);

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  // const handleSyncList = () => {
  //   dispatch(
  //     getAppEntities({
  //       sort: `id,desc`,
  //       query: `productId.equals=${id}`,
  //     })
  //   );
  // };
  useEffect(() => {
    if(productEntity?.status == AppStatus.Deleted){
      navigate('/product')
    }
  }, [productEntity]);

  
  return (
    <div className="inner-content-wrap">
      <h2 id="product-heading" data-cy="ProductHeading" className="row g-0 page-title">
        <Col md="12">
          <Breadcrumb>
            <BreadcrumbItem><a href="/product">Product</a></BreadcrumbItem>
            <BreadcrumbItem active>{productEntity.name}</BreadcrumbItem>
          </Breadcrumb>
        </Col>
        <Col md="5">
          {productEntity.name && (<span className='productImg'>{(productEntity.name || '').split('')[0]}</span>)}{productEntity.name}
        </Col>
        <Col md="7">
          <div className="d-flex justify-content-end">
            <Button className='me-2' tag={Link} to="/product" replace color="back" data-cy="entityDetailsBackButton">
              <FontAwesomeIcon icon="arrow-left" />{' '}
              <Translate contentKey="entity.action.back">Back</Translate>
            </Button>
            &nbsp;
            <Button className='me-2' tag={Link} to={`/product/${productEntity.id}/edit`} replace color="edit">
              <FontAwesomeIcon icon="pencil-alt" />{' '}
              <span>Manage</span>
            </Button>
            {/* &nbsp;
            <Button className="me-2" color="refresh" onClick={handleSyncList} disabled={appLoading}>
              <FontAwesomeIcon icon="sync" spin={appLoading} />{' '}
              <Translate contentKey="conductorMonolithApp.app.home.refreshListLabel">Refresh List</Translate>
            </Button> */}
          </div>
        </Col>
      </h2>
      <App />
    </div>
  );
};

export default ProductDetail;
