import { IApp } from 'app/shared/model/app.model';
import { JobType } from 'app/shared/model/enumerations/job-type.model';
import { JobScheduleType } from 'app/shared/model/enumerations/job-schedule-type.model';

export interface IJob {
  id?: number;
  name?: string;
  type?: JobType;
  taskExecutor?: string | null;
  scheduleType?: JobScheduleType | null;
  scheduleConfig?: string | null;
  queueName?: string | null;
  description?: string | null;
  pseudoCode?: string | null;
  app?: IApp | null;
}

export const defaultValue: Readonly<IJob> = {};
