import React, { useState, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Button, Col, Row, Spinner, Table } from 'reactstrap';
import { Translate, getSortState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities, createEntity as createAppFeature } from '../feature/feature.reducer';
import FeatureDeleteDialog from '../feature/feature-delete-dialog';
import SearchModule from 'app/shared/component/searchModule/SearchModule';
import { suggestProductFeature } from 'app/entities/ai-suggestor/ai-suggestor.reducer';
import { getEntities as getApps } from 'app/entities/app/app.reducer';
import { getEntity as getProduct } from 'app/entities/product/product.reducer';

import { getEntities as getCoreFunctionalities } from 'app/entities/core-functionality/core-functionality.reducer';


export const AppFeature = (props) => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const { productId } = useParams<'productId'>();
  const { appId } = useParams<'appId'>();
  var [deleteId, setDeleteId] = useState("")
  const [showModal, setShowModal] = useState(false)
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id', DESC), location.search)
  );

  const apps = useAppSelector(state => state.app.entities);
  const coreFunctionalities = useAppSelector(state => state.coreFunctionality.entities);
  const featureEntity = useAppSelector(state => state.feature.entity);
  const productEntity = useAppSelector(state => state.product.entity);
  const features = useAppSelector(state => state.feature.entities);
  const loading = useAppSelector(state => state.feature.loading);
  const totalItems = useAppSelector(state => state.feature.totalItems);
  const [query,setQuery] = useState("");
  const [aiSuggestLoading, setAiSuggestLoading] = useState<boolean>(false);
  const [featureList, setFeatureList] = useState<any>();


  useEffect(() => {
    dispatch(getApps({query: `id.equals=${appId}`,
    }));
    dispatch(getCoreFunctionalities({}));
    dispatch(getProduct(productId));
  }, []);

  const getAllEntities = (emptyQuery?:any) => {
    const queryValue = emptyQuery != undefined ? emptyQuery : query
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query: `appId.equals=${appId}${queryValue?`&name.contains=${queryValue}`:""}`,
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
  };

  useEffect(() => {
    setFeatureList(features);
    // dispatch(getAppEntity(appEntityId));
  },[features]);

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const handleDelete = (feature_id) => {
    setShowModal(true)
    setDeleteId(feature_id)
    deleteId = feature_id;
  }

  const viewModal = (modal) => {
    setShowModal(modal)
  }

  const handleAiSuggest = async() => {
    setAiSuggestLoading(true);
    const productDetails = {
      productName: productEntity?.name,
      productDescription: productEntity?.description,
    }
    // console.log("productDetails", productDetails);
    const {payload} = await dispatch(suggestProductFeature(productDetails));
    let result = payload['data']
    let temp_result = [];
    result.map(ele => {
      let temp_obj = {...ele, key: `${ele.name}-${Date.now()}`}
      temp_result.push(temp_obj);
    })
    let temp_arr = featureList.concat(temp_result)
    
    const distinctArray = temp_arr.reduce((accumulator, current) => {
      const found = accumulator.find(item => item.name === current.name || "id" === current.name);
      if (!found) {
        accumulator.push(current);
      }
      return accumulator;
    }, []);
    setAiSuggestLoading(false);
    setFeatureList(distinctArray);
  }

  const handleOnSaveForSuggested = async(data) => {
    const entity = {
      // ...featureEntity,
      ...data,
      app: apps.find(it => it.id == appId),
      coreFeature:coreFunctionalities.find(it => it.id === data.coreFeature)
    };
    
    const {payload} =  await dispatch(createAppFeature(entity))
    // console.log("payload", payload);
    let ele = document.getElementById('feature-key-'+data.key);
    ele.parentNode.removeChild(ele);
    let temp_arr = featureList.concat([payload['data']]);

    if(payload['data']){setFeatureList(temp_arr)}
  }

  const handleSuggestedDelete = (data) => {
    let ele = document.getElementById('feature-key-'+data.key);
    ele.parentNode.removeChild(ele);
  }

  return (
    <>
      <div className='inner-content-wrap' id='feature'>
        <h2 id="feature-heading" data-cy="FeatureHeading" className="row mb-4 mt-3">
            <Col>
              <SearchModule 
                setQuery={setQuery}
                fetchFunction={getAllEntities}
                placeHolder='Feature Name'
              />
            </Col>
          <Col md="12">
            <div className="d-flex justify-content-end">
              {/* <Button className="me-2" color="refresh" onClick={handleSyncList} disabled={loading}>
                <FontAwesomeIcon icon="sync" spin={loading} />{' '}
                <Translate contentKey="conductorMonolithApp.feature.home.refreshListLabel">Refresh List</Translate>
              </Button> */}
              <Button className="me-2" color="refresh" onClick={handleAiSuggest} disabled={loading}>
              <FontAwesomeIcon icon="magic-wand-sparkles" spin={loading || aiSuggestLoading} />{' '}
                AI Suggest
              </Button>
              <Link to={`/product/${productId}/app/${appId}/feature/new`}
                className="btn btn-save jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
                <FontAwesomeIcon icon="plus" />
                &nbsp;
                <Translate contentKey="conductorMonolithApp.feature.home.createLabel">Create new Feature</Translate>
              </Link>
            </div>
          </Col>
        </h2>
        {((aiSuggestLoading) || (featureList && featureList.length > 0)) ? (<div className="table-responsive">

          <Table>
            <thead className="table-primary">
              <tr>
                <th className="hand">
                  <Translate contentKey="conductorMonolithApp.feature.name">Name</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" >
                  <Translate contentKey="conductorMonolithApp.feature.description">Description</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand">
                  <Translate contentKey="conductorMonolithApp.feature.link">Link</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {featureList.map((feature, i) => (
                <tr key={`feature-${i}`} id={`feature-key-${feature.id || feature.key}`} data-cy="entityTable">
                  <td>{feature.name}</td>
                  <td>{feature.description}</td>
                  <td>{feature.link}</td>
                  <td className="text-end">
                    {/* <div className="btn-group flex-btn-group-container"> */}
                      {/* <Button tag={Link} to={`/feature/${appid}/${feature.id}/view`} color="primary" size="sm" data-cy="entityDetailsButton">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button> */}
                      {feature?.id ? <div className="btn-group flex-btn-group-container"> 
                      <Button
                        tag={Link}
                        to={`/product/${productId}/app/${appId}/feature/${feature.id}/edit`}
                        color="warning"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        onClick={() => handleDelete(feature.id)}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                      </div> : <div className="btn-group flex-btn-group-container">
                      
                      <Button
                        onClick={(e) => {handleOnSaveForSuggested(feature)}}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        Save
                      </Button>
                      <Button
                        onClick={() => handleSuggestedDelete(feature)}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>}
                    {/* </div> */}
                  </td>
                </tr>
              ))}
              {(aiSuggestLoading) ? <tr data-cy="entityTable">
                  <td></td>
                  <td style={{textAlign: 'center'}}>
                  <Spinner className="sm-5" color="primary"> Loading... </Spinner>
                  </td>
                  <td></td>
                  <td></td>
                </tr> : ''}
            </tbody>
          </Table>
        </div>) : (
          loading ? (<div className="conductor_loader"></div>) : (
            <div className="alert alert-warning">
              <Translate contentKey="conductorMonolithApp.feature.home.notFound">No Features found</Translate>
            </div>
          )
        )}
        {totalItems ? (
          <div className={featureList && featureList.length > 0 ? '' : 'd-none'}>
            <div className="justify-content-center d-flex">
              <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
            </div>
            <div className="justify-content-center d-flex">
              <JhiPagination
                activePage={paginationState.activePage}
                onSelect={handlePagination}
                maxButtons={5}
                itemsPerPage={paginationState.itemsPerPage}
                totalItems={totalItems}
              />
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      {showModal && <FeatureDeleteDialog itemId={deleteId} showModal={viewModal} />}
    </>
  );
};

export default AppFeature;
