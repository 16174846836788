export enum AppStatus {
  New = 'New',

  Draft = 'Draft',

  Published = 'Published',

  Disabled = 'Disabled',

  Deleted = 'Deleted',

  InProgress = 'InProgress',
}
