import { IApp } from 'app/shared/model/app.model';
import { IDeployment } from 'app/shared/model/deployment.model';
import { IEmailConfiguration } from 'app/shared/model/email-configuration.model';
import { IProductUser } from 'app/shared/model/product-user.model';
import { Authentication } from 'app/shared/model/enumerations/authentication.model';
import { AppStatus } from 'app/shared/model/enumerations/app-status.model';

export interface IProduct {
  id?: number;
  name?: string;
  authenticationType?: any;
  gitGroupPath?: string | null;
  gitDescriptorsRepo?: string | null;
  conductorPipeline?: string | null;
  icon?: string | null;
  groupId?: number | null;
  status?: AppStatus | null;
  apps?: IApp[] | null;
  deployments?: IDeployment[] | null;
  emailConfigurations?: IEmailConfiguration[] | null;
  productUsers?: IProductUser[] | null;
} 

export const defaultValue: Readonly<IProduct> = {};
