import dayjs from 'dayjs';
import { IScreenComponent } from 'app/shared/model/screen-component.model';
import { IFeature } from 'app/shared/model/feature.model';
import { IApp } from 'app/shared/model/app.model';
import { IFunctionality } from 'app/shared/model/functionality.model';
import { AppStatus } from 'app/shared/model/enumerations/app-status.model';

export interface IAppScreen {
  id?: number;
  title?: string;
  lastSyncTimestamp?: string | null;
  path?: string;
  metadata?: string | null;
  template?: string | null;
  accessRoles?: string | null;
  userId?: number | null;
  description?: string | null;
  status?: AppStatus | null;
  screenComponentviewEdits?: IScreenComponent[] | null;
  screenComponents?: IScreenComponent[] | null;
  screenComponentviewDetails?: IScreenComponent[] | null;
  screenComponentformSuccessRedirectScreens?: IScreenComponent[] | null;
  app?: IApp | null;
  functionality?: IFunctionality | null;
  feature?: IFeature | null;
}

export const defaultValue: Readonly<IAppScreen> = {};
