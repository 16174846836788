import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import EntityRelationship from './entity-relationship';
import EntityRelationshipDetail from './entity-relationship-detail';
import EntityRelationshipUpdate from './entity-relationship-update';
import EntityRelationshipDeleteDialog from './entity-relationship-delete-dialog';

const EntityRelationshipRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<EntityRelationship />} />
    <Route path="/:appId/new" element={<EntityRelationshipUpdate />} />
    <Route path=":appId/:id">
      <Route path="edit" element={<EntityRelationshipUpdate />} />
      <Route path="view" element={<EntityRelationshipDetail />} />
    </Route>
    <Route path=':appId'>
      <Route path="delete" element={<EntityRelationshipDeleteDialog />} />
      </Route>
   
  </ErrorBoundaryRoutes>
);

export default EntityRelationshipRoutes;
