import { IAppCodeBlueprint } from 'app/shared/model/app-code-blueprint.model';

export interface ICodeBlueprint {
  id?: number;
  name?: string | null;
  codeGeneratorName?: string | null;
  commandName?: string | null;
  description?: string | null;
  variables?: string | null;
  userId?: number | null;
  appCodeBlueprints?: IAppCodeBlueprint[] | null;
}

export const defaultValue: Readonly<ICodeBlueprint> = {};
