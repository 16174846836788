import { getEntities } from 'app/entities/core-functionality/core-functionality.reducer';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import React, { useEffect, useState } from 'react';
import { getSortState, JhiItemCount, JhiPagination, Translate } from 'react-jhipster';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { CardGroup, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { getSession } from 'app/shared/reducers/authentication';
import { AUTHORITIES } from 'app/config/constants';

interface Props {}
export const CorefunctionalityListView = (props: Props) => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );

  const { id } = useParams<'id'>();

  const isNew = id === undefined;
  var dataList = useAppSelector(state => state.coreFunctionality.entities);
  const loading = useAppSelector(state => state.coreFunctionality.loading);
  const totalItems = useAppSelector(state => state.coreFunctionality.totalItems);

  const getAllEntities = () => {};

  useEffect(() => {
    if (isNew) {
      dispatch(getEntities({}));
    } else {
      dispatch(
        getEntities({
          query: `categoryId.equals=${id}`,
        })
      );
    }
  }, [id]);

  let entityFields = ['name', 'description'];

  return (
    <>
      <CardGroup>
        {dataList.length > 0
          ? dataList.map((product, i) => (
              <Card
                style={{
                  minWidth: '24%',
                  margin: '.5%',
                }}
                tag={Link}
                to={`/blogCoreFunctionality/${product?.id}`}
              >
                <img alt="Sample" src={'https://picsum.photos/300/200'} />
                {
                  <CardBody>
                    <CardTitle tag="h5">{product?.name}</CardTitle>
                  </CardBody>
                }
              </Card>
            ))
          : !loading && (
              <div className="alert alert-warning">
                <h2>No CoreFunctionality found</h2>
              </div>
            )}
      </CardGroup>
    </>
  );
};

export default CorefunctionalityListView;
