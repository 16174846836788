export enum ApplicationType {
  Monolith = 'Monolith',

  Microservice = 'Microservice',

  Gateway = 'Gateway',

  WebApp = 'WebApp',

  HybridApp = 'HybridApp',

  Backend = 'Backend',
}
