import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import CodeBlueprint from './code-blueprint';
import CodeBlueprintDetail from './code-blueprint-detail';
import CodeBlueprintUpdate from './code-blueprint-update';
import CodeBlueprintDeleteDialog from './code-blueprint-delete-dialog';

const CodeBlueprintRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<CodeBlueprint />} />
    <Route path="new" element={<CodeBlueprintUpdate />} />
    <Route path=":id">
      <Route index element={<CodeBlueprintDetail />} />
      <Route path="edit" element={<CodeBlueprintUpdate />} />
      <Route path="delete" element={<CodeBlueprintDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default CodeBlueprintRoutes;
